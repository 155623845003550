import request from '@/utils/request'

// 查询用户个人信息
export function getUserProfile() {
    return request.get('/system/user/profile')
}

// 查询用户个人信息
export function getUserInfo(id) {
    return request.get('/system/user/' + id)
}

//测试发放奖励
// export function testAwardMoney(orderCode) {
//     return request.post('/api/wechat/testAwardMoney',{orderCode:orderCode})
// }


// 修改用户个人信息
export function updateUserProfile(data) {
    return request.put('/system/user/profile',data)
}

// 修改提现申请
export function updateBank(data) {
    return request.post( '/system/user/updateBank',data);
}

// 用户密码重置
export function updateUserPwd(oldPassword, newPassword) {
    return request.put('/system/user/profile/updatePwd?oldPassword='+oldPassword+"&newPassword="+newPassword)
}