<template>
  <div style="contain">
    <van-nav-bar title="动态" />
    <van-tabs v-model="active" @change="onClick">
      <van-tab title="新机">
      </van-tab>
      <van-tab title="二手机">
      </van-tab>
    </van-tabs>
    <div style="display: flex;width: 100%;font-size: 16px">
      <div @click="getSort" :class="value1==='1'?'active':'isActive'" style="width: 30%;text-align: center;padding-top: 15px">
        急售区
      </div>
      <div style="width: 70%">
        <van-dropdown-menu>
          <!--<van-dropdown-item v-model="value1" :options="option1" title="急售区" @change="getSort">-->
          <!--</van-dropdown-item>-->
          <van-dropdown-item v-model="value2" :options="option2" @change="getBrand" />
          <van-dropdown-item v-model="value3" :options="option3" @change="getCity" />
        </van-dropdown-menu>
      </div>
    </div>

    <van-dialog v-model="showZd" title="是否花费10积分进行置顶?" @confirm="Zd" show-cancel-button message=""/>

<!--    <div style="display: flex;align-items: center;width: 100%">-->
<!--      <p class="top-p" @click="getSort">置顶</p>-->
<!--    -->
<!--    </div>-->
    <div v-show="show">
      <van-empty description="找不到商品" />
    </div>
    <div v-show="!show">
      <div v-for="(item, index) in goods" :key="index" class="good-container" @click="callPhone(item)">
<!--        <div class="avatar">-->
<!--          &lt;!&ndash; <img :src="item.avatar || require('../../assets/default-avatar.png')" alt="avatar"> &ndash;&gt;-->
<!--          <img :src="require('../../assets/default-avatar.png')" alt="avatar">-->
<!--        </div>-->
        <div class="content">
          <div class="content-p1">
            <div class="p1">
              <div class="sales">出售</div>
              <div class="details">
                <span>{{ item.name }}</span> -
                <span>{{ item.seriesName }}</span> -
                <span>{{ item.modelName }}</span> -
                <span>{{ item.versionName }}</span>
              </div>
            </div>
            <div class="price" v-if="isShow">
              <span class="price-value">￥{{ item.price }}</span>
            </div>
            <div class="price" v-else>
               <span class="price-value">****</span>
            </div>
          </div>
          <div class="content-p1" style="margin-top: 8px">
            <div class="header">
              <span class="shop-name">{{ item.businessName }}</span><span style="margin-left: 4px;">|</span>
              <span style="margin-left: 4px;">{{ item.cityName }}</span> <span>|</span>
              <span v-if="item.update_time" style="margin-left: 4px;">{{ item.update_time.substring(0,10)}}</span>
            </div>

            <div class="status">
              状态: <span>{{ item.status }}</span>
            </div>
            <div @click.stop="showPop(item)" class="btn-top green-color">
              <!--<van-icon name="sort" color="#2d94f8"/>-->
              置顶
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

</template>

<script>
import request from '@/utils/request';
import { checkView } from '@/utils/checkUtil'
import {Toast} from "vant";
export default {
  data() {
    return {
      show: false,
      showZd: false,
      currentItem: {},
      active: 0,
      goods: [],
      value1:'',
      value2: '',
      value3: '',
      isNew: '0',
      isTop:null,
      option1: [
        { text: '默认', value: '' },
        { text: '是', value: 1 },
        // { text: '价格升序', value: 1 },
        // { text: '价格降序', value: 2 },

      ],
      option2: [
        { text: '全部商品', value: '' }
      ],
      option3: [
        { text: '全部城市', value: '' }
      ],
      isShow: true
    };
  },
  filters: {
    formatDate(value) {
      if (!value) return ''
      // 分割日期和时间
      return value.split('T')[0];
    }
  },
  mounted() {
    this.init()
  },
  methods: {
    showPop(item) {
      this.currentItem = item;
      //查看是否是自己的商品
      request.post('/business/series/isCanZd',
        {
          id: item.id
        }
      )
        .then(response => {
          console.log(response)
          //处理响应数据
          if (response.code == '200') {
            this.showZd = true
          } else {
            Toast.fail("置顶失败，只能置顶自己的商品！")
          }
        })
        .catch(error => {
          // 处理请求错误
          console.error('请求错误:', error);
        });
    },
    Zd() {
      console.log("zd")
      request.post('/business/series/zd',
        {
          id: this.currentItem.id
        }
      )
        .then(response => {
          console.log(response)
          // 处理响应数据
          if (response.code == '200') {
            Toast.success(response.msg)
          } else {
            Toast.fail(response.msg)
          }
          this.getList();

        })
        .catch(error => {
          // 处理请求错误
          console.error('请求错误:', error);
        });
    },
    callPhone(item){
      if(item.phone){
        plus.device.dial(item.phone);
      }else{
        Toast.fail('该商家暂无手机号');
      }

    },
    init() {
      let result = checkView()
      this.isShow = result.flag
    },
    onClick(name, title) {
      this.isNew = name;
      this.getList();
    },
    getSort() {
      if(this.value1){
        this.value1 = '';
      }else{
        this.value1 = '1';
      }

      this.isTop=this.value1;
      this.getList();//sql后拼接order by
    },
    getBrand(value) {
      this.value2 = value
      this.getList();
    },
    getCity(value) {
      this.value3 = value
      this.getList();
    },

    getList() {
      let params = { isNew: this.isNew, type: this.value1, brandId: this.value2, cityId: this.value3 ,isTop:this.isTop}
      //to do
      //调用axios传递参数获取list
      request.get('/business/series/getHomePage',params)
        .then(response => {
          // 处理响应数据
          if(response != [] &&response.length>0){
            this.goods = response;
            this.show = false
          }else{
            this.show = true
          }
        })
        .catch(error => {
          // 处理请求错误
          console.error('请求错误:', error);
        });
    },
    getBrandOptions() {
      //获取品牌选项
      request.get('/business/brand/list',
        {
          pageNum: 1,
          pageSize: 9999
        }
      )
        .then(response => {
          // 处理响应数据
          for (let i = 0; i < response.rows.length; i++) {
            // 判断是否存在重复的 name
            const existingIndex = this.option2.findIndex(item => item.text === response.rows[i].name);
            if (existingIndex === -1) {
              // 如果不存在重复的 name，则添加到数组中
              let map = {
                text: response.rows[i].name,
                value: response.rows[i].id
              };
              this.option2.push(map);
            }
          }
        })
        .catch(error => {
          // 处理请求错误
          console.error('请求错误:', error);
        });
    },
    getCityOptions() {
      request.get('/business/city/list',
        {
          pageNum: 1,
          pageSize: 9999
        }
      )
        .then(response => {
          // 处理响应数据
          for (let i = 0; i < response.rows.length; i++) {
            // 判断是否存在重复的 name
            const existingIndex = this.option2.findIndex(item => item.text === response.rows[i].name);
            if (existingIndex === -1) {
              // 如果不存在重复的 name，则添加到数组中
              let map = {
                text: response.rows[i].name,
                value: response.rows[i].id
              };
              this.option3.push(map);
            }
          }
        })
        .catch(error => {
          // 处理请求错误
          console.error('请求错误:', error);
        });
    },
  },
  created() {
    //to do
    this.getCityOptions();
    this.getBrandOptions();
    this.getList();
  }
}

</script>

<style lang="scss" scoped>
.good-container {
  display: flex;
  align-items: center;
  background-color: #ffffff; /* 背景色改为纯白提升清晰度 */
  border-bottom: 1px solid #ddd; /* 边框颜色更细腻 */
  padding: 6px;
}

.avatar img {
  width: 50px;
  height: 50px;
  border-radius: 50%;
  margin-right: 15px; /* 调整间距 */
}

.content {
  flex-grow: 1;
  .p1{
    display: flex;
    align-items: center;
    color: #333333;
    .sales{
      padding: 2px 4px;
      border-radius: 2px;
      color: white;
      font-size: 10px;
      background: #C12E2B;
      margin-right: 4px;
    }
  }
}
.header{
  display: flex;
  align-items: center;
  width: 180px;
}
.header .shop-name {
  color: #999;
  font-size: 12px;
  display: block;
  max-width: 60px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.header span {
  color: #999; /* 颜色更柔和 */
  font-size: 12px; /* 统一字体大小 */
  font-family: 'Arial', sans-serif; /* 使用无衬线字体提升现代感 */
}

.details {
  color: #1a1919;
  width: 230px;
  height: 18px;
  overflow: hidden;
  font-size: 13px;
  font-family: 'Helvetica', sans-serif; /* 更换字体样式 */
}

.status {
  font-size: 12px;
  color: #555; /* 状态信息使用稍深颜色增加区分度 */
  font-weight: 500; /* 轻微加粗 */
  width: 120px;
  span{
    color: #43BA85;
  }
}

.price {
  color: #333; /* 调整颜色以突出价格 */
  font-size: 13px; /* 加大价格字体大小 */
}

.price-value {
  font-size:13px; /* 价格数字更大 */
  color: #333; /* 价格颜色更鲜艳 */
}
  .top-p{
    width: 30%;
    text-align: center;
    box-shadow: 0 0 12px  rgba(100,101,102,.12);
    height: 48px;
    line-height: 48px;
    filter: drop-shadow(0 0 35px rgba(81, 255, 248, 0.5));

  }
  /*::v-deep .van-dropdown-menu__bar{*/
    /*!*box-shadow: 0 0 0 0  rgba(100,101,102,.12)!important;*!*/
  /*}*/
  ::v-deep .van-dropdown-menu__bar{
    box-shadow: unset !important;
  }
  .content-p1{
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  .active{
    color: #ee0a24;
  }
  .isActive{
    color: #323233
  }
  .btn-top{
    background: transparent;
    /*border: 1px solid #5492FF;*/
    font-size: 12px;
    /*border-radius: 80px;*/
    /*padding: 3px 10px;*/
    color: #5492FF;
    margin-left: 6px;
  }
  .green-color{
    color:  #5492FF!important;
  }
::v-deep .van-dialog{
  border-radius: 6px!important;
}
::v-deep .van-dialog__confirm{
  color: #5492ff!important;
}
</style>
