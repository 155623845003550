<template>
  <div class="contain">
    <van-nav-bar title="我的钱包"  left-arrow @click-left="goBack" style="background: #fff !important;color: #464c59 !important;"></van-nav-bar>
    <div class="money" :style="{ backgroundImage: `url(${myBg})`}">
      <el-row style="color:#fff;font-size: .8rem;padding-top: 1.2rem;">
        <el-col :span="14">
          <span style="padding-left: 1.5rem;">我的余额</span>
        </el-col>
        <el-col :span="10">
          <el-row>
            <el-col :span="12">
              <div class="extractButton" style="background: #07c160" @click="onExtract">提现</div>
<!--              <van-button type="primary" size="mini" style="width: 4rem;" @click="onExtract">提现</van-button>-->
            </el-col>
            <el-col :span="12">
              <div class="extractButton" @click="onExtractList">提现记录</div>
<!--              <van-button type="info" size="mini" style="width: 4rem" @click="onExtractList">提现记录</van-button>-->
            </el-col>
          </el-row>
        </el-col>
      </el-row>
      <el-row>
        <el-col :span="24" style="padding-top: 1rem;">
          <span style="padding-left: 1.1rem;font-size: 2rem;color: #fff;">￥{{balanceMoeny}}</span>
        </el-col>
      </el-row>
      <el-row style="padding-top: 1rem;">
        <el-col :span="12">
          <span style="padding-left: 1.5rem;color: #fff;font-size: .7rem"><van-icon name="gold-coin-o" />累计收益：￥{{totalMoney}}</span>
        </el-col>
        <el-col :span="12">
          <span style="padding-left: 1.5rem;color: #fff;font-size: .7rem"><van-icon name="gold-coin-o" />冻结金额：￥{{freezeMoney}}</span>
        </el-col>

        <el-col :span="24">
          <span style="padding-left: 1.5rem;color: #fff;font-size: .7rem"><van-icon name="gold-coin-o" />佣金收益：￥{{commissMoney}}</span>
        </el-col>
      </el-row>
    </div>
    <div style="margin-top:14px">
      <div style="height: 14px;background: #eee;width: 100%"></div>
      <div style="text-align:center;margin-top:14px;font-weight: bold">邀请记录</div>
      <van-tabs v-model="state" color="#5492ff" @change="tabs" title-active-color=" rgb(84, 146, 255)" >
        <van-tab title="未成功"></van-tab>
        <van-tab title="成功"></van-tab>
        <!--            <van-tab title="失  败"></van-tab>-->
      </van-tabs>
      <van-list
        v-model="loading"
        :finished="finished"
        finished-text="没有更多了"
        @load="onLoad"
        offset="80"
        swipeable="true"
      >
        <van-cell>
          <el-row style="text-align: center;font-size: 11px">
            <el-col :span="5">
              被邀请人
            </el-col>
            <el-col :span="6">
              充值金额
            </el-col>
            <el-col :span="5">
              提成比例
            </el-col>
            <el-col :span="4"  >
              佣金
            </el-col>
            <el-col :span="4"  >
              状态
            </el-col>
          </el-row>
        </van-cell>
        <van-cell v-for="(item,index) in list" :key="index">
          <el-row>
            <el-col :span="5">
              <p style="text-align: center;line-height: 8px;color: #999999">{{item.inviteeName}}</p>
            </el-col>
            <el-col :span="6">
              <p style="text-align: center;color: #00bc1a;font-size: 11px;padding-top: 2px;line-height: 8px;">
                ￥{{item.rechargeMoney||0}}</p>
            </el-col>
            <el-col :span="5">
              <p style="text-align: center;font-size: 11px;padding-top: 2px;line-height: 8px;">
                {{item.rewardRatio||'0'}}%</p>
            </el-col>
            <el-col :span="4"  >
              <p style="text-align: center;color: #00bc1a;font-size: 11px;padding-top: 2px;line-height: 8px;">
                ￥{{item.awardMoney||0}}</p>
            </el-col>
            <el-col :span="4"  >
              <p style="text-align: center;font-size: 11px;padding-top: 2px;line-height: 8px;">
                {{item.statusStr}}</p>
            </el-col>
          </el-row>
        </van-cell>
      </van-list>
    </div>
  </div>

</template>

<script>
import { getUserInfo} from "@/api/user"
import { getMyInvite } from "@/api/invite"
import { myBg } from '@/utils/imgBase64.js';
export default {
  data() {
    return {
      myBg: '',
      balanceMoeny:0.00,
      totalMoney:0.00,
      freezeMoney:0.00,
      commissMoney:0.00,
      list: [],
      loading: false,
      finished: false,
      pageNum: 0,
      pageSize: 5,
      state:0
    };
  },
  mounted(){
    this.myBg = myBg;
    this.getUserInfoData()
  },
  methods:{
    tabs(name, title) {
      this.pageNum = 0;
      this.list = [];
      this.loading = true;
      this.finished = false;
      this.onLoad();
    },
    onLoad() {
      this.pageNum++;
      let $this = this;
      console.log(this.state)
      getMyInvite({status: this.state,pageNum: this.pageNum, pageSize: this.pageSize}).then(res => {
        if (res.code == 200) {
          $this.loading = false;
          if (res.rows.length > 0) {
            if ($this.list.length == 0) {
              $this.list = res.rows;
            } else {
              $this.list.push(...res.rows);
            }
            this.loading = true;
          }
          if ($this.list.length >= res.total) {
            $this.finished = true;
            this.loading = true;
          }
        }

      })
    },
    getUserInfoData() {
      this.user = JSON.parse(localStorage.getItem('yltUser'));
      if(this.user){
        getUserInfo(this.user.userId).then(res => {
          if(res.code == 200) {
            this.balanceMoeny = (res.data.money || "0.00")
            this.totalMoney = (res.data.totalMoney || "0.00")
            this.freezeMoney = (res.data.freezeMoney || "0.00")
            this.commissMoney = (res.data.commissMoney||'0.00')
          }
        })
      }
    },
    onExtract:function(){
        this.$router.push("/extractMoneyForm");
    },
      onExtractList:function(){
          this.$router.push("/extractMoneyList");
      },
    goBack() {
      this.$router.push('/userCenter')
    }
  }
}
</script>

<style scoped>
.content {
  text-align: center;
  padding: 5vh 2vh 0;
}
.avatar {
  width: 100px;
  height: 100px;
  border-radius: 50%;
}

  .money{
    background: #5492ff;
    height: 170px;
    width: 96%;
    border-radius: .2rem;
    margin: auto;
  }

 ::v-deep .van-nav-bar__title{
   color:#464c59 !important;
 }
  ::v-deep .van-nav-bar .van-icon{
    color:#464c59 !important;
  }

  .extractButton {
    border-radius:3px;
    text-align: center;
    width:65px;
    padding:5px 0;
    font-size: 11px;
    background: #1989fa;
  }
</style>
