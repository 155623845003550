/**
 * 校验是否可以查看
 * 1. 是否认证
 * 2. 是否充值
 * 3. 是否过期
 */
export function checkView(currUrl,customMsg){
    const info = localStorage.getItem("businessInfo") ? JSON.parse(localStorage.getItem("businessInfo")) : null
    let result = { flag: true, msg: "校验成功" }
    if (info == null) {
        result = {
            flag: false,
            msg: "资料未认证,请认证审核通过后使用该功能"
        }
        return result
    }
    if (info.isRecharge == '0') {
        result = {
            flag: false,
            msg: "未充值,请充值后使用该功能"
        }
        return result
    }
    if (isDateLessThanToToday(info.expirationTime)) {
        result = {
            flag: false,
            msg: "已过期,请充值后使用该功能"
        }
        return result
    }
    //如果账号没有上传营业执照不允许发布商品
    if(!!currUrl && currUrl == '/publish' && !info.licenseUrl){
        result = {
            flag: false,
            msg: (customMsg || "请补充营业执照后使用发布商品功能")
        }
        return result
    }
    return result
}

function isDateLessThanToToday(date) {
    const today = new Date(); // 当前日期
    return new Date(date) < today; // 比较日期
}
