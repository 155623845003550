import request from '@/utils/request'

// 查询商家信息
export function getBusinessInfo() {
    return request.get('/business/business/findByUserId')
}

// 充值
export function recharge(data) {
    return request.post('/business/business/recharge',data)
}

// 修改商家信息
export function updateBusinessInfo(data) {
    return request.put('/business/business',data)
}

// 新增商家信息
export function addBusinessInfo(data) {
    return request.post('/business/business',data)
}

export function zd(data) {
    return request.post('/business/series/zd',data)
}

export function getStatus(id) {
    return request.get('/business/business/status/'+id)
}

export function editAuth(data) {
    return request.post('/business/business/edit/auth',data)
}
