<template>
 <div class="page">
     <div class="container" :style="{ backgroundImage: `url(${myBg})`}">

         <van-nav-bar title="会员充值"  left-arrow @click-left="goBack"></van-nav-bar>
         <div class="title">到期时间</div>
         <div class="time">
             {{ expirationTime }}
         </div>

     </div>
     <div class="info-con">
         <div class="info"  @click="handleClick(index)" v-for="(item,index) in list" :class="{ 'active': item.active }">
             <div class="price">￥{{ item.price }}</div>
             <div class="name">{{ item.num }}天赠送{{ item.gifts }}天</div>
         </div>
         <div class="btn-recharge" @click="pay">充值</div>
         <p class="record-p" @click="handleRecord">充值记录</p>
     </div>
 </div>
</template>

<script>
import { getBusinessInfo,recharge } from "@/api/business";
import { getAllPackage } from "@/api/package";
import { formatDate,addDate } from "@/utils/date"
import { Notify,Toast } from 'vant';
import { myBg } from '@/utils/imgBase64.js';
export default {
    data(){
        return {
            myBg: '',
            expirationTime: '',
            num: '',
            gifts: '',
            price: 0,
            list: [],
            selectItem:{}
        }
    },
    created() {
        this.myBg = myBg;
      this.getInfo()
      this.getPackage()
    },
    methods:{
        handleRecord(){
            this.$router.push('/rechargeRecord')
        },
      getPackage() {
        getAllPackage().then(res => {
          this.list = res.data.map(obj => {
            obj.active = false
            return obj
          })
        })
      },
      getInfo() {
        getBusinessInfo().then(res => {
          if(res.data) {
            this.id = res.data.id
            this.expirationTime = formatDate(res.data.expirationTime)
          }
        })
      },
      handleClick(index) {
        // 点击事件处理
        this.list.forEach((item, i) => {
          if (i === index) {
          // 设置当前点击项的样式
          item.active = true;
          this.num = item.num
          this.gifts = item.gifts
          this.price = item.price
              this.selectItem=item;
        } else {
          // 其他项恢复默认样式
          item.active = false;
        }
        });
      },
      pay() {
        if(!this.num) {
          // Notify({ type: 'danger', message: '请选择套餐' });
          this.$toast.fail('请选择套餐')
          return
        }
        if(this.id == null) {
          // Notify({ type: 'danger', message: '请先完成资料认证' });
            this.$toast.fail('请先完成资料认证')
          return
        }
        var day = this.num + this.gifts
        let time = addDate(this.expirationTime,day);
        var data = {id: this.id,integral: day,expirationTime: time,totalAmount: this.price}
          Toast.loading("加载中",{ duration: 2000 })
        recharge({
            id:this.selectItem.id
        }).then(res => {
            console.log(res)
          if(res.code == 200) {
              let that=this;
              let payInfo=res.data;
              plus.payment.getChannels((channels) => {
                  try {
                      let c = channels.find(e => e.id === 'wxpay') || {serviceReady: false}
                      console.log(c.serviceReady)
                      if(!c.serviceReady){
                          alert('未安装微信')
                          Toast("未安装微信")
                          return
                      }

                      plus.payment.request(c,{
                          appid:payInfo.appId,
                          noncestr:payInfo.nonceStr,
                          package:payInfo.packageValue,
                          partnerid:payInfo.partnerId,
                          prepayid:payInfo.prepayId,
                          timestamp:payInfo.timeStamp,
                          sign:payInfo.sign
                      }, () => {
                          console.log("支付成功")
                          Toast('支付成功')
                          let info = JSON.parse(localStorage.getItem("businessInfo"));
                          info.isRecharge = "1"
                          info.expirationTime = time
                          localStorage.setItem("businessInfo", JSON.stringify(info))
                          // Notify({ type: 'success', message: '充值成功' });
                          this.goBack()
                          // 跳转到支付成功页面
                          // that.payResult(campusId, verificationCode);
                      }, function(e){
                          console.log("支付失败")
                          Toast('取消支付')
                          // that.payResult(campusId, verificationCode);
                          // alert("支付失败："+e.message)
                      } )
                  } catch (error) {
                      Toast('异常')
                      console.log('异常')
                      console.log(error)
                  }
              }, function(e){
                  Toast('获取支付通道列表失败'+e.message)
                  console.log("获取支付通道列表失败："+e.message)
              })

            // return
          }

        })
      },
      goBack() {
        this.$router.push('/userCenter')
      }
    },
    mounted(){

    }
}
</script>

<style lang="scss" scoped>
    .page{
        background: #ffffff;
        height: 100vh;
    }
    .container{
        background: #5492ff;
        height: 170px;
    }
.title {
  height: 8vh;
  color: #ffffff;
  font-size: 16px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.time{
   font-size: 20px;
    color: white;
    text-align: center;
}
.content {
  width: 70%;
  padding: 2vh 2vh 2vh 1vh;
  border-radius: 5%;
  border: 2px solid #E9E9E9; /* 设置边框 */
  box-shadow: 0 0 5px #E2E2E2; /* 创建阴影效果 */
}
/*.price{*/
/*  color: #FFCB01;*/
/*  font-size: 26px;*/
/*  text-align: left;*/
/*}*/
.num {
  color: #5756D7;
  font-size: 15px;
}
.filling {
  background-color: #F2F2F7;
  height: 3vh;
}
.custom-placeholder ::placeholder {
  color: #FFCB01; /* 设置 placeholder 文字颜色为红色 */
}

    .top{
        background: linear-gradient(180deg,#538CFA,#eeeeee);
        color: white;
        padding: 20px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        line-height: 1.7;
        img{
            width: 30px;
            height: 30px;
        }
    }
    .info-con{
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        align-items: center;
        padding:0 10px;
        margin-top: -20px;
        .info{
            width: 48%;
            background: #F2F2F7;
            text-align: center;
            border-radius: 10px;
            padding: 14px 0;
            margin-bottom: 10px;
            .price{
                color: #5492ff;
                font-size: 22px;
            }
        }
    }
    .active {
        background-color: #6DA2FF!important;
        color: white!important;
        .price{
            color: white!important;
        }
    }
    .btn-recharge{
        padding:12px 0;
        text-align: center;
        color: white;
        background:linear-gradient(180deg,#5D98FF,#659DFF);
        width: 90%;
        border-radius: 80px;
        margin: 14px auto 0 auto;
    }
    .record-p{
        font-size: 14px;
        width: 100%;
        text-align: center;
    }
</style>
