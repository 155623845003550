import Vue from 'vue'
import App from './App.vue'
import router from './router'
import Vant from 'vant';
 import toast  from 'vant';
import 'vant/lib/index.css';
import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
import { Search } from 'vant';
import VueClipboard from 'vue-clipboard2';
Vue.use(VueClipboard);
Vue.use(Search);
Vue.use(ElementUI);
Vue.use(toast); //全局引用弹窗提示 使用方法 this.$toast.fail("内容");
Vue.config.productionTip = false
Vue.use(Vant);
//Vue.use(toast); // 全局注册提示窗，使用方法 this.$toast.success("提示内容" );
new Vue({
    beforeCreate() {
        Vue.prototype.$EventBus = this; // 定义事件总线
    }
});

// android点击手机物理返回键退出app bug解决
document.addEventListener('plusready',function(){
  var webview = plus.webview.currentWebview();
  plus.key.addEventListener('backbutton', function() {
  webview.canBack(function(e) {
          if (e.canBack) {
                  webview.back();
          } else {
              webview.close();//hide,quit
          }
      })
  });

  });
new Vue({
  router,
  render: function (h) { return h(App) }
}).$mount('#app')
