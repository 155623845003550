<template>
  <div>
    <!-- 触发器 -->
    <van-field readonly clickable 
        v-model="selectedText" 
        @click="showPopup = true" 
        :label="title" 
        :rules="rule"
        required
        :placeholder="placeholder"/>

    <!-- 弹出选择器 -->
    <van-popup v-model="showPopup" position="bottom" :close-on-click-overlay="true">
      <van-picker
        show-toolbar
        :columns="options"
        @cancel="showPopup = false"
        @confirm="handleConfirm"
      />
    </van-popup>
  </div>
</template>

<script>
export default {
  props: {
    options: {
      type: Array,
      required: true
    },
    selectTextName:{
      type: String,
      default: ''
    },
    rule: {
      type: Array,
      required: true
    },
    title: {
      type: String,
      default: ''
    },
    placeholder: {
      type: String,
      default: ''
    },
    value: {
      type: [String, Number],
      default: ''
    }
  },
  data() {
    return {
      selectedText: "",
      showPopup: false,
      selectedValue: this.value
    };
  },
  methods: {
    handleConfirm(value) {
      this.selectedValue = value;
      this.selectedText = value.text
      this.showPopup = false;
      this.$emit('confirm', this.selectedValue.value)
      this.$emit('input', this.selectedValue.value);
    },
    reset() {
      this.selectedValue = []
      this.selectedText = ""
    }
  },
  watch: {
    value(newValue) {
      this.selectedValue = newValue;
    },
    selectTextName(value){
      this.selectedText = value;
    }
  },
  created(){
    if(this.selectTextName){
      this.selectedText = this.selectTextName
    }
  },
  mounted(){
    if(this.selectTextName){
      this.selectedText = this.selectTextName
    }
   }
};
</script>
