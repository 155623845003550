<template>
  <div class="page">
    <div>

      <div class="search-top">
        <van-icon name="arrow-left" size="16" style="color: white;margin-right: 10px" @click="goback"/>
        <div class="search-con"><van-icon name="search" size="16" color="#54769B" class="search-icon" />
          <input type="text" placeholder="快速搜索" id="myInput" @change="inputChange" @keyup="inputChange"></div>
        <!--      <van-search @click="clickSearch" v-model="value" @input="getBus(value)" class="search-con"-->
        <!--                  input-align="center" background="#448DF6" placeholder="搜索商品" />-->
      </div>
      <div>
        <div v-for="(item, index) in list" :key="index" class="good-container" @click="gotoDetail(item)">
          <div class="content">
            <div class="content-p1">
              <div class="p1">
                <div class="details">
                  <span>{{item.brandName}}</span>-
                  <span>{{ item.name }}</span> -
                  <span>{{ item.seriesName }}</span> -
                  <span>{{ item.modelName }}</span> -
                  <span>{{ item.versionName }}</span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import request from '@/utils/request';
import { checkView } from '@/utils/checkUtil'

export default {
  data() {
    return {
      searchName: "",
      pageSize: 10,
      list:''

    }
  },
  methods: {
    goback(){
      this.$router.push("/search")
    },
    getList() {
      //获取品牌选项
      request.get('/business/series/list',
        {
          pageNum: 1,
          pageSize: this.pageSize,
          searchName: this.searchName
        }
      ).then(response => {
          // 处理响应数据
          console.log("res", response)
          this.list = response.rows;
        })
        .catch(error => {
          // 处理请求错误
          console.error('请求错误:', error);
        });
    },
    inputChange(val){
      console.log(val)
      let name = document.getElementById("myInput").value
      if(name){
        this.searchName = name;
      }else{
        this.searchName = ''
      }
      this.getList()
    },
    gotoDetail(item){
      let currentSeries = {
        text:item.seriesName,
        value:item.seriesId
      }
      let currentModel = {
        text:item.modelName,
        value:item.modelId
      }
      let currentVersion = {
        text:item.versionName,
        value:item.versionId
      }
      this.$router.push({
        name: 'searchDetail',
        query: {
          currentBrand: item.brandId,
          currentModel: currentModel,
          currentSeries:  currentSeries,
          currentVersion: currentVersion,
          menuName:'quickSearch',
          queryName:this.searchName
        }
      })
    }
  },
  mounted(){
    const paramValue = this.$route.query;
    if(paramValue&&paramValue.queryName){
      this.searchName = paramValue.queryName
      this.getList()
    }
  },
  created() {
  }
}
</script>

<style lang="scss" scoped>
  .page{
    background: #ffffff;
    height: 100vh;
  }

  .search-top{
    background: #448DF6;
    text-align: left;
    margin: 0 auto;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 8px;
    .search-con{
      background: #AACBF6;
      display: flex;
      align-items: left;
      padding:8px 0;
      width: 75%;
      justify-content: center;
      border-radius: 2px;
      .search-icon{
        margin-top:3px;
      }
      input{
        margin-left: 4px;
        outline: none;
        border: 0;
        color: #333;
        font-size: 13px;
        line-height: 20px;
        background: #AACBF6;
      }
      input::placeholder{
        color: #788EA2;
      }
    }
  }

  .good-container {
    display: flex;
    align-items: center;
    background-color: #ffffff; /* 背景色改为纯白提升清晰度 */
    border-bottom: 1px solid #ddd; /* 边框颜色更细腻 */
    padding: 6px;
  }

  .avatar img {
    width: 50px;
    height: 50px;
    border-radius: 50%;
    margin-right: 15px; /* 调整间距 */
  }

  .content {
    flex-grow: 1;
    .p1{
      display: flex;
      align-items: center;
      color: #333333;
      .sales{
        padding: 2px 4px;
        border-radius: 2px;
        color: white;
        font-size: 10px;
        background: #C12E2B;
        margin-right: 4px;
      }
    }
  }

  .header .shop-name {
    color: #999;
    font-size: 12px;
  }

  .header span {
    color: #999; /* 颜色更柔和 */
    font-size: 12px; /* 统一字体大小 */
    font-family: 'Arial', sans-serif; /* 使用无衬线字体提升现代感 */
  }

  .details {
    color: #1a1919;
    width: 230px;
    height: 18px;
    overflow: hidden;
    font-size: 13px;
    font-family: 'Helvetica', sans-serif; /* 更换字体样式 */
  }

  .content-p1{
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding-top: 5px;
    padding-bottom: 5px;
  }
  .van-icon {
    color: white;
  }
</style>
