<template>

  <div class="contain">
    <van-nav-bar title="商品发布" ></van-nav-bar>
    <van-form ref="form">
    <van-cell-group class="content">
      <div class="input-con">
        <p><span>*</span>类型</p>
        <van-radio-group v-model="form.isNew" class="group-radio" @change="handIsNewConfirm">
          <van-radio v-for="item in isNewOption" :name="item.value" checked-color="#ee0a24" icon-size="14px"
                     >{{item.text}}</van-radio>
        </van-radio-group>
      </div>
<!--      <custom-picker-->
<!--              :options="isNewOption"-->
<!--              title="是否为新机"-->
<!--              placeholder="请选择"-->
<!--              v-model="form.isNew"-->
<!--              :rule="isNewRules"-->
<!--              @confirm="handIsNewConfirm"-->
<!--      />-->
      <custom-picker
        :options="brandOption"
        title="品牌"
        placeholder="请选择品牌"
        v-model="form.brandId"
        :rule="brandRules"
        @confirm="handBrandConfirm"
        ref="brand"
      />
      <custom-picker
        :options="seriesOption"
        title="产品系列"
        placeholder="请选择系列"
        v-model="form.seriesId"
        :rule="seriesRules"
        @confirm="handSeriesConfirm"
        ref="series"
      />
      <custom-picker
        :options="modelOption"
        title="产品型号"
        placeholder="请选择型号"
        v-model="form.modelId"
        :rule="modelRules"
        @confirm="handModelConfirm"
        ref="model"
      />
      <custom-picker
        :options="versionOption"
        title="产品版本"
        placeholder="请选择版本"
        v-model="form.versionId"
        :rule="versionRules"
        ref="version"
      />
      <custom-picker
        :options="statusOption"
        title="状态"
        placeholder="请选择状态"
        v-model="form.status"
        :rule="sttusRules"
      />
      <van-field
        v-model="form.price"
        label="产品价格"
        placeholder="请输入产品价格"
        type="number"
        maxlength="6"
        :rules="priceRules"
        required
      ></van-field>

      <van-field
        v-model="form.num"
        label="库存"
        type="number"
        placeholder="请输入库存"
        maxlength="6"
        :rules="numRules"
        required
      ></van-field>

      <van-field
        v-model="form.damage"
        :label="title1"
        placeholder="请选择"
        :rules="damageRules"
        v-if="showDamage"
        @click="showPopup = true"
        readonly
        required
      ></van-field>
      <van-field
        v-model="form.attribute2"
        :label="title2"
        placeholder="请选择"
        v-if="showDamage"
        @click="showPopup2 = true"
        readonly
      ></van-field>
      <van-field
        v-model="form.attribute3"
        :label="title3"
        placeholder="请选择"
        v-if="showDamage"
        @click="showPopup3 = true"
        readonly
      ></van-field>
      <van-field
        v-model="form.attribute4"
        :label="title4"
        placeholder="请选择"
        v-if="showDamage"
        @click="showPopup4 = true"
        readonly
      ></van-field>
    </van-cell-group>
    <div style="width:90%;margin:30px auto;">
      <van-button style="height: 40px;background: #659DFF" size="large" round type="info" @click="save" :disabled="isDisabled">保存</van-button>

    </div>
  </van-form>
  <van-popup v-model="showPopup" position="bottom" style="text-align: center;">
    <van-checkbox-group v-model="checkedValues" style="padding: 2%;">
      <van-checkbox v-for="option in damageOption" :key="option.value" :name="option.value" style="margin: 2%">
        {{ option.text }}
      </van-checkbox>
    </van-checkbox-group>
    <div class="btn-confirm">
      <van-button style="height: 40px;background: #659DFF" size="large" round type="info"  @click="confirmSelection">确定</van-button>
    </div>

  </van-popup>

    <van-popup v-model="showPopup2" position="bottom" style="text-align: center;">
      <van-checkbox-group v-model="checkedValues2" style="padding: 2%;">
        <van-checkbox v-for="option in attribute2" :key="option.value" :name="option.value" style="margin: 2%">
          {{ option.text }}
        </van-checkbox>
      </van-checkbox-group>
      <div class="btn-confirm">
        <van-button style="height: 40px;background: #659DFF" size="large" round type="info"  @click="confirmSelection2">确定</van-button>
      </div>
    </van-popup>

    <van-popup v-model="showPopup3" position="bottom" style="text-align: center;">
      <van-checkbox-group v-model="checkedValues3" style="padding: 2%;">
        <van-checkbox v-for="option in attribute3" :key="option.value" :name="option.value" style="margin: 2%">
          {{ option.text }}
        </van-checkbox>
      </van-checkbox-group>
      <div class="btn-confirm">
        <van-button style="height: 40px;background: #659DFF" size="large" round type="info"  @click="confirmSelection3">确定</van-button>
      </div>
    </van-popup>

    <van-popup v-model="showPopup4" position="bottom" style="text-align: center;">
      <van-checkbox-group v-model="checkedValues4" style="padding: 2%;">
        <van-checkbox v-for="option in attribute4" :key="option.value" :name="option.value" style="margin: 2%">
          {{ option.text }}
        </van-checkbox>
      </van-checkbox-group>
      <div class="btn-confirm">
        <van-button style="height: 40px;background: #659DFF" size="large" round type="info"  @click="confirmSelection4">确定</van-button>
      </div>
    </van-popup>
<!--    <van-popup v-model="isShowCity" position="bottom">-->
<!--      <van-area-->
<!--              v-model="currentArea"-->
<!--              :columns-num="2"-->
<!--              title="选择地区"-->
<!--              :area-list="areaList"-->
<!--              @confirm="onConfirm"-->
<!--              @cancel="onCancel"-->
<!--      />-->
<!--    </van-popup>-->
  </div>

</template>

<script>
import CustomPicker from '@/components/CustomPicker.vue';
import { addSeries } from '@/api/series'
import { getDicts } from '@/api/dict'
import { Toast, Notify } from "vant";
import request from '@/utils/request'
import { checkView } from '@/utils/checkUtil'
import { areaList } from '@vant/area-data';
export default {
  components: {
    CustomPicker
  },
  data() {
    return {
      currentArea:'',
      active: "0",
      showDamage: false,
      showPopup: false,
      showPopup2: false,
      showPopup3: false,
      showPopup4: false,
      isDisabled: false,
      isShowCity:false,
      checkedValues: [],
      checkedValues2: [],
      checkedValues3: [],
      checkedValues4: [],
      areaList:{},
      form : {
        brandId: "",
        seriesId: "",
        modelId: "",
        versionId: "",
        status: "",
        price: "",
        num: "",
        isNew: "",
        damage: ""
      },
      brandOption: [],
      seriesOption: [],
      modelOption: [],
      versionOption: [],
      statusOption: [],
      isNewOption: [],
      damageOption:[],
      attribute2: [],
      attribute3: [],
      attribute4: [],
      title1:'',
      title2:'',
      title3:'',
      title4:'',
      brandRules: [
        { required: true }
      ],
      seriesRules: [
        { required: true }
      ],
      modelRules: [
        { required: true }
      ],
      versionRules: [
        { required: true }
      ],
      sttusRules: [
        { required: true }
      ],
      isNewRules: [
        { required: true }
      ],
      statusRules: [
        { required: true }
      ],
      damageRules: [
        { required: true }
      ],
      priceRules: [
        { required: true },
        {
          pattern: /^[0-9]*$/,
          message: '请输入整数'
        }
      ],
      numRules : [
        { required: true },
        {
          pattern: /^[0-9]*$/,
          message: '请输入整数'
        }
      ],
      selectProvince:'',
      selectCity:'',

    };
  },
  mounted(){
    this.areaList=areaList;
    this.setDisabled()
    this.getIsNew()
    this.getStatus()
    this.getDamage();
    this.getAttribute2();
    this.getAttribute3();
    this.getAttribute4();
    this.getBrandOptions()
  },
  methods:{
    onConfirm(e){
      if(e.length>=2){
        this.selectProvince=e[0].name
        this.selectCity=e[1].name
        this.isShowCity=false
      }

    },
    onCancel(){
      this.isShowCity=false
    },
    setDisabled(){
      let result = checkView(this.$router.history.current.path);
      this.isDisabled = !result.flag
      if (!result.flag) {
        // Notify({ type: 'danger', message: result.msg })
        this.$toast.fail(result.msg );
        this.$router.back();
      }
    },
    handBrandConfirm(value) {
      this.$refs.series.reset()
      this.$refs.model.reset()
      this.$refs.version.reset()
      this.form.seriesId = ""
      this.form.modelId = ""
      this.form.versionId = ""
      this.getSeriesOptions(value)
    },
    handSeriesConfirm(value) {
      this.$refs.model.reset()
      this.$refs.version.reset()
      this.form.modelId = ""
      this.form.versionId = ""
      this.getModelOptions(value)
    },
    handModelConfirm(value) {
      this.$refs.version.reset()
      this.form.versionId = ""
      this.getVersionOptions(value)
    },
    handIsNewConfirm(value) {
      console.log(value)
      if(value == 1) {
        this.showDamage = true
      } else {
        this.showDamage = false
        this.form.damage = ""
      }
    },
    getIsNew() {
      getDicts("is_new").then(res => {
        this.isNewOption = res.data.map(dict => {
          return {
            "text" : dict.dictLabel,
            "value" : dict.dictValue
          }
        })
        this.form.isNew= res.data[0].dictValue
      })
    },
    getStatus() {
      getDicts("status").then(res => {
        this.statusOption = res.data.map(dict => {
          return {
            "text" : dict.dictLabel,
            "value" : dict.dictLabel
          }
        })
      })
    },
    getDamage() {
      getDicts("damage").then(res => {
        if(res.data&&res.data.length>0) {
          res.data = res.data.sort((a, b) => parseInt(a.dictValue) - parseInt(b.dictValue))
          this.damageOption = res.data.map(dict => {
            this.title1 = dict.dicName
            return {
              "text": dict.dictLabel,
              "value": dict.dictLabel
            }
          })
        }
      })
    },
    getAttribute2() {
      getDicts("attribute2").then(res => {
        if(res.data&&res.data.length>0) {
          res.data = res.data.sort((a, b) => parseInt(a.dictValue) - parseInt(b.dictValue))
          this.attribute2 = res.data.map(dict => {
            this.title2 = dict.dicName
            return {
              "text": dict.dictLabel,
              "value": dict.dictLabel
            }
          })
        }
      })
    },
    getAttribute3() {
      getDicts("attribute3").then(res => {
        if(res.data&&res.data.length>0) {
          res.data = res.data.sort((a, b) => parseInt(a.dictValue) - parseInt(b.dictValue))
          this.attribute3 = res.data.map(dict => {
            this.title3 = dict.dicName
            return {
              "text": dict.dictLabel,
              "value": dict.dictLabel
            }
          })
        }
      })
    },
    getAttribute4() {
      getDicts("attribute4").then(res => {
        if(res.data&&res.data.length>0) {
          res.data = res.data.sort((a, b) => parseInt(a.dictValue) - parseInt(b.dictValue))
          this.attribute4 = res.data.map(dict => {
            this.title4 = dict.dicName
            return {
              "text": dict.dictLabel,
              "value": dict.dictLabel
            }
          })
        }
      })
    },
    async getBrandOptions() {
      this.brandOption = []
      try {
        const response = await request.get('/business/brand/list', {
            pageNum: 1,
            pageSize: 9999
        });
        for (let i = 0; i < response.rows.length; i++) {
          const existingIndex = this.brandOption.findIndex(item => item.text === response.rows[i].name);
          if (existingIndex === -1) {
            let map = {
              text: response.rows[i].name,
              value: response.rows[i].id
            };
            this.brandOption.push(map);
          }
        }
      } catch (error) {
        console.error('请求错误:', error);
      }
    },
    async getSeriesOptions(value) {
      this.seriesOption = []
      try {
        const response = await request.get('/business/productSeries/list', {
            pageNum: 1,
            pageSize: 9999,
            brandId:value
        });
        for (let i = 0; i < response.rows.length; i++) {
          const existingIndex = this.seriesOption.findIndex(item => item.text === response.rows[i].name);
          if (existingIndex === -1) {
            let map = {
              text: response.rows[i].name,
              value: response.rows[i].id
            };
            this.seriesOption.push(map);
          }
        }
      } catch (error) {
        console.error('请求错误:', error);
      }
    },
    async getModelOptions(value) {
      this.modelOption = []
      try {
        const response = await request.get('/business/model/list', {
            pageNum: 1,
            pageSize: 9999,
            seriesId:value
        });
        for (let i = 0; i < response.rows.length; i++) {
          const existingIndex = this.modelOption.findIndex(item => item.text === response.rows[i].name);
          if (existingIndex === -1) {
            let map = {
              text: response.rows[i].name,
              value: response.rows[i].id
            };
            this.modelOption.push(map);
          }
        }
      } catch (error) {
        console.error('请求错误:', error);
      }
    },
    async getVersionOptions(value) {
      this.versionOption = []
      try {
        const response = await request.get('/business/version/list', {
            pageNum: 1,
            pageSize: 9999,
            modelId:value
        });
        for (let i = 0; i < response.rows.length; i++) {
          const existingIndex = this.versionOption.findIndex(item => item.text === response.rows[i].name);
          if (existingIndex === -1) {
            let map = {
              text: response.rows[i].name,
              value: response.rows[i].id
            };
            this.versionOption.push(map);
          }
        }
      } catch (error) {
        console.error('请求错误:', error);
      }
    },
    save() {
      // 执行表单数据的校验
      this.$refs.form.validate().then(() => {
        addSeries(this.form).then(res => {
          if(res.code == 200) {
            Toast.success("发布成功");
            // Notify({ type: 'success', message: res.msg });
            this.goBack();
            return
          }
          Toast.fail(res.msg)
            // Notify({ type: 'danger', message: res.msg });
        })
      }).catch(() => {

      })

    },
    confirmSelection() {
      this.showPopup = false;
      let damage = ''
      let checkValue = this.checkedValues.join(",")
      if(checkValue){
        this.damageOption.forEach(e=>{
          if(checkValue.includes(e.text)){
            if(damage){
              damage = damage+","+e.text
            }else{
              damage = e.text
            }
          }
        })
      }
      this.form.damage = damage;
    },
    confirmSelection2() {
      this.showPopup2 = false;
      let attr2 = ''
      let checkValue = this.checkedValues2.join(",")
      if(checkValue){
        this.attribute2.forEach(e=>{
          if(checkValue.includes(e.text)){
            if(attr2){
              attr2 = attr2+","+e.text
            }else{
              attr2 = e.text
            }
          }
        })
      }
      this.form.attribute2 = attr2;
    },
    confirmSelection3() {
      this.showPopup3 = false;
      let attr = ''
      let checkValue = this.checkedValues3.join(",")
      if(checkValue){
        this.attribute3.forEach(e=>{
          if(checkValue.includes(e.text)){
            if(attr){
              attr = attr+","+e.text
            }else{
              attr = e.text
            }
          }
        })
      }
      this.form.attribute3 = attr;
    },
    confirmSelection4() {
      this.showPopup4 = false;
      let attr = ''
      let checkValue = this.checkedValues4.join(",")
      if(checkValue){
        this.attribute4.forEach(e=>{
          if(checkValue.includes(e.text)){
            if(attr){
              attr = attr+","+e.text
            }else{
              attr = e.text
            }
          }
        })
      }
      this.form.attribute4 = attr;
    },
    goBack() {
      // this.$router.push('/userCenter')
      this.$router.go(-1);
    }
  }
}
</script>

<style lang="scss" scoped>
.content {
  text-align: center;
  padding:0 10px;
}
.avatar {
  height: 20vh;
}
  .group-radio{
    display: flex;
    align-items: center;
    font-size: 14px;
    color: #646566;
    .van-radio:first-child{
      margin-right: 20px;
    }
  }
.input-con{
  display: flex;
  align-items: center;
  font-size: 14px;
  color: #646566;
  padding-left: 8px;
  p{
    display: flex;
    margin-right: 70px;
  }
  span{
    display: block;
    color: red;
    margin-right: 2px;
  }
}
  .van-popup{
    /*margin-bottom: 50px;*/
  }
  ::v-deep .van-popup{
    z-index: 99999!important;
  }
  .btn-confirm{
    width:90%;
    margin: 0 auto 30px auto;
  }
</style>
