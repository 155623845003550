<template>
    <div class="contain">
        <van-nav-bar title="申请提现" left-arrow @click-left="goBack" class="customNavBar"></van-nav-bar>
        <van-form ref="form">
            <van-cell-group class="content">
                <van-row>
                    <van-col :span="24" style="position: relative">
                        <van-field
                                v-model="form.bankAccount"
                                label-width="90"
                                label="提现到银行卡"
                                colon
                                readonly
                        ></van-field>
                        <span id="settingBank" @click="onSettingBank">{{form.bankAccount ? "修改" : "未设置"}}<van-icon
                                style="padding-left: 2px" name="setting-o"/></span>
                    </van-col>
                </van-row>
                <van-row>
                    <van-col :span="24" style="height: .8rem;background: rgb(225 225 225);"></van-col>
                </van-row>
                <p id="extractMoneyTitle">提现金额</p>
                <van-field
                        v-model="form.money"
                        left-icon="gold-coin-o"
                        placeholder="请输入提现金额"
                        type="digit"
                        style="font-size: 20px;"
                        @keypress="moneyKeypress"
                ></van-field>
                <div id="extractMoney">可提现金额：{{balanceMoeny}}</div>
            </van-cell-group>
            <div style="width:90%;margin:15px auto">
                <van-button style="height: 40px;background: #659DFF" size="large" round type="info" @click="save">确认提现</van-button>
            </div>
<!--            <div style="width:90%;margin:15px auto">-->
<!--                <van-button type="info" size="small" @click="save">确认提现</van-button>-->
<!--            </div>-->
        </van-form>
    </div>

</template>

<script>
    import {getUserInfo} from "@/api/user"
    import {addExtractMoney} from "@/api/extractMoney"
    import {Dialog, Toast} from "vant";

    export default {
        data() {
            return {
                balanceMoeny: 0.00,
                totalMoney: 0.00,
                form: {
                    bankAccount: ""
                },
            };
        },
        mounted() {
            this.getUserInfoData()
        },
        methods: {
            getUserInfoData() {
                this.user = JSON.parse(localStorage.getItem('yltUser'));
                if (this.user) {
                    getUserInfo(this.user.userId).then(res => {
                        if (res.code == 200) {
                            this.balanceMoeny = (res.data.money || "0.00");
                            this.form.bankAccount = res.data.bankCard;
                        }
                    })
                }
            },
            moneyKeypress: function () {
                if (this.form.money <= 0) {
                    this.$toast.fail("提现金额必须大于0！");
                    return false;
                }
                if (this.form.money > this.balanceMoeny) {
                    this.$toast.fail("提现金额必须小于等于可提现金额！");
                    return false;
                }
            },
            save: function () {
                if (this.form.money <= 0) {
                    this.$toast.fail("提现金额必须大于0！");
                    return false;
                }
                if (this.form.money > this.balanceMoeny) {
                    this.$toast.fail("提现金额必须小于等于可提现金额！");
                    return false;
                }
                if (this.form.money <= 0) {
                    this.$toast.fail("提现金额必须大于0！");
                    return false;
                }
                if (this.form.money > this.balanceMoeny) {
                    this.$toast.fail("提现金额必须小于等于可提现金额！");
                    return false;
                }
                if (this.form.money > this.balanceMoeny) {
                    this.$toast.fail("提现金额必须小于等于可提现金额！");
                    this.form.money = this.balanceMoeny;
                    return false;
                }
                if (parseFloat(this.balanceMoeny) <= 0) {
                    this.$toast.fail("没有可提金额，提现失败！");
                    return false;
                }
                if (!this.form.bankAccount) {
                    this.$toast.fail("请设置提现银行卡账号！");
                    return false;
                }
                Dialog.confirm({
                    title: "提示",
                    message: "确定提交提现申请吗？"
                }).then(() => {
                    addExtractMoney(this.form).then(res => {
                        if (res.code == 200) {
                            this.$toast.success("提交成功！");
                            this.$router.push('/userCenter')
                        }
                    })
                })
            },
            onSettingBank() {
                this.$router.push('/bankSetting')
            },
            goBack() {
                this.$router.push('/extractMoney')
            }
        }
    }
</script>

<style scoped>
    ::v-deep .van-nav-bar__title {
        color: #464c59 !important;
    }

    ::v-deep .van-nav-bar .van-icon {
        color: #464c59 !important;
    }

    ::v-deep .content {
        padding: 0px !important;
    }

    ::v-deep .van-button {
        width: 100%;
    }

    .content {
        text-align: center;
        padding: 5vh 2vh 0;
    }

    .avatar {
        width: 100px;
        height: 100px;
        border-radius: 50%;
    }

    #settingBank {
        font-size: 14px;
        position: absolute;
        top: .8rem;
        right: 1rem;
        color: #5492ff;
    }

    #extractMoney {
        text-align: left;
        font-size: 12px;
        padding-left: 1rem;
        color: #646566;
        padding-bottom: 1rem;
        padding-top: 1rem;
    }

    #extractMoneyTitle {
        text-align: left;
        font-size: 14px;
        padding-left: 1rem;
        color: #646566;
    }

    .customNavBar {
        background: #fff !important;
        color: #464c59 !important;
    }
</style>
