<template>
  <div class="sd_contains">
    <van-nav-bar :title="title" left-arrow @click-left="goBack"></van-nav-bar>
    <div v-for="(item, index) in goods" :key="index" class="good-container" >
      <!--        <div class="avatar">-->
      <!--          &lt;!&ndash; <img :src="item.avatar || require('../../assets/default-avatar.png')" alt="avatar"> &ndash;&gt;-->
      <!--          <img :src="require('../../assets/default-avatar.png')" alt="avatar">-->
      <!--        </div>-->
      <div class="content">
        <div class="content-p1">
          <div class="p1">
            <!--<div class="sales">出售</div>-->
            <div class="details">
              <span v-if="item.name">{{ item.name }}|</span>
              <span v-if="item.brandName">{{ item.brandName }}</span>
              <span v-if="item.seriesName">|{{ item.seriesName }}</span>
              <span v-if="item.isNewStr">|{{item.isNewStr}}</span>
              <!--<span>{{ item.modelName }}</span>|-->
              <!--<span>{{ item.versionName }}</span>-->
            </div>
          </div>
          <div class="price" >
            <span class="price-value">￥{{ item.price }}</span>
          </div>
        </div>
        <div class="content-p1">
          <div class="header">
<!--            <span class="shop-name">{{ item.businessName }}</span>-->
<!--            <span style="margin-left: 4px;">|</span>-->
            <span v-if="item.cityName"> {{ item.cityName }}</span>
            <span v-if="item.updateTime" style="margin-left: 4px;"><span> | </span>{{ item.updateTime.substring(0,10)}}</span>
          </div>
          <div class="status">
            状态:<span>{{ item.status }}</span>
          </div>
        </div>


      </div>
    </div>
    <van-empty v-show="isEmpty" description="该商家为空" />
  </div>
</template>

<script>
  import { getBusinessSeries, delSeries } from "@/api/series";
  import { zd } from "@/api/business";
  import {Dialog, Notify, Toast} from "vant";
  export default {
    data() {
      return {
        isEmpty:false,
        goods: [],
        currentItem:'',
        showZd:false,
        title:'',
        searchName:'',
        menuName:'',
        queryName:'',
        selectedItem:null
      };
    },
    mounted() {
      this.title=this.$route.query.businessName+"的发布"
      if(this.$route.query&&this.$route.query.searchName){
        this.searchName = this.$route.query.searchName
      }else{
        this.searchName = ''
      }
      if(this.$route.query&&this.$route.query.menuName){
        this.menuName = this.$route.query.menuName
      }else{
        this.menuName =''
      }

      if(this.$route.query&&this.$route.query.queryName){
        this.queryName = this.$route.query.queryName
      }else{
        this.queryName =''
      }

      if(this.$route.query&&this.$route.query.selectedItem){
        this.selectedItem = this.$route.query.selectedItem
      }else{
        this.selectedItem =null
      }

      this.getList();
    },
    methods: {

      showPop(item) {
        this.currentItem = item;
        this.showZd = true
      },
      getList() {
        getBusinessSeries({id:this.$route.query.businessId}).then(res => {
          if(res.data){
            this.goods = this.arrangeData(res.data);
          }

        });
      },
      arrangeData(dataList) {
        dataList.forEach(data => {

        })
        return dataList;
      },
      goBack() {
        this.$router.replace({
          name:'searchDetail',
          query: {
            currentBrand: this.$route.query.currentBrand ,
            currentCity:this.$route.query.currentCity,
            currentIsNew:this.$route.query.currentIsNew,
            currentModel:this.$route.query.currentModel,
            currentSeries:this.$route.query.currentSeries,
            currentVersion:this.$route.query.currentVersion,
            seriesValue:this.$route.query.seriesValue,
            modelValue:this.$route.query.modelValue,
            versionValue:this.$route.query.versionValue,
            searchName: this.searchName,
            menuName:this.menuName,
            queryName:this.queryName,
            selectedItem:this.selectedItem,
            cityName:this.$route.query.cityName,
            seriesName:this.$route.query.seriesName,
            modelName:this.$route.query.modelName,
            versionName:this.$route.query.versionName,
            activeKey:this.$route.query.activeKey,
              form: this.$route.query.form,
              id: this.$route.query.id,
              name: this.$route.query.name,
          }
        })
      },
      detail(id) {
        //todo 跳转到修改页面
        this.$router.push(`/productDetail/${id}`);
      },
      del(id) {
        Dialog.confirm({
          title: "确认删除",
          message: "确定要删除该商品吗？"
        })
                .then(() => {
                  delSeries(id).then(res => {
                    if(res.code == 200) {
                      Notify({ type: 'success', message: '删除成功' });
                      this.getList();
                      return
                    }
                    Notify({ type: 'danger', message: res.msg });
                  });
                })
                .catch(() => {
                  // 用户点击了取消按钮
                });
      }
    }
  };
</script>

<style lang="scss" scoped>
  .van-nav-bar {
    background-color: #5492ff !important;
  }
  .van-nav-bar .van-icon {
    color: #fff !important;
  }

  .good_contains {
    width: 90%;
    background-color: hsl(206, 28%, 95%);
    border-radius: 10px;
    margin: 5px auto;
    padding: 5px;
  }

  .name {
    color: #2e3104;
    font-weight: bold;
    line-height: 28px;
    font-size:16px;
    margin-bottom: 2px;
  }
  .brandName {
    font-size: 13px;
    color: #333;
    margin-bottom: 3px;
  }
  .shopName {
    font-size: 14px;
    color: #999;
  }
  .status {
    font-size: 12px;
    color: #999;
    margin-bottom: 3px;
    span{
      color: #43BA85;
    }
  }
  .goodPrice {
    font-size: 14px;
    font-weight: simsun;
    color: #6e767d;
  }
  .van-nav-bar {
    background-color: #5492ff;
  }
  .van-nav-bar__title {
    color: #fff;
  }
  .createTime {
    font-size: 13px;
    color: #999;
  }
  .shopName1 {
    color: #f1a52b;
    font-size: 16px;
    font-weight: 800;
  }
  .doorplate {
    margin-left: 22px;
    font-size: 14px;
    font-weight: simsun;
    color: #6e767d;
  }
  .doorplate1 {
    color: #5c8fce;
    font-size: 13px;
    font-weight: 900;
  }
  .info{
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  .info-bottom{
    display: flex;
    align-items: center;
    justify-content: space-between;
    .btn-top{
      background: transparent;
      border: 1px solid #5492FF;
      font-size: 13px;
      border-radius: 80px;
      padding: 3px 10px;
      color: #5492FF;
    }
  }

  .good-container {
    display: flex;
    align-items: center;
    background-color: #ffffff; /* 背景色改为纯白提升清晰度 */
    border-bottom: 1px solid #ddd; /* 边框颜色更细腻 */
    padding: 6px;
  }
  .content-p1{
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  .content {
    line-height: 1.5;
    flex-grow: 1;
    .p1{
      display: flex;
      align-items: center;
      color: #333333;
      .sales{
        padding: 2px 4px;
        border-radius: 2px;
        color: white;
        font-size: 10px;
        background: #C12E2B;
        margin-right: 4px;
      }
    }
  }
  .details {
    color: #1a1919;
    width: 230px;
    height: 18px;
    overflow: hidden;
    font-size: 13px;
    font-family: 'Helvetica', sans-serif; /* 更换字体样式 */
  }
  .price {
    color: #333; /* 调整颜色以突出价格 */
    font-size: 13px; /* 加大价格字体大小 */
  }

  .price-value {
    font-size:13px; /* 价格数字更大 */
    color: #333; /* 价格颜色更鲜艳 */
  }
  .header{
    display: flex;
    align-items: center;
  }
  .header .shop-name {
    color: #999;
    font-size: 12px;
    /*max-width: 60px;*/
    /*display: block;*/
    /*overflow: hidden;*/
    /*white-space: nowrap;*/
    /*text-overflow: ellipsis;*/
  }

  .header span {
    color: #999; /* 颜色更柔和 */
    font-size: 12px; /* 统一字体大小 */
    font-family: 'Arial', sans-serif; /* 使用无衬线字体提升现代感 */
  }

</style>
