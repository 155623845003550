<template>
  <div class="contain">
    <van-nav-bar title="修改密码" left-arrow @click-left="goBack"></van-nav-bar>
    <van-form ref="form">
      <van-cell-group class="content">
        <van-field
          v-model="form.oldPassword"
          label="旧密码"
          type="password"
          placeholder="请输入旧密码"
          required
          :rules="oldPasswordRules"
        />
        <van-field
          v-model="form.newPassword"
          label="新密码"
          type="password"
          placeholder="请输入新密码"
          required
          :rules="newPasswordRules"
        />
        <van-field
          v-model="form.confirmPassword"
          label="确认密码"
          type="password"
          placeholder="请再次输入新密码"
          required
          :rules="confirmPasswordRules"
        />
      </van-cell-group>
      <div style="margin:30px auto">
        <div class="btn-con" @click="save">保存</div>

      </div>
    </van-form>
  </div>
</template>

<script>
import { updateUserPwd } from "@/api/user"; 
import { Notify } from 'vant';
export default {
  data() {
    return {
      validateConfirmPassword(rule, value, callback) {
        if (value !== this.form.newPassword) {
          callback(new Error('两次输入的密码不一致'));
        } else {
          callback();
        }
      },
      uploadUrl: process.env.VUE_APP_BASE_API + "/system/user/upload", // 上传图片地址
      form: {
        oldPassword: '',
        newPassword: '',
        confirmPassword: ''
      },
        oldPasswordRules: [
          { required: true, message: '请输入旧密码', trigger: 'blur' }
        ],
        newPasswordRules: [
          { required: true, message: '请输入新密码', trigger: 'blur' },
          { min: 6, message: '密码长度至少为6位', trigger: 'blur' }
        ],
        confirmPasswordRules: [
          { required: true, message: '请再次输入新密码', trigger: 'blur' },
          { validator: this.validateConfirmPassword, trigger: 'blur' }
        ]
    };
  },
  mounted() {},
  methods: {
    save() {
      this.$refs.form.validate().then(res => {
        updateUserPwd(this.form.oldPassword,this.form.newPassword).then(res => {
          if(res.code == 200) {
            // Notify({ type: 'success', message: '修改成功,请重新登录' });
              this.$toast.success('修改成功,请重新登录');
            this.$router.push("/login")
            return
          }
          // Notify({ type: 'danger', message: res.msg });
            this.$toast.fail(res.msg);
        })
      }).catch(error => {})
    },
    goBack() {
      this.$router.push("/userCenter");
    }
  }
};
</script>

<style scoped>
.content {
  text-align: center;
  padding: 0 10px;
}
</style>