<template>
    <div class="contain">
        <van-nav-bar title="我邀请的"  left-arrow @click-left="goBack"></van-nav-bar>
            <van-pull-refresh v-model="isLoading" @refresh="onRefresh">
                <van-list
                    v-model="loading"
                    :finished="finished"
                    finished-text="没有更多了"
                    @load="onLoad"
                    style="padding: 2%;"
                >
                    <van-cell v-for="(item,index) in list" :key="index">
                        <div style="display: flex;justify-content: space-between;">
                            <div>被邀请人：{{ item.inviteeName }}</div>
                            <div>
                              状态：<span :class="item.statusClass">{{ item.statusStr }}</span>
                            </div>
                        </div>
                    </van-cell>
                </van-list>
            </van-pull-refresh>
    </div>

  </template>

  <script>
  import { getAllInvite } from "@/api/invite"
  import { checkView } from '@/utils/checkUtil'
  import { Notify } from 'vant';
  export default {
    data() {
      return {
        list: [],
        loading: false,
        finished: true,
        isLoading: false,
        count: 0, // 当前数据量
        statusClass: ""
      };
    },
    mounted() {
        this.init()
    },
    methods: {
      init() {
        let result = checkView()
        if (result.flag) {
          this.getList()
        } else {
          Notify({ type: 'danger', message: result.msg})
        }
      },
      getList() {
        this.loading = true
        getAllInvite().then(res => {
            if(res.data) {
                this.list = this.handleData(res.data)
            }
            this.isLoading = false;
            this.loading = false
        })
      },
      handleData(data) {
        return data.map(item => {
          item.statusClass = item.status == '0' ? "red" : "green"
          return item;
        })
      },
      onLoad() {
        this.list = []
        this.getList()
      },
      onRefresh() {
        this.list = []
        this.getList()
      },
      goBack() {
        this.$router.push('/userCenter')
      }
    },
  };
  </script>
  
  <style scoped>
  .red {
    color: red
  }
  .green {
    color: green;
  }
  </style>
