<template>
  <div class="chat-container">
    <!-- 头部 -->
    <!-- <div class="header">我的</div> -->
<!--    <van-nav-bar title="我的" />-->
    <!-- 背景图 -->
    <div class="back" :style="{ backgroundImage: `url(${myBg})`}">
      <div class="top-head">
        个人中心
      </div>
      <div>
        <div>
          <div style="width: 100%; height: 20vh;border-radius: 100px;">
            <div v-if="unLogin" class="login_contains">
              <div class="unLoginCss" @click="login">请登录</div>
            </div>
            <div v-if="!unLogin" class="login_contains">
              <div class="avatarImg">
                <img :src="avatar" />
              </div>
              <div class="title_contains">
                <div class="headerTitle">{{ user.userName }}</div>
                <div class="expairTime">有效期:{{ expirationTime }}</div>
                <div class="expairTime" @click="btnShare(user.invitationCode)">邀请码:<span class="invite-code">{{ user.invitationCode }}</span></div>
              </div>
            </div>
          </div>
          <!-- <img style="width: 20vh; height: 20vh;border-radius: 100px;margin-top:20px" :src="require('@/assets/avatar.png')"/> -->
          <!-- <img style="width: 200px; height: 200px;border-radius: 100px;margin-top:20px" :src="user.img"/> -->
        </div>
        <div>
          <!-- <span style="line-height: 100px;font-size: 30px;font-weight: bold;text-align: center;color: rgba(102, 92, 92, 0.404);">{{ user.username }}</span> -->
        </div>
      </div>
    </div>
    <div class="commonUtils">
      <div style="font-weight: bold;padding-bottom: 10px;">常用功能</div>
<!--      <van-divider />-->
      <van-cell-group>
        <van-cell center icon="user-o" to="/userInfo" title="个人信息" size="large" is-link />
        <van-cell center icon="points" to="/extractMoney" title="我的钱包" size="large" is-link />
        <van-cell center icon="plus" to="/publish" title="发布商品" size="large" is-link />
        <van-cell center icon="comment-o" to="/myPublish" title="我发布的" size="large" is-link />
        <van-cell v-if="info!=null&&info.auditingStatus==1" center icon="edit" to="/editAuth" title="修改资料" size="large" is-link />

        <van-cell center icon="shop-o" to="/authentication" title="资料认证" size="large" is-link />
        <van-cell center icon="gold-coin-o" to="/recharge" title="会员充值" size="large" is-link />
        <van-cell center icon="records-o" to="/updatePwd" title="修改密码" size="large" is-link />
        <van-cell center icon="envelop-o" to="/feedback" title="意见反馈" size="large" is-link />
        <van-cell center icon="friends-o" to="/invite" title="我邀请的" size="large" is-link />
      </van-cell-group>
      <div style="margin-top:10px;" v-if="!unLogin">
        <van-button style="height: 40px;background: #659DFF" size="large" round type="info" @click="layout">退出登录</van-button>
      </div>

    </div>

  </div>
</template>

<script>
import { Dialog } from 'vant';
import { formatDate } from "@/utils/date"
import { myBg } from '@/utils/imgBase64.js';
import {testAwardMoney} from '@/api/user'
import {getBusinessInfo} from '@/api/business'
export default {
  data() {
    return {
      myBg: '',
      info: {},
      user: {},
      unLogin: true,
      avatar: "",
      expirationTime: ""
    }
  },
  mounted() {
    this.myBg = myBg;

  },
  methods: {
    btnShare(value){
      this.$router.push({
        path:'/qrcode',query:{
          code:value,
          nickname:this.user.userName
        }
      })
    },
    layout() {
      Dialog.confirm({
        title: '退出登录',
        message: '确认退出?',
      })
        .then(() => {
          localStorage.setItem("yltUser", '')
          localStorage.setItem("businessInfo","")
          localStorage.setItem("token", '')
          // window.location.reload(); // 退出登录后刷新页面
          this.login()
        })
        .catch(() => {
          // on cancel
        });
    },
    login() {
      this.$router.push('/login')
    },
    businessInfo(){
      getBusinessInfo(this.user.id).then((res) => {
        if (res.data){
          this.info=res.data;
          localStorage.setItem("businessInfo",JSON.stringify(res.data))
          this.expirationTime= formatDate(this.info.expirationTime)
        }else{
          localStorage.setItem("businessInfo",null);
          this.expirationTime ="未认证"
        }

      })
    }
    // testRechange(){
    //   alert();
    //   testAwardMoney("14658e9a-5d8b-481e-bf3f-9b6ef5f2006c1").then((res) => {
    //     console.log(res);
    //   })
    // }
  },

  created() {
    try{
      this.user = JSON.parse(localStorage.getItem('yltUser'))
      console.log("user", this.user)
      //console.log("user",this.user)
      if (this.user == null || this.user == '' || this.user == 'undefined') {
        this.unLogin = true;
      } else {
        this.unLogin = false;
        this.avatar = this.user.avatar == '' ? "https://cube.elemecdn.com/3/7c/3ea6beec64369c2642b92c6726f1epng.png" : this.user.avatar;
        this.businessInfo();
      }
      console.log(this.unLogin)
    }catch (e) {
    }
  }
}
</script>

<style lang="scss" scoped>
.header {
  background-color: #f5f5f5;
  padding: 10px;
  text-align: center;
  font-size: 20px;
  font-weight: bold;
}

.chat-container {
  width: 100%;
  height: 120vh;
  background-color: #f5f5f5;
}

.back {
  width: 100%;
  background: #5492ff;
  .top-head{
    text-align: center;
    color: white;
    padding-top: 20px;
  }
}

.commonUtils {
  background-color: #fff;
  /*box-shadow: 1px 1px 15px #ddd;*/
  /*border: 1px solid #ddd;*/
  border-radius: 12px;
  padding: 20px;
  margin: -30px 14px 0 14px;
}

.login_contains {
  display: flex;
  margin-top: 20px;
}

.unLoginCss {
  width: 100vh;
  height: 20vh;
  line-height: 20vh;
  margin: 0 auto;
  color: #fff;
  font-size: 20px;
  text-align: center;
}
.avatarImg{
  margin: auto 20px;
  img{
    width:60px;
    height:60px;
    border-radius: 50%;
  }
}
.title_contains{
  /*margin: auto 1px;*/
}
.headerTitle{
  font-size: 20px;
  color:#fff
}
.expairTime{
  color:#eee;
  font-size: 14px;
  margin-top:5px;
}
  .invite-code{
    text-decoration:underline;
  }
::v-deep .van-dialog{
  border-radius: 6px!important;
}
::v-deep .van-dialog__confirm{
  color: #5492ff!important;
}
</style>
