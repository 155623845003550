<template>
  <div class="contain">
    <van-nav-bar title="绑定银行卡"  left-arrow @click-left="goBack" class="customNavBar"></van-nav-bar>
    <van-form ref="form">
      <van-cell-group class="content">
        <van-field  v-model="form.bankName"
                    label="姓名"
                    placeholder="请输入持卡人姓名"
        ></van-field>
        <van-field
                v-model="form.openingBank"
                label="开户行"
                placeholder="请输入开户行"
        ></van-field>
        <van-field
                v-model="form.bankCard"
                label="银行卡号"
                placeholder="请输入银行卡号"
                type="digit"
        ></van-field>
      </van-cell-group>
      <div style="width:90%;margin:15px auto">
        <van-button style="height: 40px;background: #659DFF" size="large" round type="info" @click="save">确定</van-button>
      </div>
<!--      <div style="width:90%;margin:15px auto">-->
<!--        <van-button type="info" size="small" @click="save" >确定</van-button>-->
<!--      </div>-->
    </van-form>
  </div>

</template>

<script>
import { getUserInfo,updateBank} from "@/api/user"
import {Dialog} from "vant";
export default {
  data() {
    return {
      form : {
        bankName:"",
        openingBank:"",
        bankCard:""
      },
    };
  },
  mounted(){
    this.getUserInfoData()
  },
  methods:{
    getUserInfoData() {
      this.user = JSON.parse(localStorage.getItem('yltUser'));
      if(this.user){
        getUserInfo(this.user.userId).then(res => {
          if(res.code == 200) {
            this.form.bankCard = res.data.bankCard;
            this.form.openingBank = res.data.openingBank;
            this.form.bankName = res.data.bankName;
          }
        })
      }
    },
    save:function(){
      if(!this.form.bankCard){
        this.$toast.fail("银行卡号不允许为空");
        return false;
      }
      if(!this.form.openingBank){
        this.$toast.fail("开户行不允许为空");
        return false;
      }  if(!this.form.bankName){
        this.$toast.fail("银行卡姓名不允许为空");
        return false;
      }

      let $this = this;
      Dialog.confirm({
        title: "提示",
        message: "确定提交吗？"
      }).then(() => {
        updateBank(this.form).then(res => {
          if (res.code == 200) {
            this.$toast.success("提交成功！");
            this.goBack();
          }
        })
      })
    },
    goBack() {
      this.$router.back();
    }
  }
}
</script>

<style scoped>
  ::v-deep .van-nav-bar__title{
    color:#464c59 !important;
  }
  ::v-deep .van-nav-bar .van-icon{
    color:#464c59 !important;
  }
  ::v-deep .content{
    padding: 0px !important;
  }
  ::v-deep .van-button{
    width: 100%;
  }
.content {
  text-align: center;
  padding: 5vh 2vh 0;
}
.avatar {
  width: 100px;
  height: 100px;
  border-radius: 50%;
}
#settingBank{
  font-size: 14px;
  position: absolute;
  top: .8rem;
  right: 1rem;
  color: #19beff;
}
  #extractMoney{
    text-align: left;
    font-size: 12px;
    padding-left: 1rem;
    color:#646566;
    padding-bottom: 1rem;
    padding-top: 1rem;
  }
  #extractMoneyTitle{
    text-align: left;
    font-size: 14px;
    padding-left: 1rem;
    color:#646566;
  }
  .customNavBar{
    background: #fff !important;
    color: #464c59 !important;
  }
</style>
