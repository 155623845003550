// api.js

import axios from 'axios';
import router from '@/router'
import { Toast } from 'vant';
// const baseURL= 'https://n211v25562.goho.co'
const baseURL= 'http://txyapi.tongxunyuan.com'
// const baseURL= 'http://localhost:8090'
const instance = axios.create({
  baseURL: baseURL, // 根据你的实际情况修改
  timeout: 10000, // 请求超时时间
});

// 请求拦截器
instance.interceptors.request.use(
  config => {

    // 可以在发送请求之前对请求做一些处理，比如添加token等
    //config.headers['Authorization'] = 'Bearer eyJhbGciOiJIUzUxMiJ9.eyJsb2dpbl91c2VyX2tleSI6ImJlM2I0MGIxLWQ2YWMtNDg5OC05NWYwLTg4ZjI0ZDZiOTU2OSJ9.gvRafedhjh_HLMGz7qtEiyMWGjQBSHnRJ7VDGMe0vKWU7Vcbwy6ysK1PIIxmDzDl61APjNiHsqciD5RyNG46iA'

    if(localStorage.getItem('token') == '') {
      config.headers['Istoken'] = 'false'
    } else {
      config.headers['Authorization'] = 'Bearer '+localStorage.getItem('token')
    }
    return config;
  },
  error => {
    // 对请求错误做些什么
    return Promise.reject(error);
  }
);

// 响应拦截器
instance.interceptors.response.use(
  response => {
    const code = response.data.code || 200;
    if (code === 401) {
        setTimeout(function(){
            router.push("/login")
            localStorage.setItem("yltUser", '')
            localStorage.setItem("token", '')
            Toast.fail("登录已过期，请重新登录。");
        },100)
    }
    // 对响应数据做一些处理，比如解析JSON等
    return response.data;
  },
  error => {
    // 对响应错误做些什么
    return Promise.reject(error);
  }
);

export default {
  // 封装GET请求
  get(url, params = {}) {
    return instance.get(url, { params });
  },
  // 封装POST请求
  post(url, data = {}) {
    return instance.post(url, data);
  },
  // 封装PUT请求
  put(url, data = {}) {
    return instance.put(url, data);
  },
  // 封装DELETE请求
  delete(url, params = {}) {
    return instance.delete(url, { params });
  },
    baseURL
};
