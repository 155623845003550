<template>
  <div class="container" style="padding-bottom: 100px;">
    <van-nav-bar title="修改资料" left-arrow @click-left="goBack"></van-nav-bar>
    <van-form ref="form">
      <div style="display: flex;flex-direction: column;align-items: center;">
        <van-cell-group class="content">
          <van-field v-model="form.doorNumber" label="门牌号" placeholder="请输入门牌号,如3A20" :readonly="isDisabled"></van-field>
          <van-field v-model="form.phone" label="联系手机号" placeholder="请输入你的联系手机号" type="tel" :readonly="isDisabled"></van-field>
        </van-cell-group>

      </div>
      <div style="width:90%;margin:15px auto">
        <van-button style="height: 40px;background: #659DFF" type="info" size="large" @click="save" round :disabled="isDisabled">提交</van-button>
      </div>


    </van-form>

  </div>
</template>

  <script>
import {
  getBusinessInfo,
  updateBusinessInfo,
  addBusinessInfo,editAuth
} from "@/api/business";
import { getCityData } from "@/api/city"
import { uploadImg } from "@/api/common"
import { Notify } from 'vant';
import { formatDate } from "@/utils/date"
import request from '@/utils/request'
export default {
  data() {
    return {
      baseUrl:request.baseURL,
      files: [],
      cityData: [

      ],
      cityName: "",
      showPopup: false,
      form: {
        id: null,
        userId: "",
        cityId: "",
        name: "",
        bankCard: "",
        openingBank: "",
        doorNumber: "",
        licenseUrl: null,
        frontUrl: null,
        reverseUrl: null,
        handUrl: null,
        isListing: '',
        auditingStatus: '0',
        phone:''
      },
      uploadUrl: process.env.VUE_APP_BASE_URL + "/common/upload",
      isDisabled: true
    };
  },
  mounted() {
    this.init()
  },
  created() {
    this.user = JSON.parse(localStorage.getItem('yltUser'))
    console.log("user", this.user)
    if (!!this.user) {
      this.$set(this.form,"phone",this.user.phonenumber);
    }
  },
  methods: {
    async init() {
      // await this.getCity()
      await this.getInfo();
    },
    getInfo() {
      getBusinessInfo().then(res => {
        if(res.data) {
          this.form = res.data
          this.cityName = this.form.cityName
          this.isDisabled = this.form.auditingStatus == 0
        } else {
          this.isDisabled = false
        }
      })
    },
    getCity() {
      getCityData().then(res => {
       this.cityData = res.data.map(obj => {
          return {"text": obj.name,"value" : obj.id.toString()}
        })
        console.log(this.cityData)
      })
    },
    show() {
      this.showPopup = true;
    },
    onConfirm(item) {
      this.form.cityId = item.value;
      this.cityName = item.text;
      this.showPopup = false;
    },
    onCancel() {
      this.showPopup = false;
    },
    save() {
      // 执行表单数据的校验
      this.$refs.form
        .validate()
        .then(() => {
          this.form.isListing = this.getIsListing()
          if (this.form.id != null) {

            if (this.form.doorNumber==''){
              this.$toast.fail("请输入门牌号");
              return false;
            }
            if (this.form.phone==''){
              this.$toast.fail("请输入手机号");
              return false;
            }
            editAuth(this.form).then(res => {
              if(res.code == 200) {
                this.setStorage()
                // Notify({ type: 'success', message: '修改成功,等待审核' })
                this.$toast.success('修改成功' )
                this.goBack();
                return
              }
              // Notify({ type: 'danger', message: res.msg });
              this.$toast.fail(res.msg);
            });
          }

        })
        .catch(error => {});
    },
    setStorage() {
      let info = JSON.parse(localStorage.getItem("businessInfo"))
      info = this.form
      info.isRecharge = this.form.isRecharge ? this.form.isRecharge : "0"
      info.expirationTime = this.form.expirationTime ? this.form.expirationTime : formatDate()
      localStorage.setItem("businessInfo",JSON.stringify(info))
    },
    getIsListing() {
      if (
        this.form.licenseUrl == null ||
        this.form.frontUrl == null ||
        this.form.reverseUrl == null ||
        this.form.handUrl == null
      ) {
        return "0";
      }
      return "1";
    },
    deletImg() {
      this.form.licenseUrl = null;
    },
    deletFrontImg() {
      this.form.frontUrl = null;
    },
    deletReverseImg() {
      this.form.reverseUrl = null;
    },
    deletHandImg() {
      this.form.handUrl = null;
    },
    goBack() {
      this.$router.push("/userCenter");
    },
    uploadLicensImage(file) {
      const formData = new FormData();
      formData.append('file', file.file);
      formData.append('uploadType','Licens');
      uploadImg(formData).then(res => {
        if(res.code == 200) {
          // this.form.licenseUrl = res.url

          if(!!res.ocrInfo){
            let ocrInfo = JSON.parse(res.ocrInfo);
            if(!!ocrInfo.Code){
              this.$toast.fail(ocrInfo.Message);
            }else{
              this.form.licenseUrl = this.baseUrl+res.fileName;
              let ocrCompanyInfo = JSON.parse(ocrInfo.data).data;
              this.$set(this.form,"name",ocrCompanyInfo.companyName);
              this.$set(this.form,"comCreditCode",ocrCompanyInfo.creditCode);
              this.$set(this.form,"comCompanyType",ocrCompanyInfo.companyType);
              this.$set(this.form,"comLegalPerson",ocrCompanyInfo.legalPerson);
              this.$set(this.form,"comBusinessScope",ocrCompanyInfo.businessScope);
              this.$set(this.form,"comRegisteredCapital",ocrCompanyInfo.registeredCapital);
              this.$set(this.form,"comRegistrationDate",ocrCompanyInfo.RegistrationDate);
              this.$set(this.form,"comValidPeriod",ocrCompanyInfo.validPeriod);
              this.$set(this.form,"comCompanyForm",ocrCompanyInfo.companyForm);
              this.$set(this.form,"comIssueDate",ocrCompanyInfo.issueDate);
              this.$set(this.form,"comTitle",ocrCompanyInfo.title);
              this.$set(this.form,"address",ocrCompanyInfo.businessAddress);
            }
          }else{
            this.$toast.fail("营业执照数据识别失败！");
          }

        }
      })
    },
    uploadFrontImage(file) {
      const formData = new FormData();
      formData.append('file', file.file);
      formData.append('uploadType','idCardFront');
      uploadImg(formData).then(res => {
        if(res.code == 200) {

          if(!!res.ocrInfo){
            let ocrInfo = JSON.parse(res.ocrInfo);
            if(!!ocrInfo.Code){
              this.$toast.fail(ocrInfo.Message);
            }else{
              this.form.frontUrl = this.baseUrl + res.fileName // res.url
              let ocrUserInfo = JSON.parse(ocrInfo.data).data.face.data;
              this.$set(this.form,"idCardName",ocrUserInfo.name);
              this.$set(this.form,"idCardAddress",ocrUserInfo.address);
              this.$set(this.form,"idCardBirthDate",ocrUserInfo.birthDate);
              this.$set(this.form,"idCardEthnicity",ocrUserInfo.ethnicity);
              this.$set(this.form,"idCardIdNumber",ocrUserInfo.idNumber);
              this.$set(this.form,"idCardSex",ocrUserInfo.sex);
            }
          }else{
            this.$toast.fail("身份证正面信息识别失败！");
          }
        }
      })
    },
    uploadReverseImage(file) {
      const formData = new FormData();
      formData.append('file', file.file);
      formData.append('uploadType','idCardReverse');
      uploadImg(formData).then(res => {
        if(res.code == 200) {

          if(!!res.ocrInfo){
            let ocrInfo = JSON.parse(res.ocrInfo);
            if(!!ocrInfo.Code){
              this.$toast.fail(ocrInfo.Message);
            }else{
              this.form.reverseUrl = this.baseUrl+res.fileName // res.url
              let ocrUserInfo = JSON.parse(ocrInfo.data).data.back.data;
              this.$set(this.form,"idCardIssueAuthority",ocrUserInfo.issueAuthority);
              this.$set(this.form,"idCardValidPeriod",ocrUserInfo.validPeriod);
            }
          }else{
            this.$toast.fail("身份证背面信息识别失败！");
          }

        }
      })
    },
    uploadHandImage(file) {
      const formData = new FormData();
      formData.append('file', file.file);
      uploadImg(formData).then(res => {
        if(res.code == 200) {
          this.form.handUrl = this.baseUrl+res.fileName // res.url
        }
      })
    },
  }
};
</script>

  <style scoped>
.container {
}
.content {
  text-align: center;
  width: 100%;
}
.upload {
  height: 20vh;
  width: 100%;
  border-radius: 5%;
  margin-top: 1vh;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}
.img {
  height: 18vh !important;
  width: 90% !important;
}
.van-uploader__upload {
  background-color: #c8c8c8 !important;
}
.uploader-slot {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 20px;
}
    .img-upload{
      padding: 0 20px;
    }
</style>
