<template>
    <div class="contain">
        <van-nav-bar title="充值记录" left-arrow @click-left="goBack"
                     style="background: #fff !important;color: #464c59 !important;"></van-nav-bar>
        <van-list
                v-model="loading"
                :finished="finished"
                finished-text="没有更多了"
                @load="onLoad"
                offset="80"
                swipeable="true"

        >
            <van-cell v-for="(item,index) in list" :key="index" class="record">
                <div class="record-top">
                    <div class="name">充值{{item.actNum}}天赠送{{item.giftNum}}天</div>
                    <div class="price">￥{{item.price}}</div>
                </div>
                <div class="time">{{item.createTime}}</div>
            </van-cell>
        </van-list>
    </div>

</template>

<script>
    import {getList} from "@/api/order"

    export default {
        data() {
            return {
                state: 0,
                list: [],
                loading: false,
                finished: false,
                pageNum: 0,
                pageSize: 20
            };
        },
        mounted() {
        },
        methods: {
            tabs(name, title) {
                this.pageNum = 0;
                this.list = [];
                this.loading = true;
                this.finished = false;
                this.onLoad();
            },
            onLoad() {
                this.pageNum++;
                let $this = this;
                getList({pageNum: this.pageNum, pageSize: this.pageSize}).then(res => {
                    if (res.code == 200) {
                        $this.loading = false;
                        if (res.rows.length > 0) {
                            if ($this.list.length == 0) {
                                $this.list = res.rows;
                            } else {
                                $this.list.push(...res.rows);
                            }
                        }
                        if ($this.list.length >= res.total) {
                            $this.finished = true;
                        }
                    }

                })
            },
            goBack() {
                this.$router.back();
            }
        }
    }
</script>

<style lang="scss" scoped>
    .content {
        text-align: center;
        padding: 5vh 2vh 0;
    }

    .avatar {
        width: 100px;
        height: 100px;
        border-radius: 50%;
    }

    .money {
        height: 8.8rem;
        width: 96%;
        border-radius: .2rem;
        background: linear-gradient(to right, #19beff, #160ce5);
        margin: auto;
        position: relative;
        top: .2rem;
    }

    ::v-deep .van-nav-bar__title {
        color: #464c59 !important;
    }

    ::v-deep .van-nav-bar .van-icon {
        color: #464c59 !important;
    }

    ::v-deep .van-cell {
        padding: 4px 16px;
    }

    .extractButton {
        border-radius: 1rem;
        border: 1px solid rgb(255, 255, 255);
        text-align: center;
        line-height: 1.5rem;
        width: 4.2rem;
        position: relative;
        left: 6rem;
        top: 0rem;
    }
    .record{
        width: 100%;

        .record-top{
            display: flex;
            align-items: center;
            justify-content: space-between;
            .name{
                font-size: 16px;
                color: #5492ff;
            }
            .price{
                color: red;
                font-size: 14px;
            }
        }
        .time{
            font-size: 13px;
            color: #999;
            text-align: right;
        }
    }
</style>
