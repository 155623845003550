import request from '@/utils/request'

// 查询提现申请列表
export function listExtractMoney(query) {
  return request.get('/extractMoney/extractMoney/list',query);
}

// 查询提现申请详细
export function getExtractMoney(id) {
  return request.get('/extractMoney/extractMoney/' + id)
}

// 新增提现申请
export function addExtractMoney(data) {
  return request.post('/extractMoney/extractMoney',data)
}

// 修改提现申请
export function updateExtractMoney(data) {
  return request.put('/extractMoney/extractMoney',data)
}

// 删除提现申请
export function delExtractMoney(id) {
  return request.delete('/extractMoney/extractMoney/' + id);
}
