<template>
    <div class="wrapper">
        <van-icon v-if="!isSpread" name="revoke" style="font-size: 1rem;color:#fff !important;" @click="back()"/>
        <div class="info">
            <div class="name">
                <div>您好！</div>
                <div>欢迎来到通讯源！</div>
            </div>
            <div class="from">
                <van-form @submit="onSubmit">
                    <van-field v-model="username" name="usernmae" label="用户名" placeholder="用户名" required
                    />
                    <van-field v-model="password" type="password" name="password" required label="密码" placeholder="密码"
                    />
                    <van-field v-model="phone" name="phone" label="手机号" required placeholder="手机号"
                    />
                    <van-row type="flex">
                        <van-col span="16">
                            <van-field
                                    v-model="smsCode"
                                    maxlength="6"
                                    label="验证码"
                                    placeholder="验证码"
                                    required

                            />
                        </van-col>
                        <van-col span="8">
                            <div style="margin-top: 10px">
                                <van-button
                                        block
                                        type="info"
                                        style="font-size: 10px"
                                        size="mini"
                                        :disabled="smsNotCanSend"
                                        @click="sendSMS"
                                >{{showText}}
                                </van-button>
                            </div>
                        </van-col>
                    </van-row>
                    <van-field v-model="inviterCode" name="inviterCode" label="邀请码" placeholder="邀请码" maxlength="6"/>
<!--                    <div class="img">-->
<!--                        <div style="font-size: 14px;color:#646566;float:left;margin:20px">头像</div>-->
<!--                        <div class="upload">-->
<!--                            <van-uploader v-model="fileList" :max-count="1" :after-read="afterRead"-->
<!--                                          accept="image/png, image/jpeg"/>-->
<!--                        </div>-->
<!--                    </div>-->
                    <div style="margin: 16px;">
                        <van-button round block type="info" native-type="submit">注册</van-button>
                        <p v-if="!isSpread" class="register-p" @click="$router.push('/login')">已有账号？去登录</p>
                    </div>
                </van-form>
            </div>
        </div>
    </div>
</template>

<script>
    import request from '@/utils/request';
    import {Toast} from 'vant';

    export default {
        computed: {
            showText() {
                let text = "获取验证码";
                if (this.smsNotCanSend) {
                    text = `重新获取(${this.timer})s`;
                }
                return text;
            },
        },

        data() {
            return {
                baseUrl: request.baseURL,
                username: '',
                password: '',
                phone: '',
                smsCode: '',
                img: '',
                inviterCode: '',
                isSpread: false,
                fileList: [],
                timer: 300,
                smsNotCanSend: false, //发送后禁止点击

            }
        },
        created() {
            this.inviterCode = this.$route.query.spread;
            if (this.$route.query.spread) {
                this.isSpread = true;
            }
        },
        methods: {
            sendSMS() {
                if (!this.phone) {
                    // Notify({ type: 'danger', message: '请输入手机号' });
                    this.$toast.fail("请输入手机号");
                    return false;
                }
                this.setItime()
                request.post('/system/user/sendCode', {
                    userName: this.username,
                    phonenumber: this.phone
                }).then(response => {
                    if (response.code == 200) {
                        console.log(response)
                    }
                })
            },
            setItime() {
                // 设置加载时间
                this.smsNotCanSend = true;
                var timeInter = setInterval(() => {
                    if (this.timer == 0) {
                        this.smsNotCanSend = false;
                        this.timer = 300;
                        clearInterval(timeInter);
                    } else {
                        this.timer--;
                    }
                }, 1000);
            },
            back: function () {
                // this.$router.go(-1);
                this.$router.back();
            },
            onSubmit(values) {

                if (!this.username) {
                    // Notify({ type: 'danger', message: '请输入用户名' });
                    this.$toast.fail("请输入用户名");
                    return false;
                }
                if (!this.password) {
                    // Notify({ type: 'danger', message: '请输入密码' });
                    this.$toast.fail("请输入密码");
                    return false;
                }
                if (!this.phone) {
                    // Notify({ type: 'danger', message: '请输入手机号' });
                    this.$toast.fail("请输入手机号");
                    return false;
                }
                if (!this.smsCode) {
                    // Notify({ type: 'danger', message: '请输入密码' });
                    this.$toast.fail("请输入验证码");
                    return false;
                }
                if (this.password.length <= 5) {
                    // Notify({ type: 'danger', message: '密码要超过5位' });
                    this.$toast.fail("密码要超过5位");
                    return false;
                }

                let showLoading = this.$toast.loading({message:"加载中...",  duration: 2000 , forbidClick: true,overlay:true});
                request.post('/system/user/register', {
                    userName: this.username,
                    password: this.password,
                    phonenumber: this.phone,
                    avatar: this.img,
                    smsCode: this.smsCode,
                    nickName: this.username,
                    inviterCode: this.inviterCode
                })
                    .then(response => {
                        try{
                            //关闭加载框
                            if(showLoading) showLoading.close();
                        }catch (e) { }

                        if (response.code == 200) {
                            this.$toast.success('注册成功');
                            if (this.isSpread) {
                                window.location.href = "https://www.pgyer.com/ALw2gvZf";
                            } else {
                                this.$router.push('/login');
                            }

                            return
                        }
                        this.$toast.fail('注册失败：' + response.msg)
                    })
                    .catch(error => {
                        this.$toast.fail('注册失败：' + error.response.data.error)
                    });
            },
            afterRead(file) {
                // 使用封装好的 Axios 发送 POST 请求
                console.log("file", file)
                const formData = new FormData();
                formData.append('file', file.file);
                console.log("formData", formData)
                // 使用封装好的 Axios 发送 POST 请求
                request.post('/common/upload', formData)
                    .then(response => {
                        console.log("response", response)
                        this.img = this.baseUrl + res.fileName
                    })
                    .catch(error => {

                    });
            },

        }
    }
</script>

<style lang="scss" scoped>
    .wrapper {
        height: 100vh;
        background: linear-gradient(0deg, #4C8CF9, #8ED0FB);
        padding: 0 30px;
        p {
            margin: 0;
        }
        .info {
            background: url("../assets/login_app.png");
            background-size: 100% 100%;
            height: 100vh;

            .name {
                color: white;
                padding-top: 60px;
                div:first-child {
                    font-size: 24px;
                    margin-bottom: 5px;
                }
                div:last-child {
                    margin-bottom: 40px;
                }
            }
        }
        ::v-deep .el-input--medium .el-input__inner {
            height: 42px !important;
            line-height: 42px !important;
        }
        ::v-deep .el-form-item {
            margin-bottom: 0 !important;
        }
        .register-p {
            color: #333;
            text-align: center;
            font-size: 13px;
            margin-top: 16px;
        }
        .from {
            background: white;
            border-radius: 10px;
            padding: 10px;
        }
    }

    .img {
        display: flex
    }

    .upload {
        margin-top: 10px;
        margin-left: 40px;
    }

    ::v-deep .van-icon-revoke {
        font-size: 1rem;
        font-weight: 900;
        color: #fff;
        position: relative;
        top: 1.5rem;
    }
</style>
