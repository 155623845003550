<template>
  <div class="contain">
    <van-nav-bar title="个人信息"  left-arrow @click-left="goBack"></van-nav-bar>
    <van-form ref="form">
    <van-cell-group class="content">
      <van-uploader
        :after-read="afterAvatarRead"
        :action="uploadUrl"
        :max-count="1"
        accept="image/*"
        delete="deletImg"
        style="margin: 0 auto;"
      >
        <img v-if="form.avatar" :src="form.avatar" alt="Avatar" class="avatar"  />
      </van-uploader>
      <van-field
        v-model="form.nickName"
        label="昵称"
        placeholder="请输入昵称"
        required
        :rules="[{ required: true, message: '请输入昵称' }]"
      ></van-field>
      <van-field
        v-model="form.phonenumber"
        label="手机号"
        placeholder="请输入手机号"
        type="tel"
        :rules="phoneRules"
        required
      ></van-field>
      <van-field
        v-model="form.email"
        label="邮箱"
        placeholder="请输入邮箱"
      ></van-field>
      <van-field
        v-model="form.sex"
        name="gender"
        label="性别"
      >
        <template #input>
          <van-radio-group v-model="form.sex" direction="horizontal">
            <van-radio name="0">男</van-radio>
            <van-radio name="1">女</van-radio>
          </van-radio-group>
        </template>
      </van-field>
    </van-cell-group>
    <div style="width:90%;margin:15px auto">
        <van-button style="height: 40px" type="info" size="large" @click="save" round>保存</van-button>
    </div>
  </van-form>
  </div>

</template>

<script>
import { getUserProfile,updateUserProfile } from "@/api/user"
import { Notify } from "vant";
import { uploadImg } from "@/api/common"
import request from '@/utils/request'
export default {
  data() {
    return {
      uploadUrl: process.env.VUE_APP_BASE_URL + "/common/upload",
      baseUrl:request.baseURL,
      fileList: [],
      form : {
        userId: '',
        avatar: null,
        nickName: '',
        phonenumber: '',
        email: '',
        sex: '0'
      },
      phoneRules: [
        { required: true, message: '请输入手机号' },
        {
          pattern: /^1[3456789]\d{9}$/,
          message: '手机号格式不正确'
        }
      ],
      emailRules: [
        { required: true, message: '请输入邮箱地址' },
        {
          type: 'email',
          message: '邮箱地址格式不正确'
        }
      ]
    };
  },
  created() {
    // this.$EventBus.$on('globalAlert', (message) => {
    //   alert(message); // 或者使用更复杂的弹窗组件
    // });
  },
  mounted(){
    this.getUserInfo()
  },
  methods:{
    getUserInfo() {
      getUserProfile().then(res => {
        if(res.code == 200) {
          this.form = res.data
          console.log(this.form.avatar)
        }
      })
    },
    afterAvatarRead(file) {
      console.log(file)
      const formData = new FormData();
      formData.append('file', file.file);
      uploadImg(formData).then(res => {

        if(res.code == 200) {
          this.form.avatar = this.baseUrl+res.fileName
        }
      })
    },
    save() {
      // 执行表单数据的校验
      this.$refs.form.validate().then(() => {
        updateUserProfile(this.form).then(res => {
          if(res.code == 200) {
            let user = JSON.parse(localStorage.getItem('yltUser'));
            user.avatar = this.form.avatar
            localStorage.setItem('yltUser',JSON.stringify(user))
            // Notify({ type: 'success', message: '修改成功' });
            this.$toast.success("修改成功");
            this.goBack()
            return
          }
          // Notify({ type: 'danger', message: res.msg });
          this.$toast.success(res.msg );
        })
      }).catch(() => {

      })

    },
    deletImg() {
      this.form.avatar = ""
    },
    goBack() {
      this.$router.push('/userCenter')
    }
  }
}
</script>

<style scoped>
.content {
  text-align: center;
  padding: 5vh 2vh 0;
}
.avatar {
  width: 100px;
  height: 100px;
  border-radius: 50%;
}

</style>
