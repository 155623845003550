<template>
    <div class="contain">
<!--        position: fixed;  width: 100%;-->
        <van-nav-bar title="提现记录" left-arrow @click-left="goBack"
                     style="background: #fff !important;color: #464c59 !important;"></van-nav-bar>
<!--        style="position: fixed; top: 2.8rem; z-index: 999;width: 100%;"-->
        <van-tabs v-model="state" color="#5492ff" @change="tabs" title-active-color=" rgb(84, 146, 255)" >
            <van-tab title="未提现"></van-tab>
            <van-tab title="提现中"></van-tab>
            <van-tab title="已提现"></van-tab>
            <!--            <van-tab title="失  败"></van-tab>-->
        </van-tabs>
        <van-list
                v-model="loading"
                :finished="finished"
                finished-text="没有更多了"
                @load="onLoad"
                offset="80"
                swipeable="true"

        >
            <van-cell v-for="(item,index) in list" :key="index">
                <el-row>
                    <el-col :span="8">
                        金额
                    </el-col>
                    <el-col :span="8">
                        时间
                    </el-col>
                    <el-col :span="8"  >
                        收款账号
                    </el-col>
                </el-row>
                <el-row>
                    <el-col :span="6">
                        <p style="color: #00bc1a;font-size: 16px;padding-top: 4px;line-height: 12px;">
                            ￥{{item.money}}</p>
                    </el-col>
                    <el-col :span="6">
                        <p style="text-align: center;line-height: 12px;color: #999999">{{item.createTime}}</p>
                    </el-col>
                    <el-col :span="12">
                        <p style="text-align: center;line-height: 12px;color: #34bfa3">{{item.bankName}}<br/>{{item.bankCard}}</p>
                    </el-col>
                </el-row>
            </van-cell>
        </van-list>
    </div>

</template>

<script>
    import {listExtractMoney} from "@/api/extractMoney"

    export default {
        data() {
            return {
                state: 0,
                list: [],
                loading: false,
                finished: false,
                pageNum: 0,
                pageSize: 5
            };
        },
        mounted() {
        },
        methods: {
            tabs(name, title) {
                this.pageNum = 0;
                this.list = [];
                this.loading = true;
                this.finished = false;
                this.onLoad();
            },
            onLoad() {
                this.pageNum++;
                let $this = this;
                listExtractMoney({state: this.state, pageNum: this.pageNum, pageSize: this.pageSize}).then(res => {
                    if (res.code == 200) {
                        $this.loading = false;
                        if (res.rows.length > 0) {
                            if ($this.list.length == 0) {
                                $this.list = res.rows;
                            } else {
                                $this.list.push(...res.rows);
                            }
                        }
                        if ($this.list.length >= res.total) {
                            $this.finished = true;
                        }
                    }

                })
            },
            goBack() {
                this.$router.push('/extractMoney')
            }
        }
    }
</script>

<style scoped>
    .content {
        text-align: center;
        padding: 5vh 2vh 0;
    }

    .avatar {
        width: 100px;
        height: 100px;
        border-radius: 50%;
    }

    .money {
        margin-top: .2rem;
        height: 8.8rem;
        width: 96%;
        border-radius: .2rem;
        background: linear-gradient(to right, #19beff, #160ce5);
        margin: auto;
        position: relative;
        top: .2rem;
    }

    ::v-deep .van-nav-bar__title {
        color: #464c59 !important;
    }

    ::v-deep .van-nav-bar .van-icon {
        color: #464c59 !important;
    }

    ::v-deep .van-cell {
        padding: 4px 16px;
    }

    .extractButton {
        border-radius: 1rem;
        border: 1px solid rgb(255, 255, 255);
        text-align: center;
        line-height: 1.5rem;
        width: 4.2rem;
        position: relative;
        left: 6rem;
        top: 0rem;
    }
</style>
