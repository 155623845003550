<template>
  <div class="sd_contains">
    <van-nav-bar :title="title" @click-left="goBack" left-arrow>

      <!--<template v-if="isNew == '1'" #right>-->
        <!--<van-icon name="list-switch" @click="showPopup" size="22px"/>-->
      <!--</template>-->
    </van-nav-bar>
    <!--<van-popup v-model="show" position="right" :style="{ width: '30%', height: '100%' }">-->
      <!--<div class="typeName">-->
        <!--<van-cell v-for="item in damageStatusOption" :key="item.value" :title="item.text"-->
                  <!--@click="selectItem(item)"/>-->
      <!--</div>-->
    <!--</van-popup>-->
    <div v-show="isNew&&isNew==='1'" style="margin-top: 6px;width: 98%;margin:6px auto;">
      <van-dropdown-menu active-color="#4C8CF9" >
        <van-dropdown-item ref="download1" v-model="value1" :title="title1" @change="getOption1" style="font-size:14px;">
          <van-checkbox-group class="checkbox-container" v-model="checkedValues1" style="padding: 2%;font-size: 14px !important;">
            <van-checkbox v-for="option in option1"
                          :key="option.value"
                          :name="option.value"
                          label-position="left"
                          icon-size="14"
                          style="margin: 2%;position: relative;">
              <span :style="setStyle(checkedValues1,option.text)">{{ option.text }}</span>
              <template #icon="props">
                  <img class="img-icon " :src="props.checked ? activeIcon : ''" style="width: 20px;position: relative;top: -2px;" />
              </template>
            </van-checkbox>
          </van-checkbox-group>
          <div class="check-button">
            <van-button type="info" size="small" class="buttonReSetting"
                        @click="confirmcancel1">重置
            </van-button>
            <van-button type="info" size="small" class="confirmBtn"
                        @click="confirmSelection1">确认
            </van-button>
          </div>
        </van-dropdown-item>

        <van-dropdown-item ref="download2" v-model="value2" :title="title2" @change="getOption2">
          <van-checkbox-group class="checkbox-container" v-model="checkedValues2" style="padding: 2%;font-size: 14px !important;">
            <van-checkbox v-for="option in option2"  label-position="left" :key="option.value" :name="option.value" icon-size="14" style="margin: 2%;position: relative;">
              <span :style="setStyle(checkedValues2,option.text)">{{ option.text }}</span>
              <template #icon="props">
                <img class="img-icon " :src="props.checked ? activeIcon : ''" style="width: 20px;position: relative;top: -2px;" />
              </template>
            </van-checkbox>
          </van-checkbox-group>
          <div class="check-button">
            <van-button type="info" size="small" class="buttonReSetting"
                        @click="confirmcancel2">重置
            </van-button>
            <van-button type="info" size="small" class="confirmBtn"
                        @click="confirmSelection2">确认
            </van-button>
          </div>
        </van-dropdown-item>

        <van-dropdown-item ref="download3" v-model="value3" :title="title3"  @change="getOption3">
          <van-checkbox-group class="checkbox-container" v-model="checkedValues3" style="padding: 2%;font-size: 14px !important;">
            <van-checkbox v-for="option in option3"  label-position="left" :key="option.value" :name="option.value" icon-size="14" style="margin: 2%;position: relative;">
              <span :style="setStyle(checkedValues3,option.text)">{{ option.text }}</span>
              <template #icon="props">
                <img class="img-icon " :src="props.checked ? activeIcon : ''" style="width: 20px;position: relative;top: -2px;" />
              </template>
            </van-checkbox>
          </van-checkbox-group>
          <div class="check-button">
            <van-button type="info" size="small" class="buttonReSetting"
                        @click="confirmcancel3">重置
            </van-button>
            <van-button type="info" size="small" class="confirmBtn"
                        @click="confirmSelection3">确认
            </van-button>
          </div>
        </van-dropdown-item>
        <van-dropdown-item ref="download4" v-model="value4" :title="title4"  @change="getOption4">
          <van-checkbox-group class="checkbox-container" v-model="checkedValues4" style="padding: 2%;font-size: 14px !important;">
            <van-checkbox v-for="option in option4"  label-position="left" :key="option.value" :name="option.value" icon-size="14" style="margin: 2%;position: relative;">
              <span :style="setStyle(checkedValues4,option.text)">{{ option.text }}</span>
              <template #icon="props">
                <img class="img-icon " :src="props.checked ? activeIcon : ''" style="width: 20px;position: relative;top: -2px;" />
              </template>
            </van-checkbox>
          </van-checkbox-group>
          <div class="check-button">
            <van-button type="info" size="small" class="buttonReSetting"
                        @click="confirmcancel4">重置
            </van-button>
            <van-button type="info" size="small" class="confirmBtn"
                        @click="confirmSelection4">确认
            </van-button>
          </div>
        </van-dropdown-item>
      </van-dropdown-menu>
    </div>

    <van-empty v-show="isEmpty" description="该商品暂时无货"/>

    <div class="detail_contain" v-show="!isEmpty">
      <div v-for="(item, index) in itemList" :key="index" class="detail-item" :data-status="item.status"
           :style="{ backgroundColor: getStatusColor(item.status) }" @click="goShop(item)">
        <p class="shop-name"><span class="name-title">商家:</span><span class="name">{{ item.businessName }}<span v-if="item.cityName">({{item.cityName}})</span></span> </p>
        <div>
          <p>门牌:<span class="code">{{ item.doorNumber }}</span></p>
        </div>
        <div>
          <p>价格:<span class="price">{{ item.price }}</span></p>
        </div>
        <div>
          <p>状态:<span class="status">{{ item.status }}</span></p>
        </div>
        <div v-if="isNew == '1'">
          <p>损坏情况:<span class="status">{{ item.damage }}</span></p>
        </div>
        <div>
          <p>时间:<span class="time">{{ item.updateTime.substring(0,10) }}</span></p>
        </div>
        <div class="btn">
          <div class="btn-p">
            <div @click.stop="showPhone(item.phone)">
              <van-icon name="phone" color="#2d94f8"/>
              商家询价
            </div>
            <!--                        <div @click.stop="plate(item.id)" ><van-icon name="cart" color="#2d94f8" />下单</div>-->
<!--            <div @click.stop="showUpdate(item)">-->
<!--              <van-icon name="bars" color="#2d94f8"/>-->
<!--              反馈-->
<!--            </div>-->
            <div @click.stop="showPop(item)">
              <van-icon name="sort" color="#2d94f8"/>
              积分置顶
            </div>
          </div>
          <!--                    <div class="btn-p">-->
          <!--                        <div @click="plate(item.id)" ><van-icon name="cart" color="#2d94f8" />下单</div>-->

          <!--&lt;!&ndash;                        <van-popover v-model="item.showPopover" trigger="showPop" :actions="actions" placement="left-end"&ndash;&gt;-->
          <!--&lt;!&ndash;                                     @select="onSelect">&ndash;&gt;-->
          <!--&lt;!&ndash;                            <template #reference>&ndash;&gt;-->
          <!--&lt;!&ndash;                                <div @click="showPop(item)"><van-icon name="sort" color="#2d94f8" />积分置顶</div>&ndash;&gt;-->
          <!--&lt;!&ndash;                            </template>&ndash;&gt;-->
          <!--&lt;!&ndash;                        </van-popover>&ndash;&gt;-->
          <!--                    </div>-->
        </div>
      </div>
    </div>

    <!-- 固定在底部的 "+" 图标 -->
    <!-- <div class="add-icon" @click="showAddDiv">
    <van-icon name="cash-back-record" size="70" color="#599bd4" />
 </div> -->
    <van-dialog v-model="showAdd" width="300px"
                :before-close="add" show-cancel-button message="">
      <p class="dialog-title">请如实填写,勿扰乱市场价格</p>
      <p class="dialog-desc">请根据参考价如实填写</p>
      <van-field v-model="price" type="number" label="价格" placeholder="请输入价格"/>
      <van-field v-model="status" @click="clickStatus" label="状态" placeholder="请选择状态"/>

    </van-dialog>
    <van-popup v-model="showAddUpdate" position="bottom" :close-on-click-overlay="true">
      <van-picker show-toolbar :columns="isNew == '0' ? statusOption : damageStatusOption"
                  @cancel="showAddUpdate = false" @confirm="handleAddUpdateConfirm"/>
    </van-popup>


    <van-dialog v-model="showZd" title="是否花费10积分进行置顶?" @confirm="Zd" show-cancel-button message="">

    </van-dialog>
    <!-- 点击后显示的固定在底部的 div -->
    <div v-if="isNew == 0 " class="add-icon" @click="onClickAdd">
      <van-icon name="cash-back-record" size="50" color="#599bd4"/>
    </div>
<!--    <van-popup v-model="showPickerPopup" position="bottom" :close-on-click-overlay="true">-->
<!--      <van-picker show-toolbar :columns="isNew == '0' ? statusOption : damageStatusOption"-->
<!--                  @cancel="showPickerPopup = false" @confirm="handleConfirm"/>-->
<!--    </van-popup>-->

    <van-dialog v-model="showPickerPopup" title="是否反馈为无货?" @confirm="handleConfirm" show-cancel-button message="">

    </van-dialog>
  </div>
</template>

<script>
  import request from '@/utils/request';
  import {Toast, Notify} from 'vant';
  import {getDicts} from '@/api/dict'
  import {updateStatus} from '@/api/series'
  import { checkView } from '@/utils/checkUtil'
  import activeIconUrl from "../../assets/select.png";
  export default {
    data() {
      return {
        isEmpty: true,
        showZd: false,
        showAddUpdate: false,
        currentItem: {},
        title: '',
        itemList: [],
        showTool: false,
        showPopover: false,
        actions: [{text: '积分置顶'}],
        showSearch: true,
        show: false,
        status: '',
        isNew: '',
        seriesId: '',
        modelId: '',
        versionId: '',
        brandId: '',
        showAddDivContent: false, // 控制点击后显示的固定在底部的 div 的显示状态
        showAdd: false,
        params: {},
        num: 0,
        price: 0,
        showPickerPopup: false,
        stockOption:[
          {label:'有货',value:1},
          {label:'无货',value:0},
        ],
        statusOption: [],
        damageStatusOption: [],
        currentDamage: '',
        currentCity: {},
        currentBrand: '',
        currentSeries: '',
        currentModel: '',
        currentVersion: '',

        seriesValue:'',
        modelValue:'',
        versionValue:'',
        cityName:'',

        selectedItem: null,
        searchName: '',

        title1: '',
        title2: '',
        title3: '',
        title4: '',
        value1: '',
        value2: '',
        value3: '',
        value4: '',
        option1: [],
        option2: [],
        option3: [],
        option4: [],
        checkedValues1: [],
        checkedValues2: [],
        checkedValues3: [],
        checkedValues4: [],

        dropdownItem1: {},
        dropdownItem2: {},
        dropdownItem3: {},
        dropdownItem4: {},

        isSelect1:false,
        isSelect2:false,
        isSelect3:false,
        isSelect4:false,

        damage:'',
        attribute2:'',
        attribute3:'',
        attribute4:'',

        menuName:'',
        queryName:'',
        currentIsNew:'',
        activeIcon:activeIconUrl
      }
    },
    methods: {
      dropdownChange() {
        this.isSelect1=true;
        console.log(this.isSelect1)
      },
      confirmcancel1(){
        this.checkedValues1 = []
        this.confirmSelection1();
      },
      confirmcancel2(){
        this.checkedValues2 = []
        this.confirmSelection2();
      },
      confirmcancel3(){
        this.checkedValues3 = []
        this.confirmSelection3();
      },
      confirmcancel4(){
        this.checkedValues4 = []
        this.confirmSelection4();
      },
      confirmSelection1() {
        this.damage = ''
        let checkValue = this.checkedValues1.join(",")
        if(checkValue){
          this.option1.forEach(e=>{
            if(checkValue.includes(e.text)){
              if(this.damage){
                this.damage = this.damage+","+e.text
              }else{
                this.damage = e.text
              }
            }
          })
        }
        this.dropdownItem1.toggle(false)
        this.getList()
      },
      confirmSelection2() {
        this.attribute2 = ''
        let checkValue = this.checkedValues2.join(",")
        if(checkValue){
          this.option2.forEach(e=>{
            if(checkValue.includes(e.text)){
              if(this.attribute2){
                this.attribute2 = this.attribute2+","+e.text
              }else{
                this.attribute2 = e.text
              }
            }
          })
        }
        this.dropdownItem2.toggle(false)
        this.getList()
      },
      confirmSelection3() {
        this.attribute3 = ''
        let checkValue = this.checkedValues3.join(",")
        if(checkValue){
          this.option3.forEach(e=>{
            if(checkValue.includes(e.text)){
              if(this.attribute3){
                this.attribute3 = this.attribute3+","+e.text
              }else{
                this.attribute3 = e.text
              }
            }
          })
        }
        this.dropdownItem3.toggle(false)
        this.getList()
      },
      confirmSelection4() {
        this.attribute4 = ''
        let checkValue = this.checkedValues4.join(",")
        if(checkValue){
          this.option4.forEach(e=>{
            if(checkValue.includes(e.text)){
              if(this.attribute4){
                this.attribute4 = this.attribute4+","+e.text
              }else{
                this.attribute4 = e.text
              }
            }
          })
        }
        this.dropdownItem4.toggle(false)
        this.getList()
      },
      goBack() {
        console.log(this.currentCity)
        // this.$router.back(-1);
        if(this.menuName){
          this.$router.push({name: this.menuName,
            query: {
              queryName: this.queryName,
            }
          })
        }else {
          this.$router.push({
            name: 'search',
            query: {
              currentCity: this.currentCity.text ? this.currentCity : '',
              currentSeries: this.currentSeries,
              currentModel: this.currentModel,
              currentVersion: this.currentVersion,
              seriesValue:this.$route.query.seriesValue,
              modelValue:this.$route.query.modelValue,
              versionValue:this.$route.query.versionValue,
              selectedItem: this.selectedItem,
              currentBrand: this.currentBrand,
              cityName:this.$route.query.cityName,
              activeKey: this.$route.query.activeKey,
              form: this.$route.query.form,
              id: this.$route.query.id,
              name: this.$route.query.name,
              currentIsNew: this.currentIsNew,
              seriesName:this.$route.query.seriesName,
              modelName:this.$route.query.modelName,
              versionName:this.$route.query.versionName,
            }
          })
        }
      },
      goShop(item) {
        console.log(item.businessId)
        // this.$router.push("/product").catch(error => {
        //     console.log(error)
        // })

        // this.$router.push( '/userCenter')
        this.$router.push({
          path: '/product', query: {
            businessId: item.businessId,
            businessName: item.businessName,
            currentBrand: this.$route.query.currentBrand,
            currentCity: this.$route.query.currentCity,
            currentIsNew: this.$route.query.currentIsNew,
            currentModel: this.$route.query.currentModel,
            currentSeries: this.$route.query.currentSeries,
            currentVersion: this.$route.query.currentVersion,
            seriesValue:this.$route.query.seriesValue,
            modelValue:this.$route.query.modelValue,
            versionValue:this.$route.query.versionValue,
            menuName:this.menuName,
            queryName:this.queryName,
            selectedItem:this.selectedItem,
            cityName:this.$route.query.cityName,
            seriesName:this.$route.query.seriesName,
            modelName:this.$route.query.modelName,
            versionName:this.$route.query.versionName,
            activeKey:this.$route.query.activeKey,
            form: this.$route.query.form,
            id: this.$route.query.id,
            name: this.$route.query.name,
          },
          searchName: this.searchName
        })
      },
      onSelect() {

      },
      clickStatus() {
        this.showAddUpdate = true
      },
      add(action,done) {
        if (action==='confirm'){
          if(this.price==''||this.price<=0){
            Toast('价格不能为空且不小于0');
            done(false);
          }else if(this.status==''){
            Toast('请选择状态');
            done(false);
          }else{
            request.post('/business/series', {

              brandId: this.brandId,
              seriesId: this.seriesId,
              modelId: this.modelId,
              versionId: this.versionId,
              status: this.status,
              price: this.price,
              isNew: this.isNew,
              // cityId: this.params.currentCity,
              cityName:this.cityName
            }).then(response => {
              // 处理响应数据
              Toast.success('保存成功');

              this.getList();
              done();
              // this.itemList = response.rows;
              this.status='';
            })
            .catch(error => {
              // 处理请求错误
              console.error('请求错误:', error);
              done();
            });
          }

        }else{
          done();
        }

      },
      updateStatus(item) {
        console.log(item)
      },
      // 根据 status 字段返回不同的背景颜色
      getStatusColor(status) {
        if (status === '无货') {
          //  return '#ff0000'; // 红色
        } else if (status === '有货') {
          //  return '#ffffff'; // 绿色
        } else {
          //  return '#b2d5fd'; // 默认颜色
        }
      },
      Zd() {
        console.log("zd")
        request.post('/business/series/zd',
          {
            id: this.currentItem.id
          }
        )
          .then(response => {
            console.log(response)
            // 处理响应数据
            if (response.code == '200') {
              Toast.success(response.msg)
            } else {
              Toast.fail(response.msg)
            }
            this.getList();

          })
          .catch(error => {
            // 处理请求错误
            console.error('请求错误:', error);
          });
      },
      onClickAdd() {
        let result = checkView("/publish");
        if (!result.flag) {
          // Notify({ type: 'danger', message: result.msg })
          this.$toast.fail(result.msg);
          this.showAdd = false;
        }else{
          this.showAdd = true
        }

      },
      showAddDiv() {
        this.showAddDivContent = !this.showAddDivContent;
      },
      selectItem(item) {
        this.currentDamage = item.value
        this.show = false;
        this.getList()
      },
      showT() {
        this.showTool = !this.showTool
      },
      back() {
        this.$router.push('/search')

      },
      plate(id) {
        console.log("下单")
        this.$router.push({
          name: 'plate', query: {
            id: id,
            currentBrand: this.$route.query.currentBrand,
            currentCity: this.$route.query.currentCity,
            currentIsNew: this.$route.query.currentIsNew,
            currentModel: this.$route.query.currentModel,
            currentSeries: this.$route.query.currentSeries,
            currentVersion: this.$route.query.currentVersion
          }
        })
      },
      showPhone(phone) {
        if (phone) {
          // window.location.href = `tel://` + phone;
          plus.device.dial(phone);
        } else {
          Toast.fail('该商家暂无手机号');
        }

      },
      showPop(item) {
        this.currentItem = item;
        console.log(item)
        //查看是否是自己的商品
        request.post('/business/series/isCanZd',
          {
            id: this.currentItem.id
          }
        )
          .then(response => {
            console.log(response)
            //处理响应数据
            if (response.code == '200') {
              this.showZd = true
            } else {
              Toast.fail("置顶失败，只能置顶自己的商品！")
            }
          })
          .catch(error => {
            // 处理请求错误
            console.error('请求错误:', error);
          });

      },

      showPopup() {
        this.show = true;
      },
      getList() {
        // console.log("canshu", {
        //         pageNum: 1,
        //         pageSize: 9999,
        //         isNew: this.isNew,
        //         seriesId: this.seriesId,
        //         modelId: this.modelId,
        //         versionId: this.versionId,
        //         brandId: this.brandId,
        //         cityId: this.params.currentCity?this.params.currentCity.value:'',
        //         damage: this.currentDamage
        //     })
        //根据城市名称 获取
        let cityName = ''
        if (this.params && this.params.cityName) {
          cityName = this.params.cityName
        }

        //获取品牌选项
        request.get('/business/series/list',
          {
            pageNum: 1,
            pageSize: 9999,
            isNew: this.isNew,
            seriesId: this.seriesId,
            modelId: this.modelId,
            versionId: this.versionId,
            brandId: this.brandId,
            cityName: cityName,
            damage: this.damage,
            attribute2: this.attribute2,
            attribute3: this.attribute3,
            attribute4: this.attribute4,
            searchName: this.searchName
          }
        )
          .then(response => {
            // 处理响应数据
            console.log("res", response)
            this.itemList = response.rows;
            if (response.rows && response.rows.length > 0) {
              this.isEmpty = false
            }else{
              this.isEmpty = true
            }
          })
          .catch(error => {
            // 处理请求错误
            console.error('请求错误:', error);
          });

      },
      getStatus() {
        getDicts("status").then(res => {
          console.log("object", res)
          this.statusOption = res.data.map(dict => {
            return {
              "text": dict.dictLabel,
              "value": dict.dictLabel
            }
          })
        })
      },
      getDamage() {
        getDicts("damage").then(res => {
          if(res.data&&res.data.length>0) {
            res.data = res.data.sort((a, b) => parseInt(a.dictValue) - parseInt(b.dictValue))
            this.option1 = res.data.map(dict => {
              this.title1 = dict.dicName
              return {
                "text": dict.dictLabel,
                "value": dict.dictLabel
              }
            })
          }
        })
      },
      showUpdate(item) {
        this.currentItem = item;
        this.showPickerPopup = true

        // Toast(action.text);
      },
      handleConfirm(value) {
        this.showPickerPopup = false
        console.log("value", value)
        var data = {"id": this.currentItem.id, "status": value.value}
        updateStatus(data).then(res => {
          if (res.code == 200) {
            Notify({type: 'success', message: '反馈成功'});
            this.getList()
            return
          }
          Notify({type: 'danger', message: res.msg});
        })
      },
      handleAddUpdateConfirm(value) {
        this.showAddUpdate = false
        this.status = value.value
        // var data = { "id": this.currentItem.id,"status": value.value }
        // updateStatus(data).then(res => {
        //     if(res.code == 200) {
        //         Notify({ type: 'success', message: '反馈成功' });
        //         this.getList()
        //         return
        //     }
        //     Notify({ type: 'danger', message: res.msg });
        // })
      },
      getVersionInfo() {
        request.get(`/business/version/${this.versionId}`)
          .then(response => {
            // 处理响应数据
            console.log("junjia", response)
            this.price = response.data.price

          })
          .catch(error => {
            // 处理请求错误
            console.error('请求错误:', error);
          });
      },

      getAttribute2() {
        getDicts("attribute2").then(res => {
          if(res.data&&res.data.length>0){
             res.data = res.data.sort((a,b)=>parseInt(a.dictValue)-parseInt(b.dictValue))
            console.log(res.data)
            this.option2 = res.data.map(dict => {
              this.title2 = dict.dicName
              return {
                "text": dict.dictLabel,
                "value": dict.dictLabel
              }
            })
          }
        })
      },
      getAttribute3() {
        getDicts("attribute3").then(res => {
          if(res.data&&res.data.length>0) {
            res.data = res.data.sort((a, b) => parseInt(a.dictValue) - parseInt(b.dictValue))
            console.log(res.data)
            this.option3 = res.data.map(dict => {
              this.title3 = dict.dicName
              return {
                "text": dict.dictLabel,
                "value": dict.dictLabel
              }
            })
          }
        })
      },
      getAttribute4() {
        getDicts("attribute4").then(res => {
          if(res.data&&res.data.length>0) {
            res.data = res.data.sort((a, b) => parseInt(a.dictValue) - parseInt(b.dictValue))
            console.log(res.data)
            this.option4 = res.data.map(dict => {
              this.title4 = dict.dicName
              return {
                "text": dict.dictLabel,
                "value": dict.dictLabel
              }
            })
          }
        })
      },
      getOption1() {
        this.value1 = this.checkedValues1.join(',');
      },
      getOption2() {
        this.value2 = this.checkedValues2.join(',');
      },
      getOption3() {
        this.value3 = this.checkedValues3.join(',');
      },
      getOption4() {
        this.value4 = this.checkedValues4.join(',');
      },
      setStyle:function(selected , val){
        return {fontWeight:selected.indexOf(val) > -1 ? '900' : '',color:selected.indexOf(val) > -1 ? '#1989fa' : ''};
      }
    },

    watch: {
      option1(value) {
        if (value && this.isNew && this.isNew === '1') {
          this.$nextTick(() => {
            this.dropdownItem1 = this.$refs.download1;
          });
        }
      },
      option2(value) {
        if (value && this.isNew && this.isNew === '1') {
          this.$nextTick(() => {
            this.dropdownItem2 = this.$refs.download2;

          });
        }
      },
      option3(value) {
        if (value && this.isNew && this.isNew === '1') {
          this.$nextTick(() => {
            this.dropdownItem3 = this.$refs.download3;

          });
        }
      },
      option4(value) {
        if (value && this.isNew && this.isNew === '1') {
          this.$nextTick(() => {
            this.dropdownItem4 = this.$refs.download4;

          });
        }
      },

    },
    mounted() {
      this.getStatus()
      this.getDamage();
      this.getAttribute2();
      this.getAttribute3();
      this.getAttribute4();
      const paramValue = this.$route.query;
      this.params = paramValue;


      if(paramValue&&paramValue.menuName){
        this.menuName = paramValue.menuName;
      }
      if(paramValue&&paramValue.queryName){
        this.queryName = paramValue.queryName;
      }
      if (paramValue && paramValue.searchName) {
        this.searchName = paramValue.searchName
      } else {
        this.searchName = ''
      }

      console.log(paramValue)
      if (paramValue && paramValue.currentBrand) {
        this.currentBrand = paramValue.currentBrand
      }
      if (paramValue && paramValue.currentSeries) {
        this.currentSeries = paramValue.currentSeries
      }
      if (paramValue && paramValue.currentModel) {
        this.currentModel = paramValue.currentModel
      }
      if (paramValue && paramValue.currentVersion) {
        this.currentVersion = paramValue.currentVersion
      }
      if (paramValue && paramValue.selectedItem) {
        this.selectedItem = paramValue.selectedItem
      }



      if (paramValue && paramValue.currentCity) {
        this.currentCity = paramValue.currentCity
      }

      let city = ''
      if (paramValue && paramValue.cityName) {
        city = paramValue.cityName
        this.cityName = paramValue.cityName
      } else {
        this.cityName = '全国'
        city = '全国'
      }
      let series = ''
      if (paramValue && paramValue.seriesName) {
        series = '-' + paramValue.seriesName;
      }
      let model = ''
      if (paramValue && paramValue.modelName) {
        model = '-' + paramValue.modelName;
      }
      let version = ''
      if (paramValue && paramValue.versionName) {
        version = '-' + paramValue.versionName
      }

      if (this.searchName) {
        this.title = this.searchName
      } else {
        this.title = city + series + model + version
      }

      this.isNew = paramValue.currentIsNew
      this.currentIsNew = paramValue.currentIsNew
      this.seriesId = this.$route.query.seriesValue,
      this.modelId = this.$route.query.modelValue,
      this.versionId = this.$route.query.versionValue,
      this.brandId = paramValue.currentBrand;
      this.getList()
      this.getVersionInfo()

    }

  }
</script>

<style lang="scss" scoped>

  .detail_contain {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    padding: 5px;
    font-size: 12px;
    p {
      margin: 0;
      padding-bottom: 2px;
      color: #6e767d;
    }
  }

  .detail_contain .detail-item {
    width: 46.5%;
    margin-bottom: 5px;
    padding: 5px 5px 0 5px;
    background-color: #D5DFEB;
    border-radius: 3px;
    box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.1);
    /* 添加阴影效果 */

  }
  .shop-name{
    display: flex;
    .name-title{
      display: block;
      width: 34px;
    }
    .shop-tit{

    }
  }
  .detail_contain .name {
    color: #E8A659;
    display: block;
    width: 130px;
    height: 14px;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }

  .detail_contain .code {
    margin-left: 5px;
    color: #60AB4C
  }

  .detail_contain .price {
    margin-left: 5px;
    font-size: 11px;
    color: red
  }

  .detail_contain .status {
    margin-left: 5px;
    font-size: 11px;
    font-weight: bold;
    color: #464c59
  }

  .detail_contain .time {
    margin-left: 5px;
    color: #333333;
  }

  .detail_contain .btn {
    border-top: 1px solid #8bb0f0;
    font-size: 11px;
    padding-top:4px;
    .btn-p {
      display: flex;
      align-items: center;
      justify-content: space-between;

    }
    .btn-p:first-child {
      margin-bottom: 4px;
    }
  }

  .typeName {
    text-align: center;
    background-color: #ededed !important;
  }

  /* 固定在底部的 "+" 图标样式 */
  .add-icon {
    position: fixed;
    bottom: 30%;
    right: 6px;
    /* 或者根据你的设计设置合适的右边距 */
    width: 50px;
    height: 50px;
    border-radius: 50%;
    /* 设置为圆形 */
    cursor: pointer;
    /* 鼠标悬停时显示指针 */
  }

  /* 点击后显示的固定在底部的 div 样式 */
  .fixed-div {
    position: fixed;
    bottom: 23%;
    right: 13px;
    width: 50px;
    height: 50px;
    border-radius: 50%;
    /* 设置为圆形 */
    text-align: center;
    background-color: #f39d1c;
    /* 这里设置背景颜色为黑色，你可以根据需求修改 */
    /* 其他样式，例如字体颜色、居中样式等 */
  }

  .zhangjia {
    line-height: 50px;
    text-align: center;
    color: #fff;
    font-weight: bold;
  }

  /* 水印样式 */
  .watermark {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }

  .van-popover__action {
    background-color: #a7c6e9;
    color: #fff;
    font-weight: bold;
  }

  .van-popover__action-text van-hairline--bottom {
    font-weight: bold;
  }

  .watermark-text {
    font-size: 24px;
    color: rgba(0, 255, 0, 0.5);
    /* 半透明绿色 */
    /* 其他样式，例如字体样式、水印位置等 */
  }

  /* 商品状态为有货时添加水印 */
  .detail-item[data-status="无货"] {
  }

  .dialog-title {
    font-weight: bold;
    text-align: center;
  }

  .dialog-desc {
    font-size: 14px;
    color: #999;
    text-align: center;
  }
  ::v-deep .van-dropdown-menu__bar{
    box-shadow: unset !important;
    height:100%!important;
    display: flex;
    align-items: center;
    justify-content: space-around;
  }

  ::v-deep .van-ellipsis{
    font-size: 14px !important;
  }

  .checkbox-container{
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    .van-checkbox{
      width: 40%; /* 两列时，每列宽度约为50% */
      margin-bottom: 10px; /* 间隔 */
      /*.van-checkbox__label {*/
      /*padding-right: 30px; !* 根据需要调整左侧距离 *!*/
      /*}*/
      .van-checkbox__icon .van-icon{
        margin-left: 100px!important;
        border: none!important;
        /* 如果需要，可以添加其他样式，如背景色等 */
        background-color: transparent!important;
      }
      .checkbox-container .van-checkbox__icon--checked .van-icon  {
        color:#6f4949!important;
        background-color: transparent!important;
      }
    }
  }
  .check-button{
    display: flex;
    justify-content: space-between;
  }

  ::v-deep .van-checkbox__icon{
    position: absolute;
    right: 0px;
  }

  .buttonReSetting{
    font-width: 900 !important; background: transparent;border-radius: 20px;color:#000;margin-left:5px;margin-bottom: 10px;width: 30%;
    border:0px !important;
  }

  .confirmBtn{
    font-width: 900 !important;color: #fff;border-radius: 20px;margin-left:15px;margin-bottom: 10px;width: 60%;
    margin-right: 4px;
  }
  ::v-deep .van-dropdown-menu__item{
    /*background: #eee;*/
    height: 30px;
    border-radius: 6px;
    flex:none!important;
    width: 23%;
    /*&:hover{*/
    /*  background: #4C8CF9;*/
    /*}*/
  }
  .dropdown-item-selected{
    background: #4C8CF9!important;
  }
  ::v-deep .van-dialog{
    border-radius: 6px!important;
  }
  ::v-deep .van-dialog__confirm{
    color: #5492ff!important; ;
  }
</style>
