import Vue from 'vue'
import VueRouter from 'vue-router'
import HomeView from '../views/HomeView.vue'
import search from '../views/search/search.vue'
import searchDetail from '@/views/search/searchDetail.vue'
import dynamic from '@/views/dynamic/dynamic.vue'
import plate from '@/views/order/plate'
import userCenter from '@/views/userCenter/userCenter.vue'
import userInfo from '@/views/userInfo/userInfo.vue'
import extractMoney from '@/views/extractMoney/extractMoney.vue'
import extractMoneyForm from '@/views/extractMoney/extractMoneyForm.vue'
import extractMoneyList from '@/views/extractMoney/extractMoneyList.vue'
import bankSetting from '@/views/extractMoney/bankSetting.vue'
import myPublish from '@/views/myPublish/myPublish.vue'
import publish from '@/views/publishSeries/publishSeries.vue'
import recharge from '@/views/recharge/recharge.vue'
import authentication from '@/views/authentication/authentication.vue'
import updatePwd from '@/views/updatePwd/updatePwd.vue'
import productDetail from '@/views/productDetail/productDetail.vue'
import feedback from '@/views/feedback/feedback.vue'
import invite from '@/views/invite/invite.vue'
import Login from '@/views/Login2.vue'
import Register from '@/views/register.vue'
import homePage from '@/views/home/index'
import product from '@/views/storeProduct/index.vue'
import sameProductDetail from '@/views/productDetail/sameProductDetail.vue'
import qrcode from '@/views/userCenter/qrcode.vue'
import quickSearch from '@/views/search/quickSearch.vue'
import editAuth from '@/views/authentication/edit.vue'
import rechargeRecord from '@/views/recharge/record'
Vue.use(VueRouter)
const routes = [
  {
    path: '/',
    name: 'home',
    component: HomeView,
    children:[
      {
        path: '/home',
        name: 'homePage',
        component: homePage,
        meta: { requiresAuth: false }
      },
      {
        path: '/search',
        name: 'search',
        component: search,
        meta: { requiresAuth: true }
        // children:[
        //
        // ]
      },
      {
        path: '/publish',
        name: 'publish',
        component: publish,
        meta: { requiresAuth: true }
      },
      {
        path: '/dynamic',
        name: 'dynamic',
        component: dynamic,
        meta: { requiresAuth: true }
      },
      {
        path: '/userCenter',
        name: 'userCenter',
        component: userCenter,
        meta: { requiresAuth: false }
      },


    ]
  },
  {
    path: '/extractMoney',
    name: 'extractMoney',
    component: extractMoney,
    meta: { requiresAuth: true }
  },{
    path: '/extractMoneyForm',
    name: 'extractMoneyForm',
    component: extractMoneyForm,
    meta: { requiresAuth: true }
  },{
    path: '/extractMoneyList',
    name: 'extractMoneyList',
    component: extractMoneyList,
    meta: { requiresAuth: true }
  },{
    path: '/bankSetting',
    name: 'bankSetting',
    component: bankSetting,
    meta: { requiresAuth: true }
  },

  {
    path: '/productDetail/:id',
    name: 'productDetail',
    component: productDetail,
    meta: { requiresAuth: false }
  },
  {
    path: '/sameProductDetail',
    name: 'sameProductDetail',
    component: sameProductDetail,
    meta: { requiresAuth: true }
  },
  {
    path: '/recharge',
    name: 'recharge',
    component: recharge,
    meta: { requiresAuth: true }
  },
  {
    path: '/updatePwd',
    name: 'updatePwd',
    component: updatePwd,
    meta: { requiresAuth: true }
  },
  {
    path: '/feedback',
    name: 'feedback',
    component: feedback,
    meta: { requiresAuth: true }
  },
  {
    path: '/invite',
    name: 'invite',
    component: invite,
    meta: { requiresAuth: true }
  },
  {
    path: '/authentication',
    name: 'authentication',
    component: authentication,
    meta: { requiresAuth: true }
  },
  {
    path: '/editAuth',
    name: 'editAuth',
    component: editAuth,
    meta: { requiresAuth: true }
  },
  {
    path: '/rechargeRecord',
    name: 'rechargeRecord',
    component: rechargeRecord,
    meta: { requiresAuth: true }
  },
  {
    path: '/userInfo',
    name: 'userInfo',
    component: userInfo,
    meta: { requiresAuth: true }
  },
  // {
  //   path: '/publish',
  //   name: 'publish',
  //   component: publish,
  //   meta: { requiresAuth: true }
  // },
  {
    path: '/myPublish',
    name: 'myPublish',
    component: myPublish,
    meta: { requiresAuth: true }
  },
  {
    path: '/qrcode',
    name: 'qrcode',
    component: qrcode,
    meta: { requiresAuth: true }
  },
  {
    path: '/Login',
    name: 'Login',
    component: Login,
    meta: { requiresAuth: false }
  },
  {
    path: '/searchDetail',
    name: 'searchDetail',
    component: searchDetail,
    meta: { requiresAuth: false }
  },
  {
    path: '/quickSearch',
    name: 'quickSearch',
    component: quickSearch,
    meta: { requiresAuth: false }
  },
  {
    path: '/register',
    name: 'Register',
    component: Register,
    meta: { requiresAuth: false }
  },
  {
    path: '/plate/:id',
    name: 'plate',
    component: plate,
    meta: { requiresAuth: false }
  },
  {
    path: '/product',
    name: 'product',
    component: product,
    meta: { requiresAuth: false }
  },

]

const router = new VueRouter({
  mode: 'hash',
  base: process.env.BASE_URL,
  routes
})

const hasToken = () => {
  const token = localStorage.getItem('token');
  console.log("token",!!token)
  return !!token; // 如果有token则返回true，否则返回false
};


router.beforeEach((to, from, next) => {
  const info = localStorage.getItem("businessInfo") ? JSON.parse(localStorage.getItem("businessInfo")) : null;
  // debugger;
  if (to.matched.some(record => record.meta.requiresAuth)) {
    // 如果路由需要认证
    if (!hasToken()) {
      // 如果没有token
      // next('/Login'); //使用此方法会报错
      router.push("/login"); // 导航到登录页
    } else {
      // if ((info == null) || (info.auditingStatus != 0 && to.name == 'recharge' ) && (to.name !== 'authentication' && to.name !== 'updatePwd')) {
      if (info==null && to.name != 'authentication' && to.name != 'updatePwd' && to.name != 'userInfo') {
        //充值后才能使用功能
        Vue.prototype.$EventBus.$emit('globalAlert', '请先进行资料认证！');
        // next();
        // router.push("/recharge"); // 跳转到充值页面
      } else if(info!=null && info.auditingStatus == 0 && to.name != 'authentication' && to.name != 'updatePwd' && to.name != 'userInfo'){

          Vue.prototype.$EventBus.$emit('globalAlert', '请等待资料认证通过后使用该功能！');
      }
      else if (info!=null &&info.isRecharge == 0&& info.auditingStatus ==1
          && ((to.name !== 'authentication' &&to.name !== 'recharge' && to.name !== 'updatePwd' && to.name != 'userInfo'))) {
        //充值后才能使用功能
        Vue.prototype.$EventBus.$emit('globalAlert', '未充值,请充值后使用该功能！');
        // next();
        // router.push("/recharge"); // 跳转到充值页面
      }else{
        next(); // 继续导航
      }
    }
  } else {
    console.log("路透4")
    next(); // 不需要认证的路由，直接继续导航
  }
});


export default router
