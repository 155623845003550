<template>
  <div class="sd_contains">
    <van-nav-bar title="我发布的" left-arrow @click-left="goBack"></van-nav-bar>
    <van-swipe-cell v-for="(item,index) in goods" :key="index" v-if="goods.length>0">
      <!--<div class="good_contains">-->
        <!--<div class="name">{{item.name}}</div>-->
        <!--<div class="info"  @click="toDetail(item)">-->
          <!--<div class="left">-->
            <!--<div class="brandName">-->
              <!--<span>{{item.brandName}}</span>-->
              <!--<span>|</span>-->
              <!--<span>{{item.seriesName}}</span>-->
              <!--<span>|</span>-->
              <!--<span>{{item.isNewStr}}</span>-->
            <!--</div>-->
            <!--<div class="status">-->
              <!--状态:{{item.status}}-->
            <!--</div>-->
          <!--</div>-->
          <!--<div class="goodPrice">-->
            <!--<div>-->
            <!--<span style="font-size:11px;color:#e4393c">￥{{item.price}}</span>-->
            <!--</div>-->
          <!--</div>-->
        <!--</div>-->
        <!--<div class="info-bottom" style="margin-top: 3px">-->
          <!--<div v-if="item.updateTime" class="createTime">{{item.updateTime.substring(0,10)}}</div>-->
          <!--<div style="display: flex">-->
            <!--<div  class="btn-top" @click.stop="detail(item.id)">编辑</div>-->
            <!--<div  class="btn-top green-color" @click.stop="showPop(item)">积分置顶</div>-->
            <!--<div  class="btn-top red-color" @click.stop="del(item.id)">删除</div>-->
          <!--</div>-->

        <!--</div>-->
      <!--</div>-->
      <div class="good-container" >
        <!--        <div class="avatar">-->
        <!--          &lt;!&ndash; <img :src="item.avatar || require('../../assets/default-avatar.png')" alt="avatar"> &ndash;&gt;-->
        <!--          <img :src="require('../../assets/default-avatar.png')" alt="avatar">-->
        <!--        </div>-->
        <div class="content">
          <div class="content-p1" @click="toDetail(item)">
            <div class="p1">
              <!--<div class="sales">出售</div>-->
              <div class="details">
                <span v-if="item.name">{{ item.name }}|</span>
                <span v-if="item.brandName">{{ item.brandName }}</span>
                <span v-if="item.seriesName">|{{ item.seriesName }}</span>
                <span v-if="item.isNewStr">|{{item.isNewStr}}</span>
                <span v-if="item.status">|{{ item.status }}</span>
                <!--<span>{{ item.versionName }}</span>-->
              </div>
            </div>
            <div class="price" >
              <span class="price-value">￥{{ item.price }}</span>
            </div>
          </div>
          <div class="content-p1" style="margin-top: 8px">
            <div class="header" @click="toDetail(item)">
              <span class="shop-name">{{ item.businessName }}</span>
              <span v-if="item.cityName" style="margin-left: 4px;"><span style="margin-left: 4px;">|</span> {{ item.cityName }}</span>
              <span v-if="item.updateTime" style="margin-left: 4px;"><span>|</span>{{ item.updateTime.substring(0,10)}}</span>
            </div>

            <div class="status">
              <span  class="btn-top" style="color: #5492FF;margin-right: 5px" @click.stop="detail(item.id)">编辑</span>
              <span  class="btn-top green-color" style="margin-right: 5px" @click.stop="showPop(item)">置顶</span>
              <span  class="btn-top red-color" @click.stop="del(item.id)">删除</span>
            </div>
            <!--<div class="status">-->
              <!--状态: <span>{{ item.status }}</span>-->
            <!--</div>-->
          </div>
        </div>
      </div>
    </van-swipe-cell>
    <div style="text-align: center" v-if="goods.length<=0">
      暂未发布商品
    </div>
    <van-dialog v-model="showZd" title="请输入置顶积分" @confirm="Zd" show-cancel-button message="">
      <van-field v-model="topIntegral" style="border: 1px" type="number" name="topIntegral" label="请输入积分" placeholder="积分" required
               />
    </van-dialog>
  </div>
</template>

<script>
  import { getBusinessSeries, delSeries } from "@/api/series";
  import { zd } from "@/api/business";
  import {Dialog, Notify, Toast} from "vant";
  import { checkView } from '@/utils/checkUtil'
  export default {
    data() {
      return {
        goods: [],
        currentItem:'',
        showZd:false,
        topIntegral: 0,
      };
    },
    mounted() {
      let result = checkView("/publish","请补充营业执照后使用该功能");
      if (!result.flag) {
        //验证当前账号是否已上传营业执照
        this.$toast.fail(result.msg );
        this.$router.back();
      }
      this.getList();
    },
    methods: {
      toDetail(item) {
        console.log(this.currentCity)
        this.$router.push({
          name: 'sameProductDetail',
          query: {
            name: item.name,
            brandName:item.brandName,
            seriesName:item.seriesName,
            modelName:item.modelName,
            versionName:item.versionName,
            seriesId: item.seriesId,
            modelId: item.modelId,
            versionId: item.versionId,
            brandId: item.brandId,
            status:item.status
          }
        })
      },
      Zd() {
        if (!this.topIntegral||(this.topIntegral&&this.topIntegral <= 0)) {
          // Notify({ type: 'danger', message: '请输入用户名' });
          this.$toast.fail("请输入大于0的积分");
        }else{
          zd({
            id: this.currentItem.id,
            topIntegral: this.topIntegral
          }).then(res => {
            if(res.code==200){
              this.showZd = false
              Toast.success("置顶成功")
            }else{
              Toast.fail(res.msg)
            }
            this.getList();
          })
        }
      },
      showPop(item) {
        this.currentItem = item;
        this.showZd = true
      },
      getList() {
        getBusinessSeries().then(res => {
          this.goods = this.arrangeData(res.data);
          console.log(this.goods)
        });
      },
      arrangeData(dataList) {
        if(dataList&&dataList.length>0){
          dataList.forEach(data => {
            // data.name = data.modelName + " " + data.versionName
          })
        }
        return dataList;
      },
      goBack() {
        this.$router.push("/userCenter");
      },
      detail(id) {
        //todo 跳转到修改页面
        this.$router.push(`/productDetail/${id}`);
      },
      del(id) {
        Dialog.confirm({
          title: "确认删除",
          message: "确定要删除该商品吗？"
        })
                .then(() => {
                  delSeries(id).then(res => {
                    if(res.code == 200) {
                      Toast.success('删除成功')
                     // Notify({ type: 'success', message: '删除成功' });
                      this.getList();
                      return
                    }
                    Toast.fail(res.msg)
                   // Notify({ type: 'danger', message: res.msg });
                  });
                })
                .catch(() => {
                  // 用户点击了取消按钮
                });
      }
    }
  };
</script>

<style lang="scss" scoped>
  .van-nav-bar {
    background-color: #5492ff !important;
  }
  .van-nav-bar .van-icon {
    color: #fff !important;
  }

  .good_contains {
    width: 93%;
    background-color: hsl(206, 28%, 95%);
    border-radius: 10px;
    margin: 4px auto;
    padding: 4px;
  }

  .name {
    color: #2e3104;
    font-weight: bold;
    line-height: 13px;
    font-size:12px;
    margin-bottom: 2px;
  }
  .brandName {
    font-size: 11px;
    color: #333;
    margin-bottom: 2px;
  }
  .shopName {
    font-size: 11px;
    color: #999;
  }
  .status {
    font-size: 11px;
    color: #999;
    margin-bottom: 2px;
  }
  .goodPrice {
    font-size: 11px;
    font-weight: simsun;
    color: #6e767d;
  }
  .van-nav-bar {
    background-color: #5492ff;
  }
  .van-nav-bar__title {
    color: #fff;
  }
  .createTime {
    font-size: 11px;
    color: #999;
  }
  .shopName1 {
    color: #f1a52b;
    font-size: 11px;
    font-weight: 800;
  }
  .doorplate {
    margin-left: 22px;
    font-size: 11px;
    font-weight: simsun;
    color: #6e767d;
  }
  .doorplate1 {
    color: #5c8fce;
    font-size: 11px;
    font-weight: 900;
  }
  .info{
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  .info-bottom{
    display: flex;
    align-items: center;
    justify-content: space-between;
    .btn-top{
      background: transparent;
      /*border: 1px solid #5492FF;*/
      font-size: 11px;
      /*border-radius: 80px;*/
      /*padding: 3px 10px;*/
      color: #5492FF;
      margin-left: 6px;
    }
  }
  .green-color{
    color: #42B883!important;
  }
  .red-color{
    color: red!important;
  }



  .good-container {
    display: flex;
    align-items: center;
    background-color: #ffffff; /* 背景色改为纯白提升清晰度 */
    border-bottom: 1px solid #ddd; /* 边框颜色更细腻 */
    padding: 6px;
  }
  .content-p1{
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  .content {
    flex-grow: 1;
    .p1{
      display: flex;
      align-items: center;
      color: #333333;
      .sales{
        padding: 2px 4px;
        border-radius: 2px;
        color: white;
        font-size: 10px;
        background: #C12E2B;
        margin-right: 4px;
      }
    }
  }
  .details {
    color: #1a1919;
    width: 230px;
    height: 18px;
    overflow: hidden;
    font-size: 13px;
    font-family: 'Helvetica', sans-serif; /* 更换字体样式 */
  }
  .price {
    color: #333; /* 调整颜色以突出价格 */
    font-size: 13px; /* 加大价格字体大小 */
  }

  .price-value {
    font-size:13px; /* 价格数字更大 */
    color: #333; /* 价格颜色更鲜艳 */
  }
  .header .shop-name {
    color: #999;
    font-size: 12px;
  }

  .header span {
    color: #999; /* 颜色更柔和 */
    font-size: 12px; /* 统一字体大小 */
    font-family: 'Arial', sans-serif; /* 使用无衬线字体提升现代感 */
  }
  ::v-deep .van-dialog{
    border-radius: 6px!important;
  }
  ::v-deep .van-dialog__confirm{
    color: #5492ff!important;
  }
</style>
