<template>
  <div class="contain">
    <van-swipe :autoplay="3000" lazy-render>
      <van-swipe-item class="banner-img" v-for="(item,index) in bannerList" :key="index" @click="goUrl(item)">
        <img :src="baseUrl+item.img" />
      </van-swipe-item>
    </van-swipe>
    <p class="title"><img src="../../assets/phone.png"/>品牌手机</p>
    <div class="brand">
      <div class="brand-con" v-for="(item,index) in dataList" :key="index" @click="goDetail(item)">
        <img :src="baseUrl+item.img">
        <p>{{item.name}}</p>
      </div>
    </div>
  </div>

</template>

<script>
import { list } from "@/api/banner"
import { all } from "@/api/brand"
import request from '@/utils/request'
export default {
  data() {
    return {
      baseUrl:request.baseURL,
      bannerList:[],
      dataList:[]

    };
  },
  mounted(){
    console.log(this.baseUrl)
    this.getBanner();
    this.getBrand();
  },
  methods:{
    goDetail(item){
      this.$router.push({
        name: 'search',
        query: {
          id: item.id,
          name: item.name,
          form:'home'
        }
      })
    },
    goUrl(item){
      this.$router.push(item.url)
    },
   getBanner(){
     list().then(res => {
       console.log(res)
       this.bannerList=res.data;
     })
   },
    getBrand(){
      all().then(res => {
        console.log(res)
        this.dataList=res.data;
      })
    }
  }
}
</script>

<style lang="scss" scoped>
.banner-img{
  width: 100%;
  height: 220px;
  img{
    width: 100%;
    height: 100%;
  }
}
.title{
  font-size: 15px;
  font-weight: bold;
  padding-left: 20px;
  margin-bottom: 20px;
  display: flex;
  align-items: center;
  img{
    width: 20px;
    height: 20px;
    margin-right: 6px;
  }
}
.brand{
  display: flex;
  flex-wrap: wrap;
  .brand-con{
    width: 25%;
    text-align: center;
    margin-bottom: 10px;
    img{
      width:45px;
      height: 45px;
      border-radius: 50%;

    }
    p{
      font-size: 13px;
      color: #333;
      margin: 5px 0;
    }
  }
}
</style>
