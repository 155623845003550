<template>
    <div class="wrapper">
        <van-icon name="revoke" style="font-size: 1rem;color:#fff !important;" @click="back()" />
        <div class="info">
            <div class="name">
                <div>您好！</div>
                <div>欢迎来到通讯源！</div>
            </div>
            <el-form :model="user" :rules="rules" ref="userForm">

                <el-form-item  >
                    <el-input size="medium" style="margin: 10px 0" prefix-icon="el-icon-user"
                        v-model="user.username"></el-input>
                </el-form-item>
                <el-form-item  >
                    <el-input size="medium" style="margin: 10px 0" prefix-icon="el-icon-lock" show-password
                        v-model="user.password"></el-input>
                </el-form-item>
                <el-form-item id="loginButton" style="padding: 10px 0;display: flex;justify-content: space-between;width: 100% !important;">
                        <el-button class="login-btn" style=""
                                   type="primary" size="large" autocomplete="off" @click="login">登录</el-button>

<!--                    <el-button type="warning" size="large" autocomplete="off" @click="$router.push('/register')">注册-->
<!--                    </el-button>-->
                </el-form-item>
                <p class="register-p" @click="$router.push('/register')">没有账号？去注册</p>
            </el-form>
        </div>
    </div>
</template>

<script>
import request from '@/utils/request';

import { Toast } from 'vant';
export default {
    name: "Login",
    data() {
        return {
            user: {
                tag:"app"
            },
            rules: {
                username: [
                    { required: true, message: '请输入用户名', trigger: 'blur' },
                ],
                password: [
                    { required: true, message: '请输入密码', trigger: 'blur' },
                ],
            }
        }
    },
    methods: {
        back:function(){
            // this.$router.go(-1);
            this.$router.back();
        },
        login() {
            if (!this.user.username) {
                // 危险通知
                this.$toast.fail("请输入账号");
                return;
            }
            if (!this.user.password) {
                // 危险通知
                this.$toast.fail("请输入密码");
                return;
            }

            // 发送登录请求
            // const userData = {
            //     username: this.user.username,
            //     password: this.user.password
            // };

            //Toast.success('登录成功');
            // this.$router.push('/userCenter');
            // 使用封装好的 Axios 发送 POST 请求

            request.post('/login', this.user)
                .then(response => {
                    // 处理响应数据
                    console.log("response", response)
                    if(response && response.code == '200'){
                        console.log("登录成功")
                        localStorage.setItem("yltUser",JSON.stringify(response.yltUser))
                        localStorage.setItem("businessInfo",JSON.stringify(response.businessInfo))
                        localStorage.setItem("token",response.token)
                        this.$toast.success("登录成功")
                        this.$router.push('/userCenter')
                    }else{
                        this.$toast.fail("账号密码错误")
                    }
                })
                .catch(error => {
                    // 处理请求错误
                    console.error('请求错误:', error);
                });
        }
    }
}
</script>

<style lang="scss" scoped>
.wrapper {
    height: 100vh;
    background: linear-gradient(0deg, #4C8CF9, #8ED0FB);
    padding:0 30px;
    /*position: fixed; !* 固定定位 *!*/
    overflow: hidden;
    p{
        margin: 0;
    }
    .info{
        background:url("../assets/login_app.png");
        background-size: 100% 100%;
        height: 100vh;

        .name{
            color: white;
            padding-top: 150px;
            div:first-child{
                font-size:24px;
                margin-bottom: 5px;
            }
            div:last-child{
                margin-bottom:40px;
            }
        }
    }
    ::v-deep .el-input--medium .el-input__inner{
        height:42px!important;
        line-height: 42px!important;
    }
    ::v-deep .el-form-item{
        margin-bottom: 0!important;
    }
    .register-p{
        color: white;
        text-align: center;
        font-size: 14px;
        margin-top: 16px;
    }
    .login-btn{
        width: 260px;
        border-radius: 80px;
        background: white;
        color: #4C8CF9;
        letter-spacing: 2px;
        font-size: 18px;
        width:100% !important;
    }
   ::v-deep .el-form-item__content{
        width: 100% !important;
    }
        ::v-deep .van-icon-revoke{
            font-size: 1rem;
            font-weight: 900;
            color:#fff;
            position: relative;
            top: 1.5rem;
        }
}
</style>
