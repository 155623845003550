<template>
  <div class="plate_contain">
    <van-nav-bar title="提交订单"   @click-left="back" left-arrow>

    </van-nav-bar>
    <van-cell-group>
    <van-cell title="商品名称" :value="info.title" />
    <van-cell title="商家" :value="info.businessName" :label="info.doorNumber" />
    <van-cell title="单价" :value="info.price" />

    <van-cell-group>
        <van-field required v-model="info.num" type="number" label="数量" placeholder="请输入数量" />
        <van-field required v-model="info.phone" label="电话号" placeholder="请输入电话号" />
    </van-cell-group>

    </van-cell-group>
    <van-submit-bar :price="info.price*info.num*100" tip="实际订单金额以批发商审核为准" button-text="提交订单" @submit="onSubmit" />

  </div>
</template>

<script>
import request from '@/utils/request';
import { Toast } from 'vant';
const { v4: uuidv4 } = require('uuid');

export default {
    data(){
        return {
            id:'',
            info:{
                title:'',
                businessName:'',//所属商家名称
                doorNumber:'',//门牌号
                price:'',
                num:'',
                phone:''
            },
            currentBrand:'',
            currentCity:'',
            currentIsNew:'',
            currentModel:'',
            currentSeries:'',
            currentVersion:''

        }
    },
    methods:{
        onSubmit(){
            if(this.info.num == '' || this.info.phone == '' ){
                Toast.fail('您有必填项没有填写');
                return
            }
            request.post('/business/order',{
                businessSeriesId:this.currentSeries.value,
                price:this.info.price,
                num:this.info.num,
                phonenumber:this.info.phone,
                orderCode:uuidv4(),
                orderStatus:'1',
            })
                .then(response => {
                // 处理响应数据
                    Toast.success('下单成功');

                   // this.itemList = response.rows;

                })
                .catch(error => {
                // 处理请求错误
                console.error('请求错误:', error);
                });
                this.back();

        },
        back(){
          // this.$router.back();
            this.$router.push({
                name:'searchDetail',
                query: {
                currentBrand: this.$route.query.currentBrand ,
                currentCity:this.$route.query.currentCity,
                currentIsNew:this.$route.query.currentIsNew,
                currentModel:this.$route.query.currentModel,
                currentSeries:this.$route.query.currentSeries,
                currentVersion:this.$route.query.currentVersion
                }
            })
        },
        async getInfo(id){
            //获取品牌选项
            await request.get(`/business/series/${id}`)
                .then(response => {
                // 处理响应数据
                    console.log("res",response)
                   // this.itemList = response.rows;
                   this.info.title = response.data.brandName+" "+response.data.seriesName+" "+response.data.modelName+" "+response.data.versionName
                   this.info.businessName = response.data.businessName
                   this.info.doorNumber = response.data.doorNumber;
                   this.info.price = response.data.price
                   this.currentBrand = response.data.brandId
                   this.currentCity = response.data.cityId
                   this.currentIsNew = response.data.isNew;

                   this.currentModel =  {text:response.data.modelName,value:response.data.modelId}
                   this.currentSeries = {text:response.data.seriesName,value:response.data.seriesId}
                   this.currentVersion = {text:response.data.versionName,value:response.data.versionId}
                })
                .catch(error => {
                // 处理请求错误
                console.error('请求错误:', error);
                });

        },
        async getOrder(){
            await this.getInfo(this.id);
        }
    },
    mounted(){
        this.id  = this.$route.query.id
        this.getOrder()
    }
}
</script>

<style scoped>
</style>
