<template>
  <div class="contain">
    <van-nav-bar title="商品详情"  left-arrow @click-left="goBack"></van-nav-bar>
    <van-form ref="form">
    <van-cell-group class="content">
      <!--<div v-if="!edit">-->
        <!--<van-field-->
          <!--v-model="info.brandName"-->
          <!--label="品牌"-->
          <!--readonly-->
        <!--&gt;</van-field>-->
        <!--<van-field-->
          <!--v-model="info.seriesName"-->
          <!--label="产品系列"-->
          <!--readonly-->
        <!--&gt;</van-field>-->
        <!--<van-field-->
          <!--v-model="info.modelName"-->
          <!--label="产品型号"-->
          <!--readonly-->
        <!--&gt;</van-field>-->
        <!--<van-field-->
          <!--v-model="info.versionName"-->
          <!--label="产品版本"-->
          <!--readonly-->
        <!--&gt;</van-field>-->
        <!--<van-field-->
          <!--v-model="info.status"-->
          <!--label="状态"-->
          <!--readonly-->
        <!--&gt;</van-field>-->
        <!--<van-field-->
          <!--v-model="info.price"-->
          <!--label="产品价格"-->
          <!--readonly-->
        <!--&gt;</van-field>-->
        <!--<van-field-->
          <!--v-model="info.num"-->
          <!--label="库存"-->
          <!--readonly-->
        <!--&gt;</van-field>-->
        <!--<van-field-->
          <!--v-model="info.isNewStr"-->
          <!--label="是否为新机"-->
          <!--readonly-->
        <!--&gt;</van-field>-->
        <!--<van-field-->
          <!--v-model="info.damage"-->
          <!--:label="title1"-->
          <!--v-if="info.isNew&&info.isNew==='1'&&info.damage"-->
          <!--readonly-->
        <!--&gt;</van-field>-->
        <!--<van-field-->
          <!--v-model="info.attribute2"-->
          <!--:label="title2"-->
          <!--placeholder=""-->
          <!--v-if="info.isNew&&info.isNew==='1'&&info.attribute2"-->
          <!--readonly-->
        <!--&gt;</van-field>-->
        <!--<van-field-->
          <!--v-model="info.attribute3"-->
          <!--:label="title3"-->
          <!--placeholder=""-->
          <!--v-if="info.isNew&&info.isNew==='1'&&info.attribute3"-->
          <!--readonly-->
        <!--&gt;</van-field>-->
        <!--<van-field-->
          <!--v-model="info.attribute4"-->
          <!--:label="title4"-->
          <!--placeholder=""-->
          <!--v-if="info.isNew&&info.isNew==='1'&&info.attribute4"-->
          <!--readonly-->
        <!--&gt;</van-field>-->
      <!--</div>-->
      <div v-if="edit">
        <!--<custom-picker-->
          <!--:options="brandOption"-->
          <!--:selectTextName="selectBrand"-->
          <!--title="品牌"-->
          <!--placeholder="请选择品牌"-->
          <!--v-model="form.brandId"-->
          <!--:rule="brandRules"-->
          <!--@confirm="handBrandConfirm"-->
          <!--ref="brand"-->
        <!--/>-->
        <!--<custom-picker-->
          <!--:options="seriesOption"-->
          <!--:selectTextName="selectSeries"-->
          <!--title="产品系列"-->
          <!--placeholder="请选择系列"-->
          <!--v-model="form.seriesId"-->
          <!--:rule="seriesRules"-->
          <!--@confirm="handSeriesConfirm"-->
          <!--ref="series"-->
        <!--/>-->
        <!--<custom-picker-->
          <!--:options="modelOption"-->
          <!--:selectTextName="selectModel"-->
          <!--title="产品型号"-->
          <!--placeholder="请选择型号"-->
          <!--v-model="form.modelId"-->
          <!--:rule="modelRules"-->
          <!--@confirm="handModelConfirm"-->
          <!--ref="model"-->
        <!--/>-->
        <!--<custom-picker-->
          <!--:options="versionOption"-->
          <!--:selectTextName="selectVersion"-->
          <!--title="产品版本"-->
          <!--placeholder="请选择版本"-->
          <!--v-model="form.versionId"-->
          <!--:rule="versionRules"-->
          <!--ref="version"-->
        <!--/>-->
        <!--<custom-picker-->
          <!--:selectTextName="selectStatus"-->
          <!--:options="statusOption"-->
          <!--title="状态"-->
          <!--placeholder="请选择状态"-->
          <!--v-model="form.status"-->
          <!--:rule="sttusRules"-->
        <!--/>-->
        <van-field
          v-model="form.brandName"
          label="品牌"
          readonly
        ></van-field>
        <van-field
          v-model="form.seriesName"
          label="产品系列"
          readonly
        ></van-field>
        <van-field
          v-model="form.modelName"
          label="产品型号"
          readonly
        ></van-field>
        <van-field
          v-model="form.versionName"
          label="产品版本"
          readonly
        ></van-field>
        <van-field
          v-model="form.status"
          label="状态"
          readonly
        ></van-field>
        <van-field
          v-model="form.price"
          label="产品价格"
          placeholder="请输入产品价格"
          type="number"
          maxlength="6"
          :rules="priceRules"
          required
        ></van-field>

        <van-field
          v-model="form.num"
          label="库存"
          type="number"
          placeholder="请输入库存"
          maxlength="6"
          :rules="numRules"
          required
        ></van-field>
        <custom-picker
          :options="isNewOption"
          :selectTextName="selectNew"
          title="是否为新机"
          placeholder="请选择"
          v-model="form.isNew"
          :rule="isNewRules"
          @confirm="handIsNewConfirm"
        />
        <van-field
          v-model="form.damage"
          :label="title1"
          placeholder="请选择"
          :rules="damageRules"
          v-if="form.isNew&&form.isNew==='1'"
          @click="showPopup = true"
          readonly
          required
        ></van-field>
        <van-field
          v-model="form.attribute2"
          :label="title2"
          placeholder="请选择"
          v-if="showDamage"
          @click="showPopup2 = true"
          readonly
        ></van-field>
        <van-field
          v-model="form.attribute3"
          :label="title3"
          placeholder="请选择"
          v-if="showDamage"
          @click="showPopup3 = true"
          readonly
        ></van-field>
        <van-field
          v-model="form.attribute4"
          :label="title4"
          placeholder="请选择"
          v-if="showDamage"
          @click="showPopup4 = true"
          readonly
        ></van-field>
      </div>
<!--      <div style="width:90%;margin:auto">-->
<!--        <van-button v-if="!edit" type="info" size="normal" @click="update" round-->
<!--                    style="width: 40%;background: transparent;color: #5492FF; border: 1px solid #5492FF; border-radius: 50px;" >修改</van-button>-->
<!--        <van-button v-if="edit" type="info" size="warning" @click="cancel" round-->
<!--                    style="width: 40%;background: transparent;color: #5492FF; border: 1px solid #5492FF; border-radius: 50px;">取消</van-button>-->
<!--        <van-button v-if="edit" type="info" size="normal" @click="save" round-->
<!--                    style="width: 40%;background: transparent;color: #5492FF; border: 1px solid #5492FF; border-radius: 50px;">保存</van-button>-->
<!--      </div>-->
      <div style="margin:30px auto">
        <div class="btn-con" @click="save">保存</div>

      </div>
    </van-cell-group>

  </van-form>

    <van-popup v-model="showPopup" position="bottom" round style="text-align: center;">
      <van-checkbox-group v-model="checkedValues" style="padding: 2%;">
        <van-checkbox v-for="option in damageOption" :key="option.value" :name="option.value" style="margin: 2%">
          {{ option.text }}
        </van-checkbox>
      </van-checkbox-group>
      <van-button type="info" @click="confirmSelection">确定</van-button>
    </van-popup>
    <van-popup v-model="showPopup2" position="bottom" round style="text-align: center;">
      <van-checkbox-group v-model="checkedValues2" style="padding: 2%;">
        <van-checkbox v-for="option in attribute2" :key="option.value" :name="option.value" style="margin: 2%">
          {{ option.text }}
        </van-checkbox>
      </van-checkbox-group>
      <van-button type="info" @click="confirmSelection2">确定</van-button>
    </van-popup>

    <van-popup v-model="showPopup3" position="bottom" round style="text-align: center;">
      <van-checkbox-group v-model="checkedValues3" style="padding: 2%;">
        <van-checkbox v-for="option in attribute3" :key="option.value" :name="option.value" style="margin: 2%">
          {{ option.text }}
        </van-checkbox>
      </van-checkbox-group>
      <van-button type="info" @click="confirmSelection3">确定</van-button>
    </van-popup>

    <van-popup v-model="showPopup4" position="bottom" round style="text-align: center;">
      <van-checkbox-group v-model="checkedValues4" style="padding: 2%;">
        <van-checkbox v-for="option in attribute4" :key="option.value" :name="option.value" style="margin: 2%">
          {{ option.text }}
        </van-checkbox>
      </van-checkbox-group>
      <van-button type="info" @click="confirmSelection4">确定</van-button>
    </van-popup>
  </div>

</template>

<script>
  import CustomPicker from '@/components/CustomPicker.vue';
  import { addSeries,getSeries } from "@/api/series";
  import { getDicts } from '@/api/dict'
  import { Notify } from "vant";
  import request from '@/utils/request'
export default {
  components: {
    CustomPicker
  },
  data() {
    return {

      selectBrand: '',
      selectModel:'',
      selectSeries:'',
      selectVersion:'',
      selectStatus:'',
      selectNew: '',

      checkedValues: [],
      edit: false,
      showDamage: false,
      showPopup: false,
      showPopup2: false,
      showPopup3: false,
      showPopup4: false,

      checkedValues2: [],
      checkedValues3: [],
      checkedValues4: [],
      isDisabled: false,
      isShowCity:false,
      info:{
      },
      form : {
      },
      brandOption: [],
      seriesOption: [],
      modelOption: [],
      versionOption: [],
      statusOption: [],
      isNewOption: [],
      damageOption:[],
      attribute2: [],
      attribute3: [],
      attribute4: [],
      title1:'',
      title2:'',
      title3:'',
      title4:'',
      brandRules: [
        { required: true }
      ],
      seriesRules: [
        { required: true }
      ],
      modelRules: [
        { required: true }
      ],
      versionRules: [
        { required: true }
      ],
      sttusRules: [
        { required: true }
      ],
      isNewRules: [
        { required: true }
      ],
      statusRules: [
        { required: true }
      ],
      damageRules: [
        { required: true }
      ],
      priceRules: [
        { required: true },
        {
          pattern: /^[0-9]*$/,
          message: '请输入整数'
        }
      ],
      numRules : [
        { required: true },
        {
          pattern: /^[0-9]*$/,
          message: '请输入整数'
        }
      ],
    };
  },
  mounted(){
    this.getStatus()
    this.getDamage();
    this.getBrandOptions()
    this.getIsNew()

    this.getAttribute2();
    this.getAttribute3();
    this.getAttribute4();

    let query = this.$router.currentRoute.params
    console.log(query)
    if(query&&query.id){
      getSeries(query.id).then(res=>{
        this.info = res.data
        console.log(this.form)
        this.update();
      })
    }
    // this.update();
  },
  created(){

  },
  watch: {
    form:{
      immediate: true,
      deep: true,
      handler(value) {
        console.log(value)
        if(value.brandId){
          this.getSeriesOptions(value.brandId)
        }
        if(value.seriesId){
          this.getModelOptions(value.seriesId)
        }
        if(value.modelId){
          this.getVersionOptions(value.modelId)
        }
        if(value.isNew&&value.isNew==='1'){
          this.showDamage = true
        }
      }
    }
  },
  methods:{
    confirmSelection() {
      this.showPopup = false;
      this.form.damage = this.checkedValues.join(',');
    },
    confirmSelection2() {
      this.showPopup2 = false;
      let attr2 = ''
      let checkValue = this.checkedValues2.join(",")
      if(checkValue){
        this.attribute2.forEach(e=>{
          if(checkValue.includes(e.text)){
            if(attr2){
              attr2 = attr2+","+e.text
            }else{
              attr2 = e.text
            }
          }
        })
      }
      this.form.attribute2 = attr2;
    },
    confirmSelection3() {
      this.showPopup3 = false;
      let attr = ''
      let checkValue = this.checkedValues3.join(",")
      if(checkValue){
        this.attribute3.forEach(e=>{
          if(checkValue.includes(e.text)){
            if(attr){
              attr = attr+","+e.text
            }else{
              attr = e.text
            }
          }
        })
      }
      this.form.attribute3 = attr;
    },
    confirmSelection4() {
      this.showPopup4 = false;
      let attr = ''
      let checkValue = this.checkedValues4.join(",")
      if(checkValue){
        this.attribute4.forEach(e=>{
          if(checkValue.includes(e.text)){
            if(attr){
              attr = attr+","+e.text
            }else{
              attr = e.text
            }
          }
        })
      }
      this.form.attribute4 = attr;
    },
    update(){
      this.form = this.info
      this.selectBrand = this.form.brandName;
      this.selectSeries = this.form.seriesName;
      this.selectModel = this.form.modelName;
      this.selectVersion = this.form.versionName;
      this.selectStatus = this.form.status;
      if(this.form.isNew&&this.form.isNew==='0'){
        this.selectNew ='新机'
      }else{
        this.selectNew = '二手机'
      }

      this.edit = true
    },
    cancel(){
      this.edit = false
    },
    save() {
      console.log(this.form)
      // 执行表单数据的校验
      this.$refs.form.validate().then(() => {
        addSeries(this.form).then(res => {

          if(res.code == 200) {
            Notify({ type: 'success', message: res.msg });
            this.goBack();
            return
          }
          Notify({ type: 'danger', message: res.msg });
        })
      }).catch(() => {
      })
    },
    goBack() {
      this.$router.push('/myPublish')
    },
    handBrandConfirm(value) {
      this.$refs.series.reset()
      this.$refs.model.reset()
      this.$refs.version.reset()
      this.form.seriesId = ""
      this.form.modelId = ""
      this.form.versionId = ""
      this.getSeriesOptions(value)
    },
    handSeriesConfirm(value) {
      this.$refs.model.reset()
      this.$refs.version.reset()
      this.form.modelId = ""
      this.form.versionId = ""
      this.getModelOptions(value)
    },
    handModelConfirm(value) {
      this.$refs.version.reset()
      this.form.versionId = ""
      this.getVersionOptions(value)
    },
    handIsNewConfirm(value) {
      if(value == 1) {
        this.showDamage = true
      } else {
        this.showDamage = false
        this.form.damage = ""
      }
    },
    getIsNew() {
      getDicts("is_new").then(res => {
        this.isNewOption = res.data.map(dict => {
          return {
            "text" : dict.dictLabel,
            "value" : dict.dictValue
          }
        })
      })
    },
    getStatus() {
      getDicts("status").then(res => {
        this.statusOption = res.data.map(dict => {
          return {
            "text" : dict.dictLabel,
            "value" : dict.dictLabel
          }
        })
      })
    },
    getDamage() {
      getDicts("damage").then(res => {
        if(res.data&&res.data.length>0) {
          res.data = res.data.sort((a, b) => parseInt(a.dictValue) - parseInt(b.dictValue))
          this.damageOption = res.data.map(dict => {
            this.title1 = dict.dicName
            return {
              "text": dict.dictLabel,
              "value": dict.dictLabel
            }
          })
        }
      })
    },
    getAttribute2() {
      getDicts("attribute2").then(res => {
        if(res.data&&res.data.length>0) {
          res.data = res.data.sort((a, b) => parseInt(a.dictValue) - parseInt(b.dictValue))
          this.attribute2 = res.data.map(dict => {
            this.title2 = dict.dicName
            return {
              "text": dict.dictLabel,
              "value": dict.dictLabel
            }
          })
        }
      })
    },
    getAttribute3() {
      getDicts("attribute3").then(res => {
        if(res.data&&res.data.length>0) {
          res.data = res.data.sort((a, b) => parseInt(a.dictValue) - parseInt(b.dictValue))
          this.attribute3 = res.data.map(dict => {
            this.title3 = dict.dicName
            return {
              "text": dict.dictLabel,
              "value": dict.dictLabel
            }
          })
        }
      })
    },
    getAttribute4() {
      getDicts("attribute4").then(res => {
        if(res.data&&res.data.length>0) {
          res.data = res.data.sort((a, b) => parseInt(a.dictValue) - parseInt(b.dictValue))
          this.attribute4 = res.data.map(dict => {
            this.title4 = dict.dicName
            return {
              "text": dict.dictLabel,
              "value": dict.dictLabel
            }
          })
        }
      })
    },
    async getBrandOptions() {
      this.brandOption = []
      try {
        const response = await request.get('/business/brand/list', {
          pageNum: 1,
          pageSize: 9999
        });
        for (let i = 0; i < response.rows.length; i++) {
          const existingIndex = this.brandOption.findIndex(item => item.text === response.rows[i].name);
          if (existingIndex === -1) {
            let map = {
              text: response.rows[i].name,
              value: response.rows[i].id
            };
            this.brandOption.push(map);
          }
        }
      } catch (error) {
        console.error('请求错误:', error);
      }
    },
    async getSeriesOptions(value) {
      this.seriesOption = []
      try {
        const response = await request.get('/business/productSeries/list', {
          pageNum: 1,
          pageSize: 9999,
          brandId:value
        });
        for (let i = 0; i < response.rows.length; i++) {
          const existingIndex = this.seriesOption.findIndex(item => item.text === response.rows[i].name);
          if (existingIndex === -1) {
            let map = {
              text: response.rows[i].name,
              value: response.rows[i].id
            };
            this.seriesOption.push(map);
          }
        }

        console.log(this.seriesOption)
      } catch (error) {
        console.error('请求错误:', error);
      }
    },
    async getModelOptions(value) {
      this.modelOption = []
      try {
        const response = await request.get('/business/model/list', {
          pageNum: 1,
          pageSize: 9999,
          seriesId:value
        });
        for (let i = 0; i < response.rows.length; i++) {
          const existingIndex = this.modelOption.findIndex(item => item.text === response.rows[i].name);
          if (existingIndex === -1) {
            let map = {
              text: response.rows[i].name,
              value: response.rows[i].id
            };
            this.modelOption.push(map);
          }
        }
      } catch (error) {
        console.error('请求错误:', error);
      }
    },
    async getVersionOptions(value) {
      this.versionOption = []
      try {
        const response = await request.get('/business/version/list', {
          pageNum: 1,
          pageSize: 9999,
          modelId:value
        });
        for (let i = 0; i < response.rows.length; i++) {
          const existingIndex = this.versionOption.findIndex(item => item.text === response.rows[i].name);
          if (existingIndex === -1) {
            let map = {
              text: response.rows[i].name,
              value: response.rows[i].id
            };
            this.versionOption.push(map);
          }
        }
      } catch (error) {
        console.error('请求错误:', error);
      }
    },
  }
}
</script>

<style scoped>
.content {
  text-align: center;
  padding:0 10px;
}
.avatar {
  height: 20vh;
}
.van-popup{
  margin-bottom: 50px;
}


</style>
