import request from '@/utils/request'

// 查询我邀请的
export function getAllInvite() {
    return request.get('/business/invite/selectAllList')
}

// 查询我邀请的
export function getMyInvite(query) {
  return request.get('/business/invite/selectMyList',query);
}