<template>
  <div id="app">
    <router-view/>

  </div>
</template>
<script>
  import { Dialog } from 'vant';
export default {
  data(){
    return {
      active: 0,
    };
  },
  created() {
    this.$EventBus.$on('globalAlert', (message) => {
      // alert(message); // 或者使用更复杂的弹窗组件
      Dialog.alert({
        // title: '提示',
        message: message,
        confirmButtonColor:"#5492ff",
        theme: 'round-button',
      }).then(() => {
        // on close
      });

    });
  },
  beforeDestroy() {
    this.$EventBus.$off('globalAlert'); // 确保移除监听器以避免内存泄漏
  }
}
</script>
<style>
  ::v-deep .van-dialog__message{
    font-size: 14px;
  }

  .van-nav-bar {
    background-color: #5492ff !important;
  }

  .van-nav-bar .van-icon {
    color: #fff !important;
  }
  .van-nav-bar__title {
    color: #fff !important
  }

  /* 设置Vant Toast的宽度 */
  .van-toast {
    width: 6.5rem !important; /* 你想要的宽度 */
  }
.btn-con{
  padding:10px 0;
  text-align: center;
  color: white;
  background:linear-gradient(180deg,#5D98FF,#659DFF);
  width: 90%;
  border-radius: 80px;
  margin: 14px auto;
}
</style>
