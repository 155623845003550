import request from '@/utils/request'

// 根据商家查询商品信息
export function getBusinessSeries(data) {
    return request.get('/business/series/findByUserId',data)
}

// 查询商家产品详细
export function getSeries(id) {
    return request.get('/business/series/' + id)
  }

// 新增商家产品信息
export function addSeries(data) {
    return request.post('/business/series',data)
}

// 修改商家产品信息
export function updateSeries(data) {
    return request.put('/business/series',data)
}


// 删除商家产品
export function delSeries(id) {
    return request.delete('/business/series/' + id)
}

// 修改状态
export function updateStatus(data) {
    return request.post('/business/series/updateStatus',data)
}
