<template>
  <div class="page">
    <van-popup v-model="seriesShow" overlay-class="customOverlay" position="right"
               :style="{ height: '85%', width: '70%' }">
      <div class="seriesSearch">
        <van-cell v-for="item in seriesOption" :key="item.value" :title="item.text" @click="check1(item)"/>
      </div>
    </van-popup>

    <van-popup v-model="modelShow" overlay-class="customOverlay" position="right"
               :style="{ height: '85%', width: '70%' }">
      <div class="seriesSearch">
        <van-cell v-for="item in modelOption" :key="item.value" :title="item.text" @click="check2(item)"/>
      </div>
    </van-popup>
    <van-popup v-model="versionShow" overlay-class="customOverlay" position="right"
               :style="{ height: '85%', width: '70%' }">

      <div class="seriesSearch">
        <van-cell v-for="item in versionOption" :key="item.value" :title="item.text" @click="check3(item)"/>
      </div>
    </van-popup>

    <div>
      <van-search
        class="search-top"
        v-model="searchName"
        action-text=''
        placeholder="快速搜索"
        @search="onSearch"
        @focus="onFocus"
      >

      </van-search>
      <!--<div class="search-con">-->

      <!--</div>-->
      <!--<input type="text" placeholder="快速搜索"><van-icon name="search" size="16" color="#54769B" class="search-icon"/></div>-->
      <!--      <van-search @click="clickSearch" v-model="value" @input="getBus(value)" class="search-con"-->
      <!--                  input-align="center" background="#448DF6" placeholder="搜索商品" />-->
    </div>

    <van-popup v-model="searchBusShow" position="bottom" :style="{ height: '40%' }">
      <van-cell v-for="(item,index) in searchBusList" :key="index" :title="item.text"
                @click="selectSearchItem(item)"/>
    </van-popup>


    <div class="s_contain">
      <van-sidebar class="s_left" v-model="activeKey" @change="selectItem">
        <van-sidebar-item :title="item.text" v-for="item in brandOption" :key="item.value"
                          :class="{ 'selected': selectedItem == item.value }"/>
      </van-sidebar>
      <!--      <div class="s_left">-->
      <!--        -->
      <!--&lt;!&ndash;        <van-cell v-for="item in brandOption" :key="item.value" :title="item.text"&ndash;&gt;-->
      <!--&lt;!&ndash;          :class="{ 'selected': selectedItem == item.value }" @click="selectItem(item.value)" />&ndash;&gt;-->
      <!--      </div>-->
      <div class="s_right">
        <div class="s_top">

          <div class="top-item">
            <!--<van-cell v-for="item in topList" :key="item.value" :title="item.text" is-link @click="selectItem1(item)" />-->
            <van-cell :title="seriesData.text" is-link @click="selectItem1(seriesData)"/>
            <van-cell :title="modelData.text" is-link @click="selectItem1(modelData)"/>
            <van-cell :title="versionData.text" is-link @click="selectItem1(versionData)"/>
            <div class="btn-search" @click="toDetail">搜索</div>
          </div>

        </div>
        <div class="isNew">
          <div v-for="(isNew, index) in isNewList" :key="index" class="isNew-item" @click="selectIsNew(isNew)"
               :class="{ 'isNewCity': currentIsNew == isNew.value }">{{ isNew.text }}
          </div>
        </div>
        <div class="s_bottom">

          <div class="bottom-city">
            <div class="select-city">
              <div class="select-p1" @click="handleCity('')" :class="{ 'selectCity': currentCity == '' }">全国</div>
              <div class="select-p2" @click="isShowCity=!isShowCity" :class="{ 'selectCity': selectCity != '' }">

                <span>{{!selectCity?'请选择':selectProvince}}</span>
                <span class="select-span">{{selectCity}}</span>
              </div>
            </div>
            <p class="city-title">常用城市</p>
            <div class="citys">
<!--              <div class="city-item" @click="handleCity('')" :class="{ 'selectCity': currentCity == '' }">全国</div>-->

              <div v-for="(city, index) in cityList" :key="index" class="city-item" @click="handleCity(city)"
                   :class="{ 'selectCity': currentCity&&currentCity.text == city.text }">{{ city.text }}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <van-popup v-model="isShowCity" position="bottom">
      <van-area
        v-model="currentArea"
        :columns-num="2"
        title="选择地区"
        :area-list="areaList"
        @confirm="onConfirm"
        @cancel="onCancel"
      />
    </van-popup>
  </div>
</template>

<script>
  import request from '@/utils/request';
  import {Sidebar, SidebarItem, Notify} from 'vant';

  import {checkView} from '@/utils/checkUtil'
  import {areaList} from '@vant/area-data';

  export default {
    data() {
      return {
        searchName: "",
        activeKey: 0,
        isShowCity: false,
        currentArea: '370000,370100',
        searchBusShow: false,
        searchBusList: [],
        value: '',
        show: false,
        seriesShow: false,
        modelShow: false,
        versionShow: false,
        currentBrand: '',
        currentSeries: '',
        currentModel: '',
        currentVersion: '',

        seriesValue:0,
        modelValue:0,
        versionValue:0,

        cityName:'',


        brandOption: [],
        currentCity: '',
        seriesOption: [],
        modelOption: [],
        versionOption: [],
        topList: [],
        selectedItem: null,
        cityList: [],
        isNewList: [{text: '新机', value: '0'}, {text: '二手机', value: '1'}],
        currentIsNew: '0',
        dateShow: false,
        areaList: {},
        selectProvince: '',
        selectCity: '',

        seriesData: {},
        modelData: {},
        versionData: {},

        brandItemChange: false,
        seriesChange: false,
        modelChange: false,
        versionChange: false,

      }
    },
    methods: {
      onFocus() {
        this.$router.push("/quickSearch")
      },
      onSearch(val) {
        // this.toDetail();
        // console.log(val);//val为输入框文本
      },

      selectSearchItem(item) {
        let result = checkView();
        if (result.flag) {
          this.$router.push({
            name: 'searchDetail',
            params: {
              currentBrand: item.value.brandId.value,
              currentModel: item.value.modelId,
              currentSeries: item.value.seriesid,
              currentVersion: item.value.versionId,

            }
          })
        } else {
          Notify({type: 'danger', message: result.msg});
        }
      },

      getBus(value) {
        if (value != null && value != '') {
          this.searchBusList = []
          request.get(`/business/series/getBusninessSereis/${value}`)
            .then(response => {
              // 处理响应数据
              console.log("response", response)
              for (let i = 0; i < response.data.length; i++) {
                let map = {}
                map['text'] = response.data[i].brandName + "-" + response.data[i].seriesName + "-" + response.data[i].modelName + "-" + response.data[i].versionName;
                map['value'] = {
                  brandId: {value: response.data[i].brand_id, text: response.data[i].brandName},
                  seriesid: {value: response.data[i].series_id, text: response.data[i].seriesName},
                  modelId: {value: response.data[i].model_id, text: response.data[i].modelName},
                  versionId: {value: response.data[i].version_id, text: response.data[i].versionName},
                }
                this.searchBusList.push(map)
              }
              console.log("搜索情况", this.searchBusList)
            })
            .catch(error => {
              // 处理请求错误
              console.error('请求错误:', error);
            });
        }

      },
      onConfirm(e) {
        console.log(e)
        if (e.length >= 2) {
          this.selectProvince = e[0].name
          this.selectCity = e[1].name
          this.isShowCity = false
          this.currentCity = {
            text: e[1].name,
            //value:currentCityInfo.value,
            selectProvince: this.selectProvince,
            selectCity: this.selectCity
          }

        }

      },
      onCancel() {
        this.isShowCity = false
      },
      handleCity(item) {
        this.selectCity = '';
        this.currentCity = item;

      },
      selectIsNew(item) {
        this.currentIsNew = item.value;
      },
      check1(item) {
        this.currentSeries = item;
        this.seriesData = item;
        // this.topList = [];
        // this.topList.push(item)
        //this.getModelOptions();
        //await this.getVersionOptions();
        this.seriesShow = false;
        this.seriesChange = true;
      },
      check2(item) {
        this.currentModel = item;
        this.modelData = item;
        // console.log(item)
        // this.topList = [];
        // this.topList.push(this.currentSeries)
        // this.topList.push(item)
        //this.getVersionOptions(item);
        this.modelShow = false;
        this.modelChange = true;
      },
      check3(item) {
        this.currentVersion = item;
        this.versionData = item;
        // this.topList = [];
        // this.topList.push(this.currentSeries)
        // this.topList.push(this.currentModel)
        // this.topList.push(item)
        this.versionShow = false
        this.versionChange = true;
      },
      selectItem(index) {

        this.activeKey = index;
        let item = this.brandOption[index].value;

        this.selectedItem = item;
        this.currentBrand = item;
        this.currentModel = '';
        this.currentSeries = '';
        this.currentVersion = '';

        this.seriesData = {};
        this.modelData = {};
        this.versionData = {};

        this.brandItemChange = true
        //this.brandChange(true);
      },
      selectItem1(item) {
        if (item.label == 'series') {
          this.seriesShow = true;
        }
        if (item.label == 'model') {
          this.modelShow = true;
        }
        if (item.label == 'version') {
          this.versionShow = true;
        }
      },
      toDetail() {
        let result = checkView();
        if (result.flag) {
          this.$router.push({
            name: 'searchDetail',
            query: {
              selectedItem: this.selectedItem,
              currentBrand: this.currentBrand,
              currentCity: this.currentCity,
              currentIsNew: this.currentIsNew,
              currentModel: this.currentModel,
              currentSeries: this.currentSeries,
              currentVersion: this.currentVersion,
              searchName: this.searchName,
              activeKey: this.activeKey,
              seriesValue:this.currentSeries.value,
              modelValue:this.currentModel.value,
              versionValue:this.currentVersion.value,
              cityName:this.currentCity.text,
              seriesName:this.currentSeries.text,
              modelName:this.currentModel.text,
              versionName:this.currentVersion.text,
              form: this.$route.query.form,
              id: this.$route.query.id,
              name: this.$route.query.name,
            }
          })
        } else {
          Notify({type: 'danger', message: result.msg});
        }
      },
      async getBrandOptions() {
        this.brandOption = [];
        if (this.$route.query.form == 'home') {
          this.brandOption.push({
            text: this.$route.query.name,
            value: this.$route.query.id
          });
          this.currentBrand = this.brandOption[0].value;
          this.selectedItem = this.brandOption[0].value;
        } else {
          try {
            const response = await request.get('/business/brand/list', {
              pageNum: 1,
              pageSize: 9999
            });
            for (let i = 0; i < response.rows.length; i++) {
              const existingIndex = this.brandOption.findIndex(item => item.text === response.rows[i].name);
              if (existingIndex === -1) {
                let map = {
                  text: response.rows[i].name,
                  value: response.rows[i].id
                };
                this.brandOption.push(map);
              }
            }
            if (this.brandOption && this.brandOption.length > 0) {
              if(!this.currentBrand){
                this.currentBrand = this.brandOption[0].value;
                this.selectedItem = this.brandOption[0].value;
              }
            }
          } catch (error) {
            console.error('请求错误:', error);
          }
        }

      },
      getSeriesOptions() {
        this.seriesOption = []
        try {
          request.get('/business/productSeries/list', {
            pageNum: 1,
            pageSize: 9999,
            brandId: this.currentBrand,
          }).then(res => {
            if (res.rows && res.rows.length > 0) {
              this.seriesOption = []
              for (let i = 0; i < res.rows.length; i++) {
                const existingIndex = this.seriesOption.findIndex(item => item.text === res.rows[i].name);
                if (existingIndex === -1) {
                  let map = {
                    text: res.rows[i].name,
                    value: res.rows[i].id,
                    label: 'series'
                  };
                  this.seriesOption.push(map);
                }
              }
              if (this.seriesOption && this.seriesOption.length > 0) {
                if (this.seriesValue&&!this.brandItemChange) {
                  let series = this.seriesOption.filter(e=>e.value ===parseInt(this.seriesValue))
                  if(series&&series.length>0){
                    this.currentSeries = series[0]
                  }else{
                    this.currentSeries = this.seriesOption[0];
                  }
                }else{
                  this.currentSeries = this.seriesOption[0];
                  this.brandItemChange = false;
                }
                this.seriesData = this.currentSeries;
              } else {
                //this.topList.push({ text: "未发布系列", value: 99999 });
                this.seriesData = {text: "未发布系列", value: 99999}
              }
            }else{
              this.seriesData = {text: "未发布系列", value: 99999}
              this.modelData = {text: "未发布型号", value: 99999}
              this.versionData = {text: "未发布版本", value: 99999}
            }
          })
        } catch (error) {
          console.error('请求错误:', error);
        }
      },
      getModelOptions() {
        this.modelOption = []
        try {
          request.get('/business/model/list', {
            pageNum: 1,
            pageSize: 9999,
            brandId: this.currentBrand,
            seriesId: this.currentSeries.value,
          }).then(response => {
            if (response.rows && response.rows.length > 0) {
              this.modelOption = []
              for (let i = 0; i < response.rows.length; i++) {
                const existingIndex = this.modelOption.findIndex(item => item.text === response.rows[i].name);
                if (existingIndex === -1) {
                  let map = {
                    text: response.rows[i].name,
                    value: response.rows[i].id,
                    label: 'model'
                  };
                  this.modelOption.push(map);
                }
              }
              if (this.modelOption && this.modelOption.length > 0) {
                if (this.modelValue&&!this.seriesChange) {
                  let model = this.modelOption.filter(e=>e.value===parseInt(this.modelValue))
                  if(model&&model.length>0){
                    this.currentModel = model[0];
                  }else{
                    this.currentModel = this.modelOption[0];
                  }
                }else{
                  this.currentModel = this.modelOption[0];
                  this.seriesChange = false
                }
                //this.topList.push(this.currentModel);
                this.modelData = this.currentModel;
              } else {
                //this.topList.push({ text: "未发布型号", value: 99999 });
                this.modelData = {text: "未发布型号", value: 99999}
              }
            } else {
              this.modelData = {text: "未发布型号", value: 99999}
              this.versionData = {text: "未发布版本", value: 99999}
            }
          })
        } catch (error) {
          console.error('请求错误:', error);
        }
      },
      getVersionOptions(item) {
        this.versionOption = []
        try {
          request.get('/business/version/list', {
            pageNum: 1,
            pageSize: 9999,
            brandId: this.currentBrand,
            seriesId: this.currentSeries.value,
            modelId: item ? item.value : this.currentModel.value
          }).then(response=>{
            if (response.rows && response.rows.length > 0) {
              this.versionOption = []
              for (let i = 0; i < response.rows.length; i++) {
                const existingIndex = this.versionOption.findIndex(item => item.text === response.rows[i].name);
                if (existingIndex === -1) {
                  let map = {
                    text: response.rows[i].name,
                    value: response.rows[i].id,
                    label: 'version'
                  };
                  this.versionOption.push(map);
                }
              }
              if (this.versionOption && this.versionOption.length > 0) {
                if (this.versionValue&&!this.modelChange) {
                  let version = this.versionOption.filter(e=>e.value===parseInt(this.versionValue))
                  if(version&&version.length>0){
                    this.currentVersion = version[0];
                  }else{
                    this.currentVersion = this.versionOption[0];
                  }
                }else{
                  this.currentVersion = this.versionOption[0];
                  this.modelChange = false
                }
                //this.topList.push(this.currentVersion);
                this.versionData = this.currentVersion;
              } else {
                //this.topList.push({ text: "未发布版本", value: 99999 });
                this.versionData = {text: "未发布版本", value: 99999}
              }
            } else {
              this.versionData = {text: "未发布版本", value: 99999}
              this.versionOption = []
            }
          })
        } catch (error) {
          console.error('请求错误:', error);
        }
      },
      brandChange(first) {
        if (first) {
          this.getBrandOptions();
        }
        // this.getSeriesOptions();
        // this.getModelOptions();
        // this.getVersionOptions();
      },
      getCityOption() {
        this.cityList = []
        let params = {pageNo: 1, pageSize: 9}
        request.get('/business/city/listIndex', params)
          .then(response => {
            // 处理响应数据
            for (let i = 0; i < response.rows.length; i++) {
              const existingIndex = this.cityList.findIndex(item => item.text === response.rows[i].name);
              if (existingIndex === -1) {
                let map = {
                  text: response.rows[i].name,
                  value: response.rows[i].id,
                };
                this.cityList.push(map);
              }
            }
          })
          .catch(error => {
            // 处理请求错误
            console.error('请求错误:', error);
          });
      }
    },
    mounted() {
      const paramValue = this.$route.query;

      if(paramValue&&paramValue.seriesValue){
        this.seriesValue = paramValue.seriesValue;
      }
      if(paramValue&&paramValue.modelValue){
        this.modelValue = paramValue.modelValue;
      }
      if(paramValue&&paramValue.versionValue){
        this.versionValue = paramValue.versionValue;
      }

      if(paramValue && paramValue.currentIsNew){
        this.currentIsNew = paramValue.currentIsNew
      }

      if (paramValue && paramValue.currentCity) {
        this.currentCity = paramValue.currentCity
      }

      if(paramValue&&paramValue.cityName){
        this.cityName = paramValue.cityName
        if(!this.currentCity||!this.currentCity.text){
          this.currentCity = {
            text:this.cityName
          }
        }
      }

      if (paramValue && paramValue.currentCity) {
        if (paramValue.currentCity && paramValue.currentCity.selectProvince)
          this.selectProvince = paramValue.currentCity.selectProvince
      }
      if (paramValue && paramValue.currentCity && paramValue.currentCity.selectCity) {
        this.selectCity = paramValue.currentCity.selectCity
      }

      if (paramValue && paramValue.currentBrand) {
        this.currentBrand = paramValue.currentBrand
      }

      if (paramValue && paramValue.selectedItem) {
        this.selectedItem = paramValue.selectedItem
      }

      if (paramValue && paramValue.currentSeries) {
        this.currentSeries = paramValue.currentSeries
      }

      if (paramValue && paramValue.currentModel) {
        this.currentModel = paramValue.currentModel
      }

      if (paramValue && paramValue.currentVersion) {
        this.currentVersion = paramValue.currentVersion
      }

      if (paramValue && paramValue.activeKey) {
        this.activeKey = paramValue.activeKey
      }
    },
    created() {
      this.brandChange(true);
      this.getCityOption();

      this.areaList = areaList
    },
    watch: {
      currentBrand: {
        immediate: true,
        deep: true,
        handler(value) {
          if (value) {
            this.getSeriesOptions()
          }else{
            this.seriesData = {text: "未发布系列", value: 99999}
          }
        }
      },
      currentSeries: {
        immediate: true,
        deep: true,
        handler(value) {
          if (value && this.currentBrand) {
            this.getModelOptions()
          }else{
            this.modelData = {text: "未发布型号", value: 99999}
          }
        }
      },
      currentModel: {
        immediate: true,
        deep: true,
        handler(value) {
          if (value && this.currentSeries && this.currentBrand){
            this.getVersionOptions()
          }else{
            this.versionData = {text: "未发布版本", value: 99999}
          }
        }
      }
    }
  }
</script>

<style lang="scss" scoped>
  .page {
    background: #F6F7FB;
    min-height: 80vh;
  }

  .selected {
    background-color: #F6F7FB !important;
    color: #A42B24;
  }

  .selectCity {
    background-color: #448DF6 !important;
    color: white !important;
  }

  .isNewCity {
    background-color: #448DF6 !important;
    color: white !important;;
  }

  .s_contain {
    width: 100%;
    display: flex;
  }

  .s_left {
    text-align: center;
    overflow: auto;
    width: 33%;
    background: white;
    min-height: 80vh;
  }

  ::v-deep .van-sidebar-item {
    background: white;
    padding: 16px 12px;
    font-weight: bold;
    font-size: 16px;
  }

  ::v-deep .van-sidebar-item--select::before {
    background-color: #A42B24;
    height: 35px;
    width: 5px;
    border-radius: 10px;
  }

  ::v-deep .van-sidebar-item--select {
    background: #F6F7FB;
  }

  .s_right {
    width: 67%;
    background: #F6F7FB;
  }

  .s_top {
    display: flex;
    flex-direction: column;
    background-color: #fff;
    margin: 12px 0 20px 0;
  }

  .s_bottom {
    background: white;
    margin: 0 16px;
    border-radius: 8px;

    height: 230px;
    overflow: auto;
    .bottom-city {
      padding: 12px 12px 0 12px;
    }
  }

  .s_left .van-cell {
    background-color: #F6F7FB;
    font-size: 16px;
    border-bottom: 1px solid #fff;
  }

  .top-item {
    background-color: #F6F7FB;
  }

  .btn-search {
    width: 85%;
    background-color: #448DF6;
    text-align: center;
    border-radius: 8px;
    color: white;
    padding: 8px 0 8px 0;
    margin: 15px auto 0 auto;
  }

  .van-cell__right-icon {
    color: white;
    font-size: 10px;
  }

  .top-item .van-cell {
    width: 85%;
    margin: 8px auto;
    background-color: #448DF6;
    color: white;
    padding: 8px 8px 8px 0;
    margin-bottom: 13px;
    border-radius: 8px;
    text-align: center;
  }

  .seriesSearch {
    text-align: left;
    background-color: #ededed !important;
  }

  .citys {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
  }

  .city-item {
    margin-bottom: 10px;
    width: 29.5%;
    padding: 8px 0;
    background-color: #F6F7FB;
    border-radius: 4px;
    text-align: center;
    font-size: 13px;
    margin-right: 6px;
  }

  .isNew {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    background: white;
    width: 78%;
    padding: 12px;
    margin: 12px auto;
    border-radius: 8px;
  }

  .isNew-item {
    width: 48%;
    padding: 8px 0;
    background-color: #F6F7FB;
    border-radius: 4px;
    text-align: center;
    justify-content: space-between;
  }

  .van-cell__title {
    text-align: center;
  }

  .select-city {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 10px;
    margin-right: 10px;
    div {
      text-align: center;
      background: #F6F7FB;
      border-radius: 4px;
      padding: 8px;
      font-size: 12px;
    }
    .select-p1 {
      width: 25%;
    }
  }

  .select-p2 {
    padding: 8px;
    background: #F6F7FB;
    border-radius: 10px;
    font-size: 14px;
    width: 55%;
    .select-span {
    }
  }

  .search-top {
    background: #448DF6;
    text-align: center;
    margin: 0 auto;
    /*display: flex;*/
    /*align-items: center;*/
    /*justify-content: center;*/
    padding: 10px 0 6px 0;
    .search-con {
      background: #AACBF6;
      display: flex;
      align-items: center;
      padding: 8px 0;
      width: 75%;
      justify-content: center;
      border-radius: 2px;
      .search-icon {
        margin-left: 60px;
      }
      input {
        margin-left: 4px;
        outline: none;
        border: 0;
        color: #333;
        font-size: 13px;
        background: #AACBF6;
      }

    }
  }

  ::v-deep .customOverlay {
    background-color: rgba(0, 0, 0, 0) !important;

  }

  ::v-deep .customOverlay .van-popup {
    margin-top: 2rem;
    z-index: 1 !important;
  }

  .van-search__content {

    margin: 0 15%;
    background: #AACBF6;
  }

  ::v-deep input.van-field__control::placeholder {
    color: #788EA2 !important;
  }

  .van-field__control::placeholder {
    color: #788EA2 !important;
  }
  .city-title{
    font-size: 14px;
    padding-left: 2px;
    font-weight: bold;
  }
</style>
