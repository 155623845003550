<template>
  <div class="contain">
    <van-nav-bar title="意见反馈"  left-arrow @click-left="goBack"></van-nav-bar>
    <van-form ref="form">
    <van-cell-group class="content">
      <van-field
        v-model="form.content"
        rows="3"
        autosize
        label="留言"
        type="textarea"
        maxlength="200"
        placeholder="请输入留言"
        required
        :rules="[{ required: true }]"
        show-word-limit
      />
      <van-field
        v-model="form.phonenumber"
        label="手机号"
        placeholder="请输入手机号"
        type="tel"
        :rules="phoneRules"
        required
      ></van-field>
    </van-cell-group>
    <div style="width:90%;margin:15px auto">
        <van-button style="height: 40px;background: #5492ff" type="info" size="large" @click="save" round>保存</van-button>
    </div>
  </van-form>
  </div>

</template>

<script>
import { addFeedback } from "@/api/feedback"
import { Notify } from "vant";
export default {
  data() {
    return {
      form : {
        content: '',
        phonenumber: ''
      },
      phoneRules: [
        { required: true, message: '请输入手机号' },
        {
          pattern: /^1[3456789]\d{9}$/,
          message: '手机号格式不正确'
        }
      ],
    };
  },
  mounted(){

  },
  methods:{
    save() {
      // 执行表单数据的校验
      this.$refs.form.validate().then(() => {
        addFeedback(this.form).then(res => {
          if(res.code == 200) {
            Notify({ type: 'success', message: '反馈成功' });
            this.goBack()
            return
          }
          Notify({ type: 'danger', message: res.msg });
        })
      }).catch(() => {

      })

    },
    goBack() {
      this.$router.push('/userCenter')
    }
  }
}
</script>

<style lang="scss" scoped>

</style>
