<template>
  <div class="home">
    <router-view/>
    <van-tabbar route :placeholder="true" style="z-index: 9998;position: relative">
      <van-tabbar-item   to="/home"  icon="home-o">首页</van-tabbar-item>
      <van-tabbar-item   to="/search" icon="search">市场</van-tabbar-item>
      <van-tabbar-item   to="/publish">
        <p class="menu-add"><img src="../assets/add.png" class="add-icon"/></p>
      </van-tabbar-item>
      <van-tabbar-item   to="/dynamic"  icon="label-o">动态</van-tabbar-item>
      <van-tabbar-item   to="/userCenter" icon="user-o">我的</van-tabbar-item>
    </van-tabbar>
  </div>
</template>

<script>
// @ is an alias to /src

export default {
  name: 'HomeView',
  data(){
    return{

    }
  },
  mounted() {
    // this.$router.push("/home").catch(error => {})
    // debugger;
    console.log("history.current.path",this.$router.history.current.path );
    if(this.$router.history.current.path === '/'){
      this.$router.push("/home").catch(error => {})
    }
  },
  components: {

  }
}
</script>
<style lang="scss" scoped>
  .menu-add{
    background:linear-gradient(180deg, #4C8CF9,#1963F9);
    border-radius: 50%;
    width: 42px;
    height: 42px;
    text-align: center;
    color: white;
    position: relative;
    z-index: 99999;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: auto;
    top: -26px;
  }
  .add-icon{
    width: 20px;
    height: 20px;
  }
</style>
