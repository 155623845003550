<template>
  <div class="container" style="padding-bottom: 100px;">
    <van-nav-bar title="商家认证" left-arrow @click-left="goBack"></van-nav-bar>
    <van-form ref="form">
      <div style="display: flex;flex-direction: column;align-items: center;">
        <van-cell-group class="content">
          <van-field v-if="form.id != null" v-model="form.auditingStatusStr" label="审核状态" :readonly="true"></van-field>
<!--          <van-field-->
<!--            v-model="cityName"-->
<!--            is-link-->
<!--            readonly-->
<!--            label="城市"-->
<!--            placeholder="选择城市"-->
<!--            :disabled="isDisabled"-->
<!--            @click="showPopup = true"-->
<!--          />-->
          <div class="img-upload">
            <p>营业执照</p>
            <van-cell-group class="upload">
              <van-uploader
                      :disabled="isDisabled||isEdit"
                      :action="baseUrl+`/common/upload`"
                      :max-count="1"
                      accept="image/*"
                      :delete="deletImg"
                      :after-read="uploadLicensImage"
                      style="margin: 0 auto;"
              >
                <img v-if="form.licenseUrl" :src="form.licenseUrl" class="img" fit="contain" />
                <div v-else class="uploader-slot">
                  <van-icon name="plus" />
                  <div>请上传营业执照</div>
                </div>
              </van-uploader>
            </van-cell-group>
            <p>身份证正面照片</p>
            <van-cell-group class="upload" >


              <van-uploader
                      :disabled="isDisabled||isEdit"
                      :action="uploadUrl"
                      :max-count="1"
                      accept="image/*"
                      :delete="deletFrontImg"
                      :after-read="uploadFrontImage"
                      style="margin: 0 auto;"
              >
                <img v-if="form.frontUrl" :src="form.frontUrl" class="img" fit="contain" />
                <div class="uploader-slot" v-else>
                  <van-icon name="plus" />
                  <div>请上传身份证正面照片</div>
                </div>
              </van-uploader>
            </van-cell-group>
            <p>身份证反面照片</p>
            <van-cell-group class="upload" >

              <van-uploader
                      :disabled="isDisabled||isEdit"
                      :action="uploadUrl"
                      :max-count="1"
                      accept="image/*"
                      :delete="deletReverseImg"
                      :after-read="uploadReverseImage"
                      style="margin: 0 auto;"
              >
                <img v-if="form.reverseUrl" :src="form.reverseUrl" class="img" fit="contain" />
                <div class="uploader-slot" v-else>
                  <van-icon name="plus" />
                  <div>请上传身份证反面图片</div>
                </div>
              </van-uploader>
            </van-cell-group>
            <p>手持身份证照片</p>
            <van-cell-group class="upload" >

              <van-uploader
                      :disabled="isDisabled||isEdit"
                      :action="uploadUrl"
                      :max-count="1"
                      accept="image/*"
                      :delete="deletHandImg"
                      :after-read="uploadHandImage"
                      style="margin: 0 auto;"
              >
                <img v-if="form.handUrl" :src="form.handUrl" class="img" fit="contain" />
                <div class="uploader-slot" v-else>
                  <van-icon name="plus" />
                  <div>请上传手持身份证图片</div>
                </div>
              </van-uploader>
            </van-cell-group>
          </div>
          <p class="p-title">商家信息</p>
          <van-field v-model="form.doorNumber" label="门牌号" placeholder="请输入门牌号,如3A20" :readonly="isEdit"></van-field>
          <van-field v-model="form.phone" label="联系手机号" placeholder="请输入你的联系手机号" type="tel" :readonly="isEdit"></van-field>
          <van-field v-model="form.name" label="公司名称"   :readonly="true" :rules="[{ required: true, message: '请上传营业执照获取公司名称' }]"  rows="1"  autosize type="textarea"></van-field>
          <van-field v-model="form.comCreditCode" label="税号"  type="text" :readonly="true"  :rules="[{ required: true, message: '请上传营业执照获取税号' }]"></van-field>
          <van-field v-model="form.comLegalPerson" label="法人/负责人"  type="text" :readonly="true" :rules="[{ required: true, message: '请上传营业执照获取法人' }]"></van-field>
          <van-field v-model="form.comRegistrationDate" label="注册日期"  type="text" :readonly="true"></van-field>
          <van-field v-model="form.comRegisteredCapital" label="注册资本"  type="text" :readonly="true"></van-field>
          <van-field v-model="form.comBusinessScope" label="经营范围"  :readonly="true"  rows="1"  autosize type="textarea"></van-field>
          <van-field v-model="form.address" label="地址"   :readonly="true"  rows="1"  autosize type="textarea"></van-field>
          <van-field v-model="form.comCompanyType" label="类型" style="display: none;"  type="text" :readonly="true"></van-field>
          <van-field v-model="form.comValidPeriod" label="营业期限" style="display: none;" type="text" :readonly="true"></van-field>
          <van-field v-model="form.comCompanyForm" label="组成形式" style="display: none;" type="text" :readonly="true"></van-field>
          <van-field v-model="form.comIssueDate" label="发照日期" style="display: none;"  type="text" :readonly="true"></van-field>
          <van-field v-model="form.comTitle" label="证照标题" style="display: none;"  type="text" :readonly="true"></van-field>

          <p class="p-title">身份信息</p>
          <van-field v-model="form.idCardName" label="姓名" :readonly="true" :rules="[{ required: true, message: '请上传上传身份证正面获取名称' }]"></van-field>
          <van-field v-model="form.idCardSex" label="性别" :readonly="true"></van-field>
          <van-field v-model="form.idCardEthnicity" label="民族" :readonly="true"></van-field>
          <van-field v-model="form.idCardBirthDate" label="出生日期" :readonly="true"></van-field>
          <van-field v-model="form.idCardAddress" label="住址" :readonly="true"></van-field>
          <van-field v-model="form.idCardIdNumber" label="身份证号码" :readonly="true"></van-field>
          <van-field v-model="form.idCardIssueAuthority" label="签发机关" :readonly="true"></van-field>
          <van-field v-model="form.idCardValidPeriod" label="有效期" :readonly="true"></van-field>


<!--          <van-field v-model="form.bankCard" label="银行卡" placeholder="请输入你的银行卡号" :readonly="true"></van-field>-->
<!--          <van-field v-model="form.openingBank" label="开户行" placeholder="请输入你的开户行" :readonly="true"></van-field>-->
        </van-cell-group>

      </div>
      <div v-if="!isEdit" style="width:90%;margin:15px auto">
        <van-button style="height: 40px;background: #659DFF" type="info" size="large" @click="save" round :disabled="isDisabled">提交</van-button>
      </div>


    </van-form>
    <van-popup v-model="showPopup" round position="bottom">
      <van-picker
        :columns="cityData"
        @cancel="showPopup = false"
        @confirm="onConfirm"
        title="城市"
        :show-toolbar="true"
      />
    </van-popup>
  </div>
</template>

  <script>
import {
  getBusinessInfo,
  updateBusinessInfo,
  addBusinessInfo
} from "@/api/business";
import { getCityData } from "@/api/city"
import { uploadImg } from "@/api/common"
import { Notify } from 'vant';
import { formatDate } from "@/utils/date"
import request from '@/utils/request'
export default {
  data() {
    return {
      baseUrl:request.baseURL,
      files: [],
      cityData: [
        { text: "杭州", value: "1" },
        { text: "宁波", value: "2" },
        { text: "温州", value: "3" },
        { text: "绍兴", value: "4" },
        { text: "湖州", value: "5" }
      ],
      cityName: "",
      showPopup: false,
      form: {
        id: null,
        userId: "",
        cityId: "",
        name: "",
        bankCard: "",
        openingBank: "",
        doorNumber: "",
        licenseUrl: null,
        frontUrl: null,
        reverseUrl: null,
        handUrl: null,
        isListing: '',
        auditingStatus: '0',
        phone:''
      },
      uploadUrl: process.env.VUE_APP_BASE_URL + "/common/upload",
      isDisabled: true,
      isEdit:false
    };
  },
  mounted() {
    this.init()
  },
  created() {
    this.user = JSON.parse(localStorage.getItem('yltUser'))
    console.log("user", this.user)
    if (!!this.user) {
      this.$set(this.form,"phone",this.user.phonenumber);
    }
  },
  methods: {
    async init() {
      await this.getCity()
      await this.getInfo();
    },
    getInfo() {
      getBusinessInfo().then(res => {
        if(res.data) {
          this.form = res.data
          this.cityName = this.form.cityName
          if(this.form.auditingStatus==1){
            this.isEdit=true;
          }
         this.isDisabled = this.form.auditingStatus == 0
        } else {
          this.isDisabled = false
        }
      })
    },
    getCity() {
      getCityData().then(res => {
       this.cityData = res.data.map(obj => {
          return {"text": obj.name,"value" : obj.id.toString()}
        })
        console.log(this.cityData)
      })
    },
    show() {
      this.showPopup = true;
    },
    onConfirm(item) {
      this.form.cityId = item.value;
      this.cityName = item.text;
      this.showPopup = false;
    },
    onCancel() {
      this.showPopup = false;
    },
    save() {
      // 执行表单数据的校验
      this.$refs.form
        .validate()
        .then(() => {
          this.form.isListing = this.getIsListing()
          if (this.form.id != null) {
            this.form.auditingStatus = '0'
            if (this.form.doorNumber==''){
              this.$toast.fail("请输入门牌号");
              return false;
            }
            if (this.form.phone==''){
              this.$toast.fail("请输入手机号");
              return false;
            }
            updateBusinessInfo(this.form).then(res => {
              if(res.code == 200) {
                this.setStorage()
                // Notify({ type: 'success', message: '修改成功,等待审核' })
                this.$toast.success('修改成功,等待审核' )
                this.goBack();
                return
              }
              // Notify({ type: 'danger', message: res.msg });
              this.$toast.fail(res.msg);
            });
          } else {
            if (this.form.doorNumber==''){
              this.$toast.fail("请输入门牌号");
              return false;
            }
            if (this.form.phone==''){
              this.$toast.fail("请输入联系手机号");
              return false;
            }
            addBusinessInfo(this.form).then(res => {
              if(res.code == 200) {
                // this.setStorage()
                this.$toast.success('申请成功,等待审核' )
                this.goBack();
                return
              }
              // Notify({ type: 'danger', message: res.msg });
              this.$toast.fail(res.msg);
            });
          }

        })
        .catch(error => {});
    },
    setStorage() {
      let info = JSON.parse(localStorage.getItem("businessInfo"))
      info = this.form
      info.isRecharge = this.form.isRecharge ? this.form.isRecharge : "0"
      info.expirationTime = this.form.expirationTime ? this.form.expirationTime : formatDate()
      localStorage.setItem("businessInfo",JSON.stringify(info))
    },
    getIsListing() {
      if (
        this.form.licenseUrl == null ||
        this.form.frontUrl == null ||
        this.form.reverseUrl == null ||
        this.form.handUrl == null
      ) {
        return "0";
      }
      return "1";
    },
    deletImg() {
      this.form.licenseUrl = null;
    },
    deletFrontImg() {
      this.form.frontUrl = null;
    },
    deletReverseImg() {
      this.form.reverseUrl = null;
    },
    deletHandImg() {
      this.form.handUrl = null;
    },
    goBack() {
      this.$router.push("/userCenter");
    },
    uploadLicensImage(file) {
      const formData = new FormData();
      formData.append('file', file.file);
      formData.append('uploadType','Licens');
      this.$toast.loading("请求中",{ duration: 2000 });
      uploadImg(formData).then(res => {
        if(res.code == 200) {
          // this.form.licenseUrl = res.url

          if(!!res.ocrInfo){
            let ocrInfo = JSON.parse(res.ocrInfo);
            if(!!ocrInfo.Code){
              this.$toast.fail(ocrInfo.Message);
            }else{
              this.form.licenseUrl = this.baseUrl+res.fileName;
              let ocrCompanyInfo = JSON.parse(ocrInfo.data).data;
              this.$set(this.form,"name",ocrCompanyInfo.companyName);
              this.$set(this.form,"comCreditCode",ocrCompanyInfo.creditCode);
              this.$set(this.form,"comCompanyType",ocrCompanyInfo.companyType);
              this.$set(this.form,"comLegalPerson",ocrCompanyInfo.legalPerson);
              this.$set(this.form,"comBusinessScope",ocrCompanyInfo.businessScope);
              this.$set(this.form,"comRegisteredCapital",ocrCompanyInfo.registeredCapital);
              this.$set(this.form,"comRegistrationDate",ocrCompanyInfo.RegistrationDate);
              this.$set(this.form,"comValidPeriod",ocrCompanyInfo.validPeriod);
              this.$set(this.form,"comCompanyForm",ocrCompanyInfo.companyForm);
              this.$set(this.form,"comIssueDate",ocrCompanyInfo.issueDate);
              this.$set(this.form,"comTitle",ocrCompanyInfo.title);
              this.$set(this.form,"address",ocrCompanyInfo.businessAddress);
            }
          }else{
            this.$toast.fail("营业执照数据识别失败！");
          }

        }
      })
    },
    uploadFrontImage(file) {
      const formData = new FormData();
      formData.append('file', file.file);
      formData.append('uploadType','idCardFront');
      this.$toast.loading("请求中",{ duration: 2000 });
      uploadImg(formData).then(res => {
        if(res.code == 200) {

          if(!!res.ocrInfo){
            let ocrInfo = JSON.parse(res.ocrInfo);
            if(!!ocrInfo.Code){
              this.$toast.fail(ocrInfo.Message);
            }else{
              this.form.frontUrl = this.baseUrl + res.fileName // res.url
              let ocrUserInfo = JSON.parse(ocrInfo.data).data.face.data;
              this.$set(this.form,"idCardName",ocrUserInfo.name);
              this.$set(this.form,"idCardAddress",ocrUserInfo.address);
              this.$set(this.form,"idCardBirthDate",ocrUserInfo.birthDate);
              this.$set(this.form,"idCardEthnicity",ocrUserInfo.ethnicity);
              this.$set(this.form,"idCardIdNumber",ocrUserInfo.idNumber);
              this.$set(this.form,"idCardSex",ocrUserInfo.sex);
            }
          }else{
            this.$toast.fail("身份证正面信息识别失败！");
          }
        }
      })
    },
    uploadReverseImage(file) {
      const formData = new FormData();
      formData.append('file', file.file);
      formData.append('uploadType','idCardReverse');
      this.$toast.loading("请求中",{ duration: 2000 });
      uploadImg(formData).then(res => {
        if(res.code == 200) {

          if(!!res.ocrInfo){
            let ocrInfo = JSON.parse(res.ocrInfo);
            if(!!ocrInfo.Code){
              this.$toast.fail(ocrInfo.Message);
            }else{
              this.form.reverseUrl = this.baseUrl+res.fileName // res.url
              let ocrUserInfo = JSON.parse(ocrInfo.data).data.back.data;
              this.$set(this.form,"idCardIssueAuthority",ocrUserInfo.issueAuthority);
              this.$set(this.form,"idCardValidPeriod",ocrUserInfo.validPeriod);
            }
          }else{
            this.$toast.fail("身份证背面信息识别失败！");
          }

        }
      })
    },
    uploadHandImage(file) {
      const formData = new FormData();
      formData.append('file', file.file);
      uploadImg(formData).then(res => {
        if(res.code == 200) {
          this.form.handUrl = this.baseUrl+res.fileName // res.url
        }
      })
    },
  }
};
</script>

  <style lang="scss" scoped>
.container {
  background-color: #f6f6f6;
}
.content {
  width: 100%;
   p{
     font-size: 14px;
     color: #646566;
   }
}
.upload {
  height: 20vh;
  width: 100%;
  border-radius: 5%;
  margin-top: 1vh;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}
.img {
  height: 18vh !important;
  width: 90% !important;
}
.van-uploader__upload {
  background-color: #c8c8c8 !important;
}
.uploader-slot {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 20px;
}
    .img-upload{
      padding: 0 14px;
    }
    .p-title{
      color:#5492ff!important;
      font-weight: bold;
      font-size: 16px!important;
      padding-left: 14px;
    }
</style>
