// 格式化日期的函数
export function formatDate(paramDate) {
    const date = paramDate ? new Date(paramDate) : new Date()
    const year = date.getFullYear();
    const month = padZero(date.getMonth() + 1);
    const day = padZero(date.getDate());

    return `${year}-${month}-${day}`;
}

export function addDate(time,day) {
    const date = new Date(time)
    // 将当前日期增加指定天数
    date.setDate(date.getDate() + day);

    return date
}

// 补零函数，用于保证月份和日期的两位格式
function padZero(num) {
    return num < 10 ? '0' + num : num;
}
