<template>
    <div class="wrapper">
        <van-icon name="revoke" style="font-size: 1rem;color:#fff !important;" @click="back()" />
        <div class="info">
            <div class="name">
                <div class="code-name" @click="doCopy">{{code}}</div>
                <div ref="qrcode" class="qrcode-img"></div>
                <el-button class="login-btn" type="primary" size="large" autocomplete="off" @click="btnShare">分享好友</el-button>
            </div>

        </div>
    </div>
</template>

<script>
import request from '@/utils/request';
import QRCode from 'qrcode'
import { Toast } from 'vant';
export default {
    name: "Qrcode",
    data() {
        return {
            code:'',
            nickname:'',
            user: {},
            shares:null,
            registerUrl:'http://txyapp.tongxunyuan.com/#/register?spread='
        }
    },
    mounted() {
        this.code=this.$route.query.code;
        this.nickname=this.$route.query.nickname;
        this.generateQRCode(this.registerUrl+this.code);

    },
    created() {
        let t = this

        plus.share.getServices(function(e) {
            t.shareData = e　　　　　　　　//这个回调函数的参数 e 就包含了 所有对象的数组

            //在这个数组里 找到属于微信的对象 循环匹配查找
            for(var i in e){
                if('weixin' == e[i].id){
                    t.sharewx = e[i]　　//保存到变量里 （之后即可使用该对象发起分享）
                }
            }
        })
    },
    methods: {
        doCopy () {
            this.$copyText(this.code).then(e=>{
                // alert('Copied')
                console.log(e)
                this.$toast("已复制邀请码："+this.code)
            }, err=> {
                // alert('Can not copy')
                console.log(err)
            })
        },
        generateQRCode(data) {
            QRCode.toDataURL(data, { errorCorrectionLevel: 'H',width: 250, height: 250}, (err, url) => {
                if (err) console.error(err)
                this.$refs.qrcode.innerHTML = '<img src="' + url + '">'
            })
        },
        back:function(){
            // this.$router.go(-1);
            this.$router.back();
        },
        btnShare(){
             //因为用的vue框架，这里需要用到this ，如果没有用可注释

            this.$toast.loading("加载中",{ duration: 1000 })
            let msg = {
                type: 'web',　　//分享的内容的类型
                title: this.nickname+'邀请您加入通讯源',
                content: '邀请您加入通讯源',
                thumbs: ['http://img-cdn-qiniu.dcloud.net.cn/icon3.png'],
                href:this.registerUrl+this.code,
                extra:{scene:"WXSceneSession"} // 'WXSceneSession'分享给好友，'WXSceneTimeline'分享到朋友圈
            }
            let t=this;
            //msg对象的属性和参数 官方文档里有说明
            //这里就用到了上面获取到的 微信对象 使用send发起分享
            if(this.sharewx){
                this.sharewx.send(msg, function() {
                    t.$toast("已分享！");
                },function(error) {
                    t.$toast("分享失败！");
                })
            }


        }
    }
}
</script>

<style lang="scss" scoped>
.wrapper {
    background: linear-gradient(0deg, #4C8CF9, #8ED0FB);
    padding:0 30px;
    p{
        margin: 0;
    }
    .info{
        background:url("../../assets/login_app.png");
        background-size: 100% 100%;
        height: 100vh;

        .name{
            color: white;
            padding-top: 100px;
            text-align: center;
            div:first-child{
                font-size:24px;
                margin-bottom: 5px;
            }
            div:last-child{
                margin-bottom:40px;
            }
        }
    }
    ::v-deep .el-input--medium .el-input__inner{
        height:42px!important;
        line-height: 42px!important;
    }
    ::v-deep .el-form-item{
        margin-bottom: 0!important;
    }
    .register-p{
        color: white;
        text-align: center;
        font-size: 14px;
        margin-top: 16px;
    }
    .login-btn{
        border-radius: 80px;
        background: white;
        color: #4C8CF9;
        letter-spacing: 2px;
        font-size: 18px;
        width:85%!important;
    }
   ::v-deep .el-form-item__content{
        width: 100% !important;
    }
    ::v-deep .van-icon-revoke{
        font-size: 1rem;
        font-weight: 900;
        color:#fff;
        position: relative;
        top: 1.5rem;
    }
}
    .code-name{
        background: #9ccef9;
        margin: 0 auto;
        width: 200px;
        color: #4C8CF9;
        padding:6px 0;
        border-radius: 10px;
        font-weight: bold;
    }
    .qrcode-img{
        margin:30px 0;
    }
</style>
