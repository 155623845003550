<template>
  <div class="sd_contains">
    <van-nav-bar :title="title" @click-left="$router.push('/myPublish')" left-arrow>
    </van-nav-bar>
    <van-empty v-show="isEmpty" description="暂无同款商品"/>
    <div class="detail_contain" v-show="!isEmpty">
      <div v-for="(item, index) in itemList" :key="index" class="detail-item" :data-status="item.status"
           :style="{ backgroundColor: getStatusColor(item.status) }">
        <div>
          <p>商家:<span class="name">{{ item.businessName }}</span></p>
        </div>
        <div>
          <p>门牌:<span class="code">{{ item.doorNumber }}</span></p>
        </div>
        <div>
          <p>价格:<span class="price">{{ item.price }}</span></p>
        </div>
        <div>
          <p>状态:<span class="status">{{ item.status }}</span></p>
        </div>
        <div v-if="isNew == '1'">
          <p>损坏情况:<span class="status">{{ item.damage }}</span></p>
        </div>
        <div>
          <p>时间:<span class="time">{{ item.createTime.substring(0,10) }}</span></p>
        </div>
        <!--<div class="btn">-->
        <!--<div class="btn-p">-->
        <!--<div @click.stop="showPhone(item.phone)"><van-icon name="phone" color="#2d94f8" />询价</div>-->
        <!--<div @click.stop="showUpdate(item)"><van-icon name="bars" color="#2d94f8" />反馈</div>-->
        <!--<div @click.stop="showPop(item)"><van-icon name="sort" color="#2d94f8" />置顶</div>-->
        <!--</div>-->
        <!--</div>-->
      </div>
    </div>
  </div>
</template>

<script>
  import request from '@/utils/request';
  import {Toast, Notify} from 'vant';
  import {getDicts} from '@/api/dict'
  import {updateStatus} from '@/api/series'

  export default {
    data() {
      return {
        isEmpty: true,
        showZd: false,
        showAddUpdate: false,
        currentItem: {},
        title: '',
        itemList: [],
        showTool: false,
        showPopover: false,
        actions: [{text: '积分置顶'}],
        showSearch: true,
        show: false,
        status: '',
        isNew: '',
        seriesId: '',
        modelId: '',
        versionId: '',
        brandId: '',
        status: '',
        showAddDivContent: false, // 控制点击后显示的固定在底部的 div 的显示状态
        showAdd: false,
        params: {},
        num: 0,
        price: 0,
        showPickerPopup: false,
        statusOption: [],
        damageStatusOption: [],
        currentDamage: ''
      }
    },
    methods: {
      goShop(item) {
        console.log(item.businessId)
        // this.$router.push("/product").catch(error => {
        //     console.log(error)
        // })

        // this.$router.push( '/userCenter')
        this.$router.push({
          path: '/product', query: {
            businessId: item.businessId,
            businessName: item.businessName,
            currentBrand: this.$route.query.currentBrand,
            currentCity: this.$route.query.currentCity,
            currentIsNew: this.$route.query.currentIsNew,
            currentModel: this.$route.query.currentModel,
            currentSeries: this.$route.query.currentSeries,
            currentVersion: this.$route.query.currentVersion
          }
        })
      },
      onSelect() {

      },
      clickStatus() {
        this.showAddUpdate = true
      },
      add() {
        request.post('/business/series', {
          businessId: '1',
          brandId: this.params.currentBrand,
          seriesId: this.params.currentSeries.value,
          modelId: this.params.currentModel.value,
          versionId: this.params.currentVersion.value,
          status: this.status,
          price: this.price,
          isNew: this.isNew,
          cityId: this.params.currentCity

        })
          .then(response => {
            // 处理响应数据
            Toast.success('添加成功');
            this.getList();
            // this.itemList = response.rows;

          })
          .catch(error => {
            // 处理请求错误
            console.error('请求错误:', error);
          });
      },
      updateStatus(item) {
        console.log(item)
      },
      // 根据 status 字段返回不同的背景颜色
      getStatusColor(status) {
        if (status === '无货') {
          //  return '#ff0000'; // 红色
        } else if (status === '有货') {
          //  return '#ffffff'; // 绿色
        } else {
          //  return '#b2d5fd'; // 默认颜色
        }
      },
      Zd() {
        console.log("zd")
        request.post('/business/series/zd',
          {
            bussinessId: 1,
            id: this.currentItem.id
          }
        )
          .then(response => {
            // 处理响应数据
            if (response.data.code == '200') {
              Toast.success(response.data.message)
            } else {
              Toast.fail(response.data.message)
            }
            this.getList();

          })
          .catch(error => {
            // 处理请求错误
            console.error('请求错误:', error);
          });
      },
      onClickAdd() {
        this.showAdd = true
      },
      showAddDiv() {
        this.showAddDivContent = !this.showAddDivContent;
      },
      selectItem(item) {
        this.currentDamage = item.value
        this.show = false;
        this.getList()
      },
      showT() {
        this.showTool = !this.showTool
      },
      back() {
        this.$router.push('/search')

      },
      plate(id) {
        console.log("下单")
        this.$router.push({
          name: 'plate', query: {
            id: id,
            currentBrand: this.$route.query.currentBrand,
            currentCity: this.$route.query.currentCity,
            currentIsNew: this.$route.query.currentIsNew,
            currentModel: this.$route.query.currentModel,
            currentSeries: this.$route.query.currentSeries,
            currentVersion: this.$route.query.currentVersion
          }
        })
      },
      showPhone(phone) {
        if (phone) {
          // window.location.href = `tel://` + phone;
          plus.device.dial(phone);
        } else {
          Toast.fail('该商家暂无手机号');
        }

      },
      showPop(item) {
        this.currentItem = item;
        this.showZd = true
      },

      showPopup() {
        this.show = true;
      },
      getList() {
        //获取 同款商品
        request.get('/business/series/listSame',
          {
            pageNum: 1,
            pageSize: 9999,
            // isNew: this.isNew,
            seriesId: this.seriesId,
            modelId: this.modelId,
            versionId: this.versionId,
            brandId: this.brandId,
            status:this.status
            // cityId: this.params.currentCity?this.params.currentCity.value:'',
            // damage: this.currentDamage
          }
        )
          .then(response => {
            // 处理响应数据
            console.log("res", response)
            this.itemList = response.rows;
            if (response.rows && response.rows.length > 0) {
              this.isEmpty = false
            }
          })
          .catch(error => {
            // 处理请求错误
            console.error('请求错误:', error);
          });

      },
      getStatus() {
        getDicts("status").then(res => {
          console.log("object", res)
          this.statusOption = res.data.map(dict => {
            return {
              "text": dict.dictLabel,
              "value": dict.dictLabel
            }
          })
        })
      },
      getDamage() {
        getDicts("damage").then(res => {
          console.log("object", res)
          this.damageStatusOption = res.data.map(dict => {
            return {
              "text": dict.dictLabel,
              "value": dict.dictLabel
            }
          })
        })
      },
      showUpdate(item) {
        this.currentItem = item;
        this.showPickerPopup = true

        // Toast(action.text);
      },
      handleConfirm(value) {
        this.showPickerPopup = false
        console.log("value", value)
        var data = {"id": this.currentItem.id, "status": value.value}
        updateStatus(data).then(res => {
          if (res.code == 200) {
            Notify({type: 'success', message: '反馈成功'});
            this.getList()
            return
          }
          Notify({type: 'danger', message: res.msg});
        })
      },
      handleAddUpdateConfirm(value) {
        this.showAddUpdate = false
        this.status = value.value
        // var data = { "id": this.currentItem.id,"status": value.value }
        // updateStatus(data).then(res => {
        //     if(res.code == 200) {
        //         Notify({ type: 'success', message: '反馈成功' });
        //         this.getList()
        //         return
        //     }
        //     Notify({ type: 'danger', message: res.msg });
        // })
      },
      getVersionInfo() {
        request.get(`/business/version/${this.versionId}`)
          .then(response => {
            // 处理响应数据
            console.log("junjia", response)
            this.price = response.data.price

          })
          .catch(error => {
            // 处理请求错误
            console.error('请求错误:', error);
          });
      }
    },
    mounted() {
      this.getStatus()
      this.getDamage();
      const paramValue = this.$route.query;
      console.log(paramValue)
      this.params = paramValue;

      let title = ''
      if (paramValue.brandName) {
        title = title + paramValue.brandName + '|'
      }
      if (paramValue.seriesName) {
        title = title + paramValue.seriesName +'|'
      }
      if (paramValue.modelName) {
        title = title + paramValue.modelName+'|'
      }
      if (paramValue.versionName) {
        title = title + paramValue.versionName+'|'
      }
      if (paramValue.status) {
        title = title + paramValue.status
      }

      this.title = title

      this.seriesId = paramValue.seriesId;
      this.modelId = paramValue.modelId;
      this.versionId = paramValue.versionId;
      this.brandId = paramValue.brandId;
      this.status = paramValue.status;


      this.getList()
      this.getVersionInfo()
    }
  }
</script>

<style lang="scss" scoped>

  .detail_contain {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    padding: 5px;
    font-size: 13px;
    p {
      margin: 0px;
      padding-bottom: 2px;
      color: #6e767d;
    }
  }

  .detail_contain .detail-item {
    width: 45.5%;
    margin-bottom: 5px;
    padding: 5px;
    background-color: #b2d5fd;
    border: 1px solid #8bb0f0;
    border-radius: 10px;
    box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.1);
    /* 添加阴影效果 */

  }

  .detail_contain .name {
    margin-left: 5px;
    color: #E8A659;
  }

  .detail_contain .code {
    margin-left: 5px;
    color: #60AB4C
  }

  .detail_contain .price {
    margin-left: 5px;
    font-size: 13px;
    color: red
  }

  .detail_contain .status {
    margin-left: 5px;
    font-size: 13px;
    font-weight: bold;
    color: #464c59
  }

  .detail_contain .time {
    margin-left: 5px;
    color: #333333;
  }

  .detail_contain .btn {
    border-top: 1px solid #8bb0f0;
    font-size: 13px;
    padding-top: 10px;
    .btn-p {
      display: flex;
      align-items: center;
      justify-content: space-between;

    }
    .btn-p:first-child {
      margin-bottom: 4px;
    }
  }

  .typeName {
    text-align: center;
    background-color: #ededed !important;
  }

  /* 固定在底部的 "+" 图标样式 */
  .add-icon {
    position: fixed;
    bottom: 30%;
    right: 6px;
    /* 或者根据你的设计设置合适的右边距 */
    width: 50px;
    height: 50px;
    border-radius: 50%;
    /* 设置为圆形 */
    cursor: pointer;
    /* 鼠标悬停时显示指针 */
  }

  /* 点击后显示的固定在底部的 div 样式 */
  .fixed-div {
    position: fixed;
    bottom: 23%;
    right: 13px;
    width: 50px;
    height: 50px;
    border-radius: 50%;
    /* 设置为圆形 */
    text-align: center;
    background-color: #f39d1c;
    /* 这里设置背景颜色为黑色，你可以根据需求修改 */
    /* 其他样式，例如字体颜色、居中样式等 */
  }

  .zhangjia {
    line-height: 50px;
    text-align: center;
    color: #fff;
    font-weight: bold;
  }

  /* 水印样式 */
  .watermark {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }

  .van-popover__action {
    background-color: #a7c6e9;
    color: #fff;
    font-weight: bold;
  }

  .van-popover__action-text van-hairline--bottom {
    font-weight: bold;
  }

  .watermark-text {
    font-size: 24px;
    color: rgba(0, 255, 0, 0.5);
    /* 半透明绿色 */
    /* 其他样式，例如字体样式、水印位置等 */
  }

  /* 商品状态为有货时添加水印 */
  .detail-item[data-status="无货"] {
  }

  .dialog-title {
    font-weight: bold;
    text-align: center;
  }

  .dialog-desc {
    font-size: 14px;
    color: #999;
    text-align: center;
  }
</style>
